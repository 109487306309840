import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { BbitI18n, type BbitTranslationObject } from '@bbitgmbh/bbit.utils';
import { BbitPictureSrcDirective, I18nPipe } from '@bbitgmbh/website.sdk';
import { BehaviorSubject } from 'rxjs';
import { ApiService } from '../../api/api.service';
import { PageHeaderComponent } from '../../helper/page-header/page-header.component';

interface ISelectRow {
  _id: string;
  designation: BbitTranslationObject;
  code: string;
}

@Component({
  selector: 'app-product-detail',
  templateUrl: './product-detail.component.html',
  styleUrls: ['./product-detail.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    ReactiveFormsModule,
    FormsModule,
    RouterLink,
    AsyncPipe,
    I18nPipe,
    BbitPictureSrcDirective,
    PageHeaderComponent,
  ],
})
export class ProductDetailComponent {
  private _route = inject(ActivatedRoute);
  private _api = inject(ApiService);

  private _code: string;
  public weapon = new BehaviorSubject<any>(undefined);
  public options = new BehaviorSubject<{ [key: string]: ISelectRow }>({});
  public articleNumber = new BehaviorSubject<string>('');
  public fields: string[] = [
    'version',
    'material',
    'color',
    'trigger',
    'frontSight',
    'optic',
    'caliber',
    'barrel',
    'gripShell',
  ];
  public isOpen: { [field: string]: BehaviorSubject<boolean> } = {};
  public availableOptions: { [field: string]: ISelectRow[] } = {};

  constructor() {
    this._code = this._route.snapshot.params.code;
    this._api.getWeapons().then((weaponList) => {
      const weapon = weaponList.find((w) => w.code === this._code);

      this.weapon.next(weapon);
      for (const field of this.fields) {
        this.setValue(field);
        this.isOpen[field] = new BehaviorSubject<boolean>(false);
        this.availableOptions[field] = this.weapon.value[field]?.options || [];
      }

      this.options.next(this.options.value);
    });

    this.options.subscribe((options) => {
      const no = [];
      no.push(this._code);
      no.push(options.version?.code || 'R');
      no.push(options.material?.code || '0');
      no.push(options.color?.code || '0');
      no.push('-');
      no.push(options.trigger?.code || '0');
      no.push(options.frontSight?.code || '0');
      no.push(options.optic?.code || '0');
      no.push(options.caliber?.code || '0');
      no.push('-');
      no.push(options.barrel?.code || '0');
      no.push(options.gripShell?.code || '0');
      no.push('1'); // TODO how to handle "Ordonanzbeschuss"
      const temp = no.join('');
      const checkSum = this._calcCheckSum(temp);
      this.articleNumber.next(`${temp}-${checkSum}`);
    });
  }

  ngOnInit(): void {}

  setValue(key: string, id?: string) {
    const data = this.weapon.value?.[key];
    if (!data) {
      return;
    }
    const options = data.options;
    let updateSubject = true;
    if (!id) {
      id = data.default;
      updateSubject = false;
    }

    this.options.value[key] = options.find((o: any) => o._id === id);
    if (updateSubject) {
      this.options.next(this.options.value);
    }
  }

  private _calcCheckSum(gtin: string): number {
    const toInt = (numString: string): number => {
      return Number.parseInt(numString.toUpperCase(), 36);
    };

    const isOdd = (num: number): boolean => {
      return num % 2 === 1;
    };

    gtin = gtin.toUpperCase().replace(/[^0-9A-Z]/g, '');
    const chunks = gtin.split('').map(toInt).reverse();
    let checksum = 0;

    // Remove first chunk (checksum)
    chunks.shift();

    // sum numbers and multiply accordingly
    chunks.forEach((n, index) => {
      checksum += isOdd(index) ? n : n * 3;
    });

    // calc checksum
    checksum %= 10;
    checksum = checksum === 0 ? 0 : 10 - checksum;

    return checksum;
  }

  compact<T>(value: T[]): T[] {
    return value.filter((v) => v);
  }

  filterSpecs(weapon: any) {
    const specs: any[] = [];

    if (!weapon?.phxCatalog) return specs;

    const w = weapon.phxCatalog;

    if (w.width?.amount) {
      specs.push({
        label: 'width',
        value: `${w.width.amount} ${w.width.unit}`,
      });
    }

    if (w.height?.amount) {
      specs.push({
        label: 'height',
        value: `${w.height.amount} ${w.height.unit}`,
      });
    }

    if (w.barrelLength?.amount) {
      specs.push({
        label: 'barrelLength',
        value: `${w.barrelLength.amount} ${w.barrelLength.unit}`,
      });
    }

    if (w.totalLength?.amount) {
      specs.push({
        label: 'totalLength',
        value: `${w.totalLength.amount} ${w.totalLength.unit}`,
      });
    }

    if (w.weight?.amount) {
      specs.push({
        label: 'weight',
        value: `${w.weight.amount} ${w.weight.unit}`,
      });
    }

    if (w.intendedUse?.length) {
      specs.push({
        label: 'intendedUse',
        value: w.intendedUse
          .map((i: any) => BbitI18n.t({ object: i.designation }))
          .filter((i: string) => i?.length)
          .join(', '),
      });
    }

    if (w.magazineCapacity?.designation) {
      specs.push({
        label: 'magazineCapacity',
        value: BbitI18n.t({ object: w.magazineCapacity.designation }),
      });
    }

    return specs.filter((s) => s.value?.length > 0);
  }

  filterScopeOfDelivery(weapon: any) {
    const specs: any[] = [];

    if (!weapon?.phxCatalog) return specs;

    const w = weapon.phxCatalog;

    if (w.deliveryScope?.length) {
      for (const e of w.deliveryScope)
        if (e.item?.designation && e.count?.amount) {
          specs.push({
            designation: BbitI18n.t({ object: e.item.designation }),
            count: e.count.amount,
            itemNumber: e.item.itemNumber,
          });
        }
    }

    return specs;
  }

  filterAccessories(weapon: any) {
    const specs: any[] = [];

    if (!weapon?.accessories?.length) return specs;

    for (const e of weapon.accessories) {
      if (e?.designation) {
        specs.push({
          designation: BbitI18n.t({ object: e.designation }),
          itemNumber: e.itemNumber,
        });
      }
    }

    return specs;
  }

  filterManualsByLanguage(manuals: any[]): any[] {
    const byLang = manuals?.filter((m) => m.language === `#${BbitI18n.getLanguage()}` && m.s3Key) || [];

    const cleanupUrl = (url: string): string => {
      const urlWithSlash = url.startsWith('/') ? url : `/${url}`;

      const splitted = urlWithSlash.split('/');
      const combined = splitted.map((s) => encodeURIComponent(s)).join('/');
      return `https://phx.swiss${combined}`;
    };

    const getFileName = (url: string): string | undefined => {
      return url.split('/').pop();
    };

    return byLang.map((m) => ({
      url: cleanupUrl(m.s3Key),
      designation: m.designation || getFileName(m.s3Key),
    }));
  }
}
