import { AsyncPipe } from '@angular/common';
import { Component, inject } from '@angular/core';
import { BbitI18n } from '@bbitgmbh/bbit.utils';
import { I18nPipe } from '@bbitgmbh/website.sdk';
import { BehaviorSubject } from 'rxjs';
import { ApiService } from '../api/api.service';
import type { ICountry } from '../api/distributor.interfaces';
import { stripBeginningHashtag } from '../api/helper';
import { PageHeaderComponent } from '../helper/page-header/page-header.component';
import { NavService } from '../nav/nav.service';

@Component({
  selector: 'app-shooter',
  templateUrl: './shooter.component.html',
  styleUrls: ['./shooter.component.scss'],
  imports: [AsyncPipe, I18nPipe, PageHeaderComponent],
})
export class ShooterComponent {
  private _api = inject(ApiService);
  private _navService = inject(NavService);

  public shooter = new BehaviorSubject<any[]>([]);
  private _allCountries: ICountry[] = [];
  constructor() {
    this._api.getShooter().then(async (result) => {
      this._allCountries = await this._api.getCountries();
      this.shooter.next(result.sort((a, b) => (a.sortOrder || 0) - (b.sortOrder || 0)));
    });
  }

  getCountryDesignation(_id: string): string {
    _id = stripBeginningHashtag(_id);
    const country = this._allCountries.find((o) => o._id === _id);
    if (country) {
      return BbitI18n.t({ object: country?.designation });
    }
    return '';
  }

  removeLeadingAt = (s: string) => {
    if (s.startsWith('@')) {
      return s.substr(1);
    }
    return s;
  };

  getInstagramLink(instagram: string): string {
    return `https://www.instagram.com/${this.removeLeadingAt(instagram)}/`;
  }

  getFacebookLink(facebook: string): string {
    return `https://www.facebook.com/${facebook}`;
  }

  getYoutubeLink(youtube: string): string {
    return `https://www.youtube.com/${youtube}`;
  }
}
