<div class="px-2 lg:px-8 m-auto max-w-[1600px] pb-6 py-6">
  <div class="lg:grid lg:grid-cols-12 lg:gap-x-5">
    <aside class="py-6 px-2 sm:px-6 lg:col-span-3 lg:py-0 lg:px-0">
      <nav class="space-y-1">
        @for (link of links; track link) {
          <a
            href="#"
            class="text-gray-900 hover:text-gray-900 hover:bg-gray-50 group rounded-md px-3 py-2 flex items-center text-sm font-medium"
            aria-current="page"
            [routerLink]="link.routerLink"
            [routerLinkActive]="'bg-gray-50 text-primary-700 hover:text-primary-700 hover:bg-white'"
            #rla="routerLinkActive"
            >
            <!-- <ng-icon
            [name]="link.icon"
            size="24px"
            [class]="
              rla.isActive
                ? 'text-primary-500 group-hover:text-primary-500 flex-shrink-0 -ml-1 mr-3 h-6 w-6'
                : 'text-gray-400 group-hover:text-gray-500 flex-shrink-0 -ml-1 mr-3 h-6 w-6'
            "
          ></ng-icon> -->
          <span class="truncate">{{ link.name | i18n }}</span>
        </a>
      }
    </nav>
  </aside>

  <div class="space-y-6 sm:px-6 lg:col-span-9 lg:px-0">
    <router-outlet></router-outlet>
  </div>
</div>
</div>
