import { AsyncPipe } from '@angular/common';
import { Component, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BbitI18n } from '@bbitgmbh/bbit.utils';
import { BbitPictureSrcDirective, I18nPipe } from '@bbitgmbh/website.sdk';
import { DateTime } from 'luxon';
import { BehaviorSubject } from 'rxjs';
import { ApiService } from 'src/app/api/api.service';
import { PageHeaderComponent } from '../../helper/page-header/page-header.component';

@Component({
  selector: 'app-news-detail',
  templateUrl: './news-detail.component.html',
  styleUrls: ['./news-detail.component.scss'],
  imports: [AsyncPipe, I18nPipe, BbitPictureSrcDirective, PageHeaderComponent],
})
export class NewsDetailComponent {
  private _route = inject(ActivatedRoute);
  private _api = inject(ApiService);

  private _id: string | undefined;
  public news = new BehaviorSubject<any>(undefined);

  constructor() {
    this._id = this._route.snapshot.params.id;
    this._api.getNews().then((newsList) => {
      const entry = newsList.find((w) => w._id === this._id);

      const currentLanguage = BbitI18n.getLanguage().substring(0, 2);

      this.news.next({
        ...entry,
        description:
          entry?.news?.[currentLanguage as 'de' | 'en' | 'fr'] ||
          entry?.news?.[BbitI18n.getLanguage() as unknown as 'de' | 'en' | 'fr'],
        image: entry?.newsImage.url,
        moreInfoLink: entry?.link?.url,
        newsDate: entry?.newsDate ? DateTime.fromISO(entry?.newsDate).toLocaleString(DateTime.DATE_MED) : undefined,
      });
    });
  }
}
