<!-- Hero section -->
<div class="relative bg-zinc-900">
  <div aria-hidden="true" class="absolute inset-0 bg-zinc-900 opacity-50"></div>

  <!-- Navigation -->
  <header class="relative z-10">
    <nav aria-label="Top">
      <!-- Top navigation -->
      <div class="bg-zinc-900 hidden lg:block text-xs">
        <div class="mx-auto flex h-10 max-w-7xl items-center justify-end px-4 sm:px-6 lg:px-8">
          <div class="lg:flex items-center text-zinc-200 space-x-4 hidden">
            <a href="#" class="text-xs font-medium hover:text-primary-300" (click)="changeLang(BbitLanguage.DE_CH)">Deutsch</a>
            <a href="#" class="text-xs font-medium hover:text-primary-300" (click)="changeLang(BbitLanguage.EN_US)">English</a>
          </div>
        </div>
      </div>

      <!-- Secondary navigation -->
      <div class="bg-white bg-opacity-10 backdrop-blur-md backdrop-filter">
        <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <div>
            <div class="flex h-16 items-center justify-between">
              <!-- Logo (lg+) -->
              <div class="hidden lg:flex lg:flex-1 lg:items-center">
                <a href="#" [routerLink]="['/']">
                  <span class="sr-only">PHX Phoenix</span>
                  <img class="h-8 w-auto" src="/assets/images/logo-phx-white.svg" alt="" />
                </a>
              </div>

              <div class="hidden lg:flex h-full">
                <div class="inset-x-0 bottom-0 px-4">
                  <div class="flex h-full justify-center space-x-8">
                    @for (item of nav.getMenuItems(); track item) {
                      <a [routerLink]="item.routerLink" class="flex items-center text-sm font-medium text-white hover:text-primary-300">{{
                        item.text | i18n
                      }}</a>
                    }
                  </div>
                </div>
              </div>

              <!-- Mobile menu and search (lg-) -->
              <div class="flex flex-1 items-center lg:hidden">
                <!-- Mobile menu toggle, controls the 'mobileMenuOpen' state. -->
                <button type="button" class="-ml-2 p-2 text-white" (click)="overlay.open()">
                  <span class="sr-only">Open menu</span>
                  <svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
                  </svg>
                </button>
              </div>

              <!-- Logo (lg-) -->
              <a href="#" class="lg:hidden">
                <span class="sr-only">PHX Phoenix AG</span>
                <img src="/assets/images/logo-phx-white.svg" alt="" class="h-8 w-auto" />
              </a>

              <div class="flex flex-1 items-center justify-end">
                <!-- <a href="#" class="hidden text-sm font-medium text-white lg:block">Search</a> -->

                <div class="ml-auto lg:flex items-center hidden">
                  <div class="flex flex-row items-center ml-2">
                    <!-- @if (!(website.observeAuthenticated() | async)) {
                      <a class="flex p-2 cursor-pointer" (click)="website.login()">
                        <ng-icon
                          name="heroArrowLeftOnRectangle"
                          class="flex-shrink-0 text-white group-hover:text-gray-500"
                          size="24px"
                        ></ng-icon>
                      </a>
                    } -->

                    @if (website.observeAuthenticated() | async) {
                      <a class="flex p-2 cursor-pointer" (click)="website.logout()">
                        <ng-icon
                          name="heroArrowRightOnRectangle"
                          class="flex-shrink-0 text-white group-hover:text-gray-500"
                          size="24px"
                        ></ng-icon>
                      </a>

                      <a [routerLink]="['/account']" class="flex p-2">
                        <ng-icon name="heroUserCircle" class="flex-shrink-0 text-gray-400 group-hover:text-gray-500" size="24px"></ng-icon>
                      </a>

                      <a class="flex p-2" [routerLink]="['/shop/cart']">
                        <ng-icon name="heroShoppingBag" class="flex-shrink-0 text-gray-400 group-hover:text-gray-500" size="24px"></ng-icon>
                        <span class="ml-2 text-sm font-medium text-white group-hover:text-gray-800 flex items-center">{{
                          cart.observeItemCount() | async
                        }}</span>
                      </a>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
  </header>
</div>
