<app-page-header [title]="{ key: 'dealer' }"></app-page-header>

<div class="relative mx-auto max-w-7xl mt-12 mb-12 px-4 md:px-8">
  <div class="space-y-6">
    <div class="flex flex-row flex-wrap -mx-3 cursor-pointer">
      @for (country of countries | async; track country) {
        <div
          class="flex flex-row items-center p-3 m-3 border border-transparent hover:border-orange-200 rounded-md"
          [class.border-orange-500]="country === (filteredCountry | async)"
          (click)="filter(country)"
        >
          <span class="fi fi-{{ getCountryCode(country) }} fis"></span>
          <div class="ml-2">
            {{ getCountryDesignation(country) }}
          </div>
        </div>
      }
    </div>
  </div>
  <ul role="list" class="space-y-6 mt-12">
    @for (d of distributors | async; track d) {
      <li class="flex flex-col sm:flex-row justify-between sm:items-center rounded-sm shadow-md p-4 sm:p6 bg-white hover:bg-orange-200">
        <div class="flex flex-row items-center">
          <span class="fi fi-{{ getCountryCode(d.address.country) }} fis"></span>
          <div class="ml-6">
            <p class="text-xl font-semibold text-gray-900 mb-2">
              {{ d.name.fullName || d.name.companyName }}
            </p>
            <p>
              {{ d.address.street }}
              <br />
              {{ d.address.postalCode }} {{ d.address.locality }}
              <br />
              {{ getCountryDesignation(d.address.country) }}
            </p>
            @if (d.website) {
              <a class="website" [href]="d.website" target="_blank">{{ d.website }}</a>
            }
          </div>
        </div>
        @if (d.image?.url) {
          <div class="h-12 mt-12 sm:mt-0 ml-auto sm:ml-0">
            <a [href]="d.website" [style.pointer-events]="!d.website ? 'none' : 'all'" target="_blank">
              <img class="h-full" [src]="d.image.url" alt="" />
            </a>
          </div>
        }
      </li>
    }
  </ul>
</div>
