<div class="relative mx-auto items-center bg-black mb-24 w-full">
  <img class="h-full w-full absolute object-cover opacity-75" src="/assets/images/fire-1.png" alt="fire" />
  <swiper-container #swiperRef slides-per-view="1" space-between="0" loop="true" speed="1000" navigation="true">
    @for (slide of slides | async; track slide.src; let i = $index) {
      <swiper-slide>
        <div class="flex flex-col items-center justify-center text-center px-6 py-16 lg:px-0 h-full">
          @if (slide.subTitle) {
            <p class="text-md text-white">{{ slide.subTitle }}</p>
          }
          @if (slide.title) {
            <div class="my-4 text-3xl font-bold tracking-tight text-white">{{ slide.title }}</div>
          }
          @if (slide.localImage) {
            <img [src]="slide.imageUrl" alt="" class="object-scale-down w-96" />
          } @else {
            <picture [bbitPictureSrc]="slide.imageUrl" class="object-scale-down w-69 md:w-[600px]"></picture>
          }

          <!-- @if (slide.routerLink) {
            <a
              href="#"
              [routerLink]="slide.routerLink"
              class="mb-24 sm:mb-16 inline-block rounded-md border border-transparent bg-white px-8 py-3 text-base font-medium text-zinc-900 hover:bg-primary-400 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 focus:ring-offset-gray-50"
              >{{ slide.linkText }}</a
            >
          } -->
        </div>
      </swiper-slide>
    }
  </swiper-container>
</div>

<section aria-labelledby="collection-heading" class="mx-auto max-w-xl px-4 sm:px-6 lg:max-w-7xl lg:px-8">
  <h1 class="text-5xl font-bold tracking-tight md:text-5xl lg:text-6xl">
    {{ { key: 'welcomeTo' } | i18n }} <span class="text-primary-600">PHX Phoenix</span>
  </h1>
  <p class="mt-6 max-w-3xl text-xl text-zinc-900">
    {{ { key: 'homeLead' } | i18n }}
  </p>

  <div class="mt-10 space-y-12 lg:grid lg:grid-cols-3 lg:gap-x-8 lg:space-y-0">
    <a  [routerLink]="['/products']" class="group block text-center">
      <div aria-hidden="true" class="aspect-h-2 aspect-w-3 overflow-hidden rounded-sm lg:aspect-h-6 lg:aspect-w-5 group-hover:opacity-75">
        <img src="/assets/images/x_drake_rt_trsp.png" alt="" class="h-full w-full object-cover object-center" />
      </div>
      <h3 class="mt-4 text-base font-semibold text-gray-900">{{ { key: 'onlineConfigurator' } | i18n }}</h3>
      <p class="mt-2 text-sm text-gray-500">{{ { key: 'configureYourDreamPistolOnline' } | i18n }}</p>
    </a>
    <a  [routerLink]="['/dealer']" class="group block text-center">
      <div aria-hidden="true" class="aspect-h-2 aspect-w-3 overflow-hidden rounded-sm lg:aspect-h-6 lg:aspect-w-5 group-hover:opacity-75">
        <img src="/assets/images/messe.jpg" alt="" class="h-full w-full object-cover object-center" />
      </div>
      <h3 class="mt-4 text-base font-semibold text-gray-900">{{ { key: 'dealer' } | i18n }}</h3>
      <p class="mt-2 text-sm text-gray-500">{{ { key: 'purchaseThePhxPhoenixPistolFromTheDealerOfYourChoice' } | i18n }}</p>
    </a>
    <a  [routerLink]="['/shooter']" class="group block text-center">
      <div aria-hidden="true" class="aspect-h-2 aspect-w-3 overflow-hidden rounded-sm lg:aspect-h-6 lg:aspect-w-5 group-hover:opacity-75">
        <img src="/assets/images/home-3.jpg" alt="" class="h-full w-full object-cover object-center" />
      </div>
      <h3 class="mt-4 text-base font-semibold text-gray-900">{{ { key: 'factoryTeam' } | i18n }}</h3>
      <p class="mt-2 text-sm text-gray-500">{{ { key: 'ourShootersRegularlyWinPodiumPlaces' } | i18n }}</p>
    </a>
  </div>
</section>

@if (news | async; as n) {
  @if (n.length > 0) {
    <div class="bg-white mt-32">
      <!-- Header -->
      <div class="relative bg-zinc-900 pb-32">
        <div class="absolute inset-0">
          <img class="h-full w-full object-cover" src="/assets/images/home-2.jpeg" alt="" />
          <div class="absolute inset-0 bg-zinc-600 mix-blend-multiply" aria-hidden="true"></div>
        </div>
        <div class="relative mx-auto max-w-7xl py-20 px-4 sm:py-20 sm:px-6 lg:px-8">
          <h1 class="text-4xl font-bold tracking-tight text-white md:text-5xl lg:text-6xl">News</h1>
        </div>

        <section class="relative z-10 mx-auto max-w-7xl px-4 pb-16 sm:px-6 lg:px-8 mt-12">
          <div class="grid grid-cols-1 gap-y-20 lg:grid-cols-3 lg:gap-y-0 lg:gap-x-8">
            @for (item of n; track item._id) {
              <div class="flex flex-col overflow-hidden rounded-sm shadow-md bg-white hover:bg-primary-200">
                <div class="flex-shrink-0">
                  <img class="h-48 w-full object-cover" [src]="item.image" alt="" />
                </div>
                <div class="flex flex-1 flex-col justify-between p-6">
                  <div class="flex-1">
                    <a (click)="routeToNews(item)" class="mt-2 block cursor-pointer">
                      <p class="text-xl font-semibold text-gray-900">
                        {{ { object: item.title } | i18n }}
                      </p>
                      @if (item.description) {
                        <p class="mt-3 text-base text-gray-500" [innerHTML]="{ object: item.description } | i18n"></p>
                      }
                    </a>
                  </div>
                  <div class="mt-6 flex items-center">
                    <div class="flex space-x-1 text-sm text-gray-500">
                      <time>{{ { date: item.from, format: 'dd.LL.yyyy' } | luxon }}</time>
                    </div>
                  </div>
                </div>
              </div>
            }
          </div>
        </section>
        <div class="flex flex-row items-center justify-center">
          <div class="rounded-md shadow z-30">
            <a
              [routerLink]="['/news']"
              class="flex items-center justify-center rounded-md border border-transparent bg-primary-600 px-8 py-3 text-base font-medium text-white hover:bg-primary-700 md:py-4 md:px-10 md:text-lg"
              >{{ { key: 'moreNews' } | i18n }}</a
            >
          </div>
        </div>
      </div>

      <!-- Overlapping cards -->
    </div>
  }
}
