import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import {
  type BbitLegalDocDefinition,
  BbitObject,
  type IBbitOrganizationLegalIdentity,
  bbitProdEnvironmentKey,
} from '@bbitgmbh/bbit.utils';
import { BbitWebsiteMetaDataService, BbitWebsiteService } from '@bbitgmbh/website.sdk';
import { BehaviorSubject, map, share } from 'rxjs';
import { AsyncPipe } from '@angular/common';

@Component({
    selector: 'app-privacy',
    templateUrl: './privacy.component.html',
    styleUrls: ['./privacy.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [AsyncPipe]
})
export class PrivacyComponent {
  private _meta = inject(BbitWebsiteMetaDataService);
  private _website = inject(BbitWebsiteService);

  public identity = new BehaviorSubject<IBbitOrganizationLegalIdentity | undefined>(undefined);
  public legalDocs = new BehaviorSubject<(BbitLegalDocDefinition & { key: string })[]>([]);

  Object = Object;
  mailToLink = this.identity.asObservable().pipe(
    map((identity) => {
      if (!identity) {
        return '';
      }
      return `mailto:${identity.mainEMail}`;
    }),
    share(),
  );

  phoneLink = this.identity.asObservable().pipe(
    map((identity) => {
      if (!identity) {
        return '';
      }
      return `tel:${identity.mainPhone?.replace(/\s/g, '')}`;
    }),
    share(),
  );

  constructor() {
    this._meta.update({ title: { key: 'privacy' } });

    const currentConfig = this._website.getAuthConfig();

    if (BbitObject.isObject(currentConfig?.legalDocs)) {
      const docsArr = Object.entries(currentConfig.legalDocs).map(([key, value]) => ({ ...value, key }));
      this.legalDocs.next(docsArr);
    }

    this.identity.next(this._website.getAuthMeta()?.environments?.[bbitProdEnvironmentKey]?.identity);
  }
}
