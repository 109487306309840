@if (weapon | async; as w) {
  <app-page-header [title]="{ object: w.designation }" ></app-page-header>

  <div class="flex flex-col mx-auto max-w-7xl mt-12 mb-12 px-4 md:px-8">
    <div class="grid grid-cols-1 md:grid-cols-2 gap-6">
      <div class="col-span-1 order-2 md:order-1">
        <div class="rounded-sm shadow-md">
          @if ((filterSpecs(w) || []).length > 0) {
            <h3 class="p-4 text-xl font-bold tracking-tight text-gray-900 sm:text-2xl">
              {{ { key: 'configurator' } | i18n }}
            </h3>
          }
          @if (options | async; as o) {
            <div class="divide-y divide-gray-100">
              @for (field of fields; track field) {
                @if (o[field]) {
                  <div class="p-4 even:bg-gray-50 hover:bg-orange-200">
                    <label [for]="field" class="block text-sm font-medium text-gray-700">
                      {{ { key: field, n: 1 } | i18n }}
                    </label>
                    @if (availableOptions[field] && availableOptions[field].length > 1) {
                      <select
                        [id]="field"
                        [name]="field"
                        [ngModel]="o?.[field]?._id"
                        (ngModelChange)="setValue(field, $event)"
                        class="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-orange-500 focus:outline-none focus:ring-orange-500 sm:text-sm"
                      >
                        @for (option of availableOptions[field]; track option) {
                          <option [value]="option._id" [selected]="o?.[field]?._id === option._id">
                            {{ { object: option.designation } | i18n }}
                          </option>
                        }
                      </select>
                    } @else {
                      <div class="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base sm:text-sm">
                        @if (availableOptions?.[field]?.[0]?.designation) {
                          {{ { object: availableOptions[field][0].designation } | i18n }}
                        }
                      </div>
                    }
                    <!-- (click)="isOpen[field].next(!isOpen[field].value)"-->
                  </div>
                }
              }
            </div>
          }
          <div class="divide-y divide-gray-100">
            <div class="p-4 even:bg-gray-50 hover:bg-orange-200">
              <h4 class="text-sm font-medium text-gray-700">
                {{ { key: 'orderNumberOfThisProduct' } | i18n }}
              </h4>
              <div class="p-1 grid grid-cols-3">
                <span>{{ articleNumber | async }}</span>
                <!-- <app-animated-text [text]="articleNumber | async" class="text-lg col-span-2"></app-animated-text> -->
                <a routerLink="/dealer" class="pl-1 pt-1 text-sm align-middle">{{ { key: 'findDealer' } | i18n }}</a>
              </div>
            </div>
          </div>
        </div>
        <div class="rounded-sm shadow-md mt-6">
          <div>
            @if ((filterSpecs(w) || []).length > 0) {
              <h3 class="p-4 text-xl font-bold tracking-tight text-gray-900 sm:text-2xl">
                {{ { key: 'specification' } | i18n }}
              </h3>
            }
            <div class="divide-y divide-gray-100mt-6">
              <div class="grid lg:grid-cols-4 grid-cols-2 gap-4">
                @for (specElement of filterSpecs(w); track specElement) {
                  <div class="p-4 hover:bg-orange-200 flex flex-col">
                    <h4 class="text-sm font-medium text-gray-700">
                      {{ { key: specElement.label } | i18n }}
                    </h4>
                    <p class="text-sm align-top">{{ specElement.value }}</p>
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
        <div class="rounded-sm shadow-md mt-6">
          <div>
            @if ((filterScopeOfDelivery(w) || []).length > 0) {
              <h3 class="p-4 text-xl font-bold tracking-tight text-gray-900 sm:text-2xl">
                {{ { key: 'scopeOfDelivery' } | i18n }}
              </h3>
            }
            <div class="divide-y divide-gray-100mt-6">
              <div class="flex flex-col">
                @for (e of filterScopeOfDelivery(w); track e) {
                  <div class="p-4 even:bg-gray-50 hover:bg-orange-200 flex flex-col justify-between">
                    <div class="text-gray-900">{{ e.count }}x {{ e.designation }}</div>
                    <div class="mt-2 text-gray-600 text-sm">{{ { key: 'orderNumber' } | i18n }} {{ e.itemNumber }}</div>
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
        <div class="rounded-sm shadow-md mt-6">
          <div>
            @if ((filterAccessories(w) || []).length > 0) {
              <h3 class="p-4 text-xl font-bold tracking-tight text-gray-900 sm:text-2xl">
                {{ { key: 'accessories' } | i18n }}
              </h3>
            }
            <div class="divide-y divide-gray-100mt-6">
              <div class="flex flex-col">
                @for (e of filterAccessories(w); track e) {
                  <div class="p-4 even:bg-gray-50 hover:bg-orange-200 flex flex-col justify-between">
                    <div class="text-gray-900">
                      {{ e.designation }}
                    </div>
                    <div class="mt-2 text-gray-600 text-sm">{{ { key: 'orderNumber' } | i18n }} {{ e.itemNumber }}</div>
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
        @if ((filterManualsByLanguage(w.manuals) || []).length > 0) {
          <div class="rounded-sm shadow-md mt-6">
            <div>
              <h3 class="p-4 text-xl font-bold tracking-tight text-gray-900 sm:text-2xl">Downloads</h3>
              <div class="divide-y divide-gray-100mt-6">
                <div class="flex flex-col">
                  @for (manual of filterManualsByLanguage(w.manuals); track manual) {
                    <a
                      class="p-4 even:bg-gray-50 hover:bg-orange-200 flex flex-row justify-between"
                      download
                      [href]="manual.url"
                      target="_blank"
                    >
                      <div class="text-gray-900 overflow-x-clip">
                        {{ manual.designation }}
                      </div>
                      <div>
                        <svg style="width: 24px; height: 24px" viewBox="0 0 24 24">
                          <path fill="currentColor" d="M5,20H19V18H5M19,9H15V3H9V9H5L12,16L19,9Z" />
                        </svg>
                      </div>
                    </a>
                  }
                </div>
              </div>
            </div>
          </div>
        }
      </div>
      <div class="col-span-1 order-1 md:order-2">
        @for (image of compact([w.imageLeft, w.imageRight, w.imageFront]); track image; let i = $index) {
          <picture [bbitPictureSrc]="image.s3Key" alt="Waffe"></picture>
        }
      </div>
    </div>
  </div>
}

<!-- <ng-container *ngIf="weapon | async as w">

</ng-container> -->
