export const ensureBeginningHashtag = (input: string): string => {
  if (input == null) {
    return input;
  }

  if (String(input)[0] !== '#') {
    input = `#${String(input)}`;
  }
  return input;
};

export const stripBeginningHashtag = (input: string): string => {
  if (input == null) {
    return input;
  }
  return String(input).replace(/^#/, '');
};
