import { ChangeDetectionStrategy, Component, type OnInit, inject } from '@angular/core';
import { BbitWebShopService, BbitWebShopVatCalculationMethod } from '@bbitgmbh/webshop.sdk';
import { DateTime } from 'luxon';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { AsyncPipe } from '@angular/common';
import { RouterLink } from '@angular/router';
import { I18nPipe } from '@bbitgmbh/website.sdk';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [ReactiveFormsModule, FormsModule, RouterLink, AsyncPipe, I18nPipe]
})
export class FooterComponent implements OnInit {
  webShop = inject(BbitWebShopService);

  public currentYear = DateTime.now().toFormat('yyyy');
  public vatCalc = [
    {
      _id: BbitWebShopVatCalculationMethod.INCLUSIVE,
      text: { key: 'priceInclVat' },
    },
    {
      _id: BbitWebShopVatCalculationMethod.EXCLUSIVE,
      text: { key: 'priceExclVat' },
    },
  ];

  ngOnInit(): void {}

  changeVatCalc(calc: BbitWebShopVatCalculationMethod) {
    this.webShop.setVatCalculationMethod(calc);
  }
}
