import { CdkMenuModule } from '@angular/cdk/menu';
import { OverlayModule } from '@angular/cdk/overlay';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { enableProdMode, importProvidersFrom, inject, provideAppInitializer } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { provideRouter, withInMemoryScrolling } from '@angular/router';
import { bbitProdEnvironmentKey } from '@bbitgmbh/bbit.utils';
import { BbitWebShopService } from '@bbitgmbh/webshop.sdk';
import {
  BbitAuthHttpInterceptorService,
  BbitCacheHttpInterceptorService,
  BbitStructuredDataService,
  BbitWebsiteService,
} from '@bbitgmbh/website.sdk';
import { NgIconsModule } from '@ng-icons/core';
import {
  heroArrowDown,
  heroArrowLeftOnRectangle,
  heroArrowRightOnRectangle,
  heroArrowUpRight,
  heroArrowsUpDown,
  heroBars3,
  heroCheck,
  heroCheckCircle,
  heroClock,
  heroExclamationTriangle,
  heroFaceFrown,
  heroMagnifyingGlass,
  heroQuestionMarkCircle,
  heroShoppingBag,
  heroTrash,
  heroUserCircle,
  heroXCircle,
  heroXMark,
} from '@ng-icons/heroicons/outline';
import {
  heroCheckCircleSolid,
  heroEnvelopeSolid,
  heroExclamationTriangleSolid,
  heroPhoneSolid,
} from '@ng-icons/heroicons/solid';
import { matCookie } from '@ng-icons/material-icons/baseline';
import { remixFacebookBoxFill, remixInstagramLine, remixTelegramFill } from '@ng-icons/remixicon';
import { register } from 'swiper/element/bundle';
import { appFactory } from './app/app-factory';
import { appRoutes } from './app/app-routes';
import { AppComponent } from './app/app.component';
import { translations } from './app/translations';
import { environment } from './environments/environment';

register();

if (environment.production) {
  enableProdMode();
}

bootstrapApplication(AppComponent, {
  providers: [
    importProvidersFrom(
      BrowserModule,
      ReactiveFormsModule,
      FormsModule,
      OverlayModule,
      NgIconsModule.withIcons({
        heroXMark,
        heroXCircle,
        heroCheckCircle,
        heroClock,
        heroCheck,
        heroQuestionMarkCircle,
        heroBars3,
        heroArrowRightOnRectangle,
        heroArrowLeftOnRectangle,
        heroUserCircle,
        heroShoppingBag,
        heroTrash,
        heroArrowDown,
        heroExclamationTriangle,
        heroFaceFrown,
        heroMagnifyingGlass,
        heroArrowsUpDown,
        heroArrowUpRight,
        remixFacebookBoxFill,
        remixInstagramLine,
        remixTelegramFill,
        matCookie,
        heroExclamationTriangleSolid,
        heroCheckCircleSolid,
        heroPhoneSolid,
        heroEnvelopeSolid,
      }),
      CdkMenuModule,
    ),
    provideAppInitializer(() => {
      const initializerFn = appFactory(
        inject(BbitWebShopService),
        inject(BbitWebsiteService),
        inject(BbitStructuredDataService),
      );
      return initializerFn();
    }),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BbitAuthHttpInterceptorService,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BbitCacheHttpInterceptorService,
      multi: true,
    },
    provideHttpClient(withInterceptorsFromDi()),
    provideRouter(
      appRoutes,
      withInMemoryScrolling({
        scrollPositionRestoration: 'enabled',
      }),
    ),
  ],
}).catch((err) => console.error(err));
