import { AsyncPipe } from '@angular/common';
import { Component, inject } from '@angular/core';
import { Router } from '@angular/router';
import { BbitPictureSrcDirective, I18nPipe } from '@bbitgmbh/website.sdk';
import { BehaviorSubject } from 'rxjs';
import { ApiService } from '../api/api.service';
import type { IWeaponModel } from '../api/weapon.interfaces';
import { PageHeaderComponent } from '../helper/page-header/page-header.component';
import { NavService } from '../nav/nav.service';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss'],
  imports: [AsyncPipe, I18nPipe, BbitPictureSrcDirective, PageHeaderComponent],
})
export class ProductsComponent {
  private _api = inject(ApiService);
  private _router = inject(Router);
  private _navService = inject(NavService);

  public weapons = new BehaviorSubject<any>([]);

  constructor() {
    this._api.getWeapons().then((weapons) => {
      console.log('arsch', weapons[0]);
      this.weapons.next(weapons);
    });
  }

  ngOnInit(): void {}

  routeToWeapon(weapon: IWeaponModel): void {
    this._router.navigate(['/products', weapon.code]);
  }
}
