<footer class="w-full bg-black">
  <div class="relative overflow-hidden">
    <div class="relative z-10">
      <div class="w-full flex flex-col mx-auto max-w-7xl py-4 sm:py-12 px-6 text-white z-10">
        <div class="flex flex-row justify-between">
          <div class="flex flex-col">
            <h3 class="font-bold mb-2">PHX Phoenix AG</h3>
            <div class="flex flex-col mt-4">
              <a href="mailto:info@phx.swiss" target="_blank"> info&#64;phx.swiss</a>
              <a href="tel:+41338211000" target="_blank">+41 33 821 10 00</a>
            </div>
          </div>
          <div class="flex flex-col">
            <div class="flex flex-row justify-end space-x-2">
              <a href="https://www.instagram.com/phxphoenixag/" target="_blank">
                <svg style="width: 24px; height: 24px" viewBox="0 0 24 24">
                  <path
                    fill="currentColor"
                    d="M7.8,2H16.2C19.4,2 22,4.6 22,7.8V16.2A5.8,5.8 0 0,1 16.2,22H7.8C4.6,22 2,19.4 2,16.2V7.8A5.8,5.8 0 0,1 7.8,2M7.6,4A3.6,3.6 0 0,0 4,7.6V16.4C4,18.39 5.61,20 7.6,20H16.4A3.6,3.6 0 0,0 20,16.4V7.6C20,5.61 18.39,4 16.4,4H7.6M17.25,5.5A1.25,1.25 0 0,1 18.5,6.75A1.25,1.25 0 0,1 17.25,8A1.25,1.25 0 0,1 16,6.75A1.25,1.25 0 0,1 17.25,5.5M12,7A5,5 0 0,1 17,12A5,5 0 0,1 12,17A5,5 0 0,1 7,12A5,5 0 0,1 12,7M12,9A3,3 0 0,0 9,12A3,3 0 0,0 12,15A3,3 0 0,0 15,12A3,3 0 0,0 12,9Z"
                  />
                </svg>
              </a>
              <a href="https://www.facebook.com/PHXphoenixAG/" target="_blank">
                <svg style="width: 24px; height: 24px" viewBox="0 0 24 24">
                  <path
                    fill="currentColor"
                    d="M12 2.04C6.5 2.04 2 6.53 2 12.06C2 17.06 5.66 21.21 10.44 21.96V14.96H7.9V12.06H10.44V9.85C10.44 7.34 11.93 5.96 14.22 5.96C15.31 5.96 16.45 6.15 16.45 6.15V8.62H15.19C13.95 8.62 13.56 9.39 13.56 10.18V12.06H16.34L15.89 14.96H13.56V21.96A10 10 0 0 0 22 12.06C22 6.53 17.5 2.04 12 2.04Z"
                  />
                </svg>
              </a>
              <a href="https://www.youtube.com/channel/UCzEhhe39jmw9CvCIyubeTaQ" target="_blank">
                <svg style="width: 24px; height: 24px" viewBox="0 0 24 24">
                  <path
                    fill="currentColor"
                    d="M10,15L15.19,12L10,9V15M21.56,7.17C21.69,7.64 21.78,8.27 21.84,9.07C21.91,9.87 21.94,10.56 21.94,11.16L22,12C22,14.19 21.84,15.8 21.56,16.83C21.31,17.73 20.73,18.31 19.83,18.56C19.36,18.69 18.5,18.78 17.18,18.84C15.88,18.91 14.69,18.94 13.59,18.94L12,19C7.81,19 5.2,18.84 4.17,18.56C3.27,18.31 2.69,17.73 2.44,16.83C2.31,16.36 2.22,15.73 2.16,14.93C2.09,14.13 2.06,13.44 2.06,12.84L2,12C2,9.81 2.16,8.2 2.44,7.17C2.69,6.27 3.27,5.69 4.17,5.44C4.64,5.31 5.5,5.22 6.82,5.16C8.12,5.09 9.31,5.06 10.41,5.06L12,5C16.19,5 18.8,5.16 19.83,5.44C20.73,5.69 21.31,6.27 21.56,7.17Z"
                  />
                </svg>
              </a>
            </div>
            <div class="mt-4 sm:flex sm:items-center sm:justify-between w-full">
              <div class="flex flex-col items-center sm:items-end text-black w-full">
                <label for="vatCalculation" class="sr-only">{{ { key: 'vatCalculation' } | i18n }}</label>
                <select
                  id="vatCalculation"
                  name="vatCalculation"
                  class="w-full block rounded-md bg-black text-white border-black py-2 pl-3 pr-10 text-sm focus:border-primary-500 focus:outline-none focus:ring-primary-500"
                  [ngModel]="webShop.observeVatCalculationMethod() | async"
                  (ngModelChange)="changeVatCalc($event)"
                >
                  @for (o of vatCalc; track o) {
                    <option [value]="o._id">
                      {{ o.text | i18n }}
                    </option>
                  }
                </select>
              </div>
            </div>
          </div>
        </div>
        <div class="flex flex-col md:flex-row mt-6 pt-6 border-t border-t-white border-opacity-40 justify-between text-sm">
          <div class="flex flex-row space-x-2">
            <a [routerLink]="['/imprint']">{{ { key: 'impressum' } | i18n }}</a>
            <a [routerLink]="['/privacy']">{{ { key: 'privacy' } | i18n }}</a>
            <!-- <a [routerLink]="['/legal']">Rechtliches</a> -->
          </div>
          <span>&copy; PHX Phoenix AG</span>
        </div>
      </div>
    </div>
  </div>
</footer>
