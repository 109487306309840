import { Component, input } from '@angular/core';
import { IBbitI18n } from '@bbitgmbh/bbit.utils';
import { I18nPipe } from '@bbitgmbh/website.sdk';

@Component({
  selector: 'app-page-header',
  imports: [I18nPipe],
  templateUrl: './page-header.component.html',
  styleUrl: './page-header.component.scss',
})
export class PageHeaderComponent {
  public title = input<IBbitI18n>('');
  public lead = input<IBbitI18n>('');
}
