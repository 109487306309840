<app-page-header [title]="'News'"></app-page-header>

<div class="relative mx-auto max-w-7xl mt-12 mb-12 px-4 md:px-8">
  <h2 class="font-bold">{{ { key: 'latestNews' } | i18n }}</h2>
  <div class="mx-auto mt-12 grid gap-x-6 gap-y-8 md:grid-cols-2 lg:grid-cols-3">
    @for (item of news | async; track item._id) {
      <ng-container *ngTemplateOutlet="itemTemplate; context: { item: item }"></ng-container>
    }
  </div>

  <h2 class="font-bold mt-24">History</h2>
  <div class="mx-auto mt-12 grid gap-x-6 gap-y-8 md:grid-cols-2 lg:grid-cols-3">
    @for (item of history | async; track item._id) {
      <ng-container *ngTemplateOutlet="itemTemplate; context: { item: item }"></ng-container>
    }
  </div>
</div>

<ng-template #itemTemplate let-item="item">
  <div class="flex flex-col overflow-hidden rounded-sm shadow-md bg-white hover:bg-orange-200">
    <div class="flex-shrink-0">
      <img class="h-48 w-full object-cover" [src]="item.image" alt="" />
    </div>
    <div class="flex flex-1 flex-col justify-between p-6">
      <div class="flex-1">
        <a (click)="routeToNews(item)" target="_blank" class="mt-2 block cursor-pointer text-xl font-semibold text-gray-900">
          {{ { object: item.title } | i18n }}
        </a>
      </div>
      <div class="mt-6 flex items-center">
        <div class="flex space-x-1 text-sm text-gray-500">
          <time>{{ { date: item.from, format: 'dd.LL.yyyy' } | luxon }}</time>
        </div>
      </div>
    </div>
  </div>
</ng-template>
