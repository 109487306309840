@if (news | async; as n) {
  <app-page-header [title]="{ object: n.title }" [lead]="'Leissigen, ' + n.newsDate"></app-page-header>

  <div class="relative mx-auto max-w-7xl mt-12 mb-12 px-4 md:px-8">
    <div class="grid grid-cols-1 md:grid-cols-2 gap-6">
      <div [class]="n.description || n.moreInfoLink ? 'col-span-1' : 'col-span-2'">
        <div class="w-full flex flex-col justify-center">
          <picture [bbitPictureSrc]="n.image" alt="News"></picture>
        </div>
      </div>
      @if (n.description || n.moreInfoLink) {
        <div class="col-span-1">
          <p class="mx-auto mt-3 mr-auto lg:mr-6 max-w-md text-lg sm:text-xl md:mt-5 md:max-w-3xl" [innerHTML]="n.description"></p>
          @if (n.moreInfoLink) {
            <div class="mt-10 sm:flex sm:justify-center lg:justify-start">
              <div class="rounded-md shadow">
                <a
                  [href]="n.moreInfoLink"
                  _target="blank"
                  class="flex w-full items-center justify-center rounded-md border border-transparent bg-orange-600 px-8 py-3 text-base font-medium text-white hover:bg-orange-700 md:py-4 md:px-10 md:text-lg"
                  >{{ { key: 'moreInfo' } | i18n }}</a
                >
              </div>
            </div>
          }
        </div>
      }
    </div>
  </div>
}
