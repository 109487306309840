<div class="relative flex flex-col justify-center items-center mx-auto w-full bg-black h-72">
  <div class="flame-bg"></div>

  <div class="text-center relative py-24 z-10 flex flex-col items-center justify-center">
    @if (title()) {
      <h2 class="text-3xl font-bold text-white sm:text-4xl">
        {{ title() | i18n }}
      </h2>
    }

    @if (lead()) {
      <p class="mx-auto mt-3 max-w-2xl text-xl text-white sm:mt-4">
        {{ lead() | i18n }}
      </p>
    }
  </div>
</div>
