<div class="relative overflow-hidden bg-white">
  <div class="mx-auto max-w-7xl">
    <div class="relative z-10 bg-white pb-8 sm:pb-16 md:pb-20 lg:w-full lg:max-w-2xl lg:pb-28 xl:pb-32">
      <div>
        <div class="relative px-4 pt-6 sm:px-6 lg:px-8"></div>
      </div>

      <div class="mx-auto mt-10 max-w-7xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28">
        <div class="sm:text-center lg:text-left">
          <h1 class="text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl md:text-6xl">
            <span class="block xl:inline"><span class="text-primary-600">Rechtliche Dokumente</span></span>
          </h1>
          <p class="mt-3 text-base text-gray-500 sm:mx-auto sm:mt-5 sm:max-w-xl sm:text-lg md:mt-5 md:text-xl lg:mx-0">
            <span class="text-nowrap">{{ (identity | async)?.fullName }}</span>
          </p>
        </div>
      </div>
    </div>
  </div>
</div>

@for (legalDoc of legalDocs | async; track legalDoc) {
  <div class="bg-white py-4 sm:py-8">
    <div class="mx-auto grid max-w-7xl gap-x-8 px-6 lg:px-8 xl:grid-cols-3">
      <div class="max-w-2xl">
        <h2 class="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
          {{ legalDoc.title | i18n }}
        </h2>
        <!-- <p class="mt-6 text-lg leading-8 text-gray-600">
          Dieser Abschnitt ist nur auf deutsch verfügbar.
        </p> -->
      </div>
      <div class="grid xl:col-span-2">
        @if (legalDoc.description) {
          <p>
            {{ legalDoc.description | i18n }}
          </p>
        }
        <p>
          Download&nbsp;<a class="text-primary-600" [href]="legalDoc.url" target="_blank"
            >{{ legalDoc.title | i18n }} Version {{ { date: legalDoc.versionDate, format: 'dd.LL.yyyy' } | luxon }}</a
          >
        </p>
        @if (Object.keys(legalDoc.translations || {}).length > 0) {
          <div>
            <h2 class="text-xl font-bold tracking-tight text-gray-900 sm:text-2xl">Übersetzungen</h2>
            <ul>
              @for (language of Object.keys(legalDoc?.translations || {}); track language) {
                <li>
                  <a class="text-primary-600" [href]="$any(legalDoc.translations)[language].url" target="_blank">{{
                    { key: language } | i18n
                  }}</a>
                </li>
              }
            </ul>
          </div>
        }
      </div>
    </div>
  </div>
}
