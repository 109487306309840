import { BbitI18n, BbitLanguage, bbitProdEnvironmentKey } from '@bbitgmbh/bbit.utils';
import { BbitWebShopService, webShopTranslations } from '@bbitgmbh/webshop.sdk';
import { BbitImageOptimizationType, BbitStructuredDataService, BbitWebsiteService } from '@bbitgmbh/website.sdk';
import { environment } from '../environments/environment';
import { translations } from './translations';

export function appFactory(
  webshop: BbitWebShopService,
  website: BbitWebsiteService,
  structuredData: BbitStructuredDataService,
) {
  return async () => {
    BbitI18n.setDefaultLanguage(BbitLanguage.DE_CH);
    BbitI18n.setDefaultTimezone('Europe/Zurich');
    BbitI18n.addTranslations(webShopTranslations);
    BbitI18n.addTranslations(translations);

    await Promise.all([
      webshop.initialize({
        organizationKey: 'phx-phoenix',
        environmentKey: 'prod',
        authConfigKey: 'web-shop-prod',
        salesChannel: '#AxY2D2EKsePTNNaKzCkkw',
        imageOptimization: {
          type: BbitImageOptimizationType.IMAGEBOSS,
          imageBossKey: 'phx-s3',
        },
        devBlApiUrl: !environment.production ? 'http://localhost:4301' : undefined,
      }),
    ]);

    const identity = website.getAuthMeta()?.environments?.[bbitProdEnvironmentKey]?.identity;
    structuredData.initialize({
      name: identity?.fullName || '',
      url: 'https://phx.swiss',
      phone: identity?.mainPhone || '',
      eMail: identity?.mainEMail || '',
    });
  };
}
