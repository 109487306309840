<div class="flex flex-row justify-between mb-8 max-w-128">
  <h2 class="font-bold">
    {{ { key: 'profile', n: 1 } | i18n }}
  </h2>
  <a
    [href]="'https://' + organizationKey + '.b-bit.cloud/account'"
    target="_blank"
    class="content-around center align-middle rounded-md border border-transparent bg-primary-600 py-4 px-4 text-sm font-medium text-black shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
    >
    {{ { key: 'manageMyAccount' } | i18n }}
  </a>
</div>

<div class="space-y-6">
  <form [formGroup]="profileForm" (ngSubmit)="onSubmit()">
    <div class="sm:overflow-hidden sm:rounded-md">
      <!-- shadow -->
      <div class="space-y-6 bg-white py-6">
        <div class="grid grid-cols-[100px_minmax(100px,_1fr)] gap-4">
          <div class="font-medium">{{ { key: 'eMail' } | i18n }}</div>
          <div class="whitespace-pre-line">{{ (contactSubj | async)?.eMail }}</div>
          <div class="font-medium">{{ { key: 'password' } | i18n }}</div>
          <div class="whitespace-pre-line">**********</div>
          <div class="font-medium">{{ { key: 'address' } | i18n }}</div>
          <div class="whitespace-pre-line">{{ addressObs | async }}</div>
        </div>

        <!-- <div class="rounded-md bg-primary-50 p-4">
        <div class="flex">
          <div class="flex-shrink-0">
            <ng-icon
              name="heroExclamationTriangleSolid"
              class="text-primary-400"
              size="20px"
            ></ng-icon>
          </div>
          <div class="ml-3">
            <h3 class="text-sm font-medium text-primary-800">
              {{ { key: "editingLocked" } | i18n }}
            </h3>
            <div class="mt-2 text-sm text-primary-700">
              <p>
                {{ { key: "editingLockedInfo" } | i18n }}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="grid grid-cols-6 gap-6">
        <div class="col-span-6" formGroupName="name">
          <label
            for="salutation"
            class="block text-sm font-medium text-gray-700"
            >{{ { key: "salutation" } | i18n }}</label
            >
            <select
              type="text"
              name="salutation"
              id="name"
              formControlName="salutation"
              autocomplete="salutation"
              class="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-primary-500 focus:outline-none focus:ring-primary-500 sm:text-sm"
              (change)="
                profileForm
                  .get('salutation')
                  ?.setValue($any($event.target)?.value)
              "
              >
              <option value="#male">{{ { key: "male" } | i18n }}</option>
              <option value="#female">{{ { key: "female" } | i18n }}</option>
              <option value="#company">{{ { key: "company" } | i18n }}</option>
            </select>
          </div>
          <div class="col-span-6" formGroupName="name">
            <label
              for="companyName"
              class="block text-sm font-medium text-gray-700"
              >{{ { key: "company" } | i18n }}</label
              >
              <input
                type="text"
                name="companyName"
                id="name"
                formControlName="companyName"
                autocomplete="name"
                class="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-primary-500 focus:outline-none focus:ring-primary-500 sm:text-sm"
                />
            </div>

            <div class="col-span-6">
              <label
                for="email-address"
                class="block text-sm font-medium text-gray-700"
                >Email</label
                >
                <input
                  type="text"
                  name="email-address"
                  id="email-address"
                  autocomplete="email"
                  formControlName="eMail"
                  class="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-primary-500 focus:outline-none focus:ring-primary-500 sm:text-sm"
                  />
              </div>

              <div class="col-span-6" formGroupName="address">
                <label
                  for="street-address"
                  class="block text-sm font-medium text-gray-700"
                  >{{ { key: "street" } | i18n }}</label
                  >
                  <input
                    type="text"
                    name="street-address"
                    id="street-address"
                    autocomplete="street-address"
                    formControlName="street"
                    class="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-primary-500 focus:outline-none focus:ring-primary-500 sm:text-sm"
                    />
                </div>

                <div
                  class="col-span-6 sm:col-span-6 lg:col-span-2"
                  formGroupName="address"
                  >
                  <label
                    for="postal-code"
                    class="block text-sm font-medium text-gray-700"
                    >{{ { key: "postalCode" } | i18n }}</label
                    >
                    <input
                      type="text"
                      name="postal-code"
                      id="postal-code"
                      autocomplete="postal-code"
                      formControlName="postalCode"
                      class="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-primary-500 focus:outline-none focus:ring-primary-500 sm:text-sm"
                      />
                  </div>

                  <div
                    class="col-span-6 sm:col-span-6 lg:col-span-4"
                    formGroupName="address"
                    >
                    <label for="city" class="block text-sm font-medium text-gray-700">{{
                      { key: "locality" } | i18n
                    }}</label>
                    <input
                      type="text"
                      name="city"
                      id="city"
                      autocomplete="address-level2"
                      formControlName="locality"
                      class="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-primary-500 focus:outline-none focus:ring-primary-500 sm:text-sm"
                      />
                  </div>
                </div>

                <!-- <div class="col-span-6">
                <label for="country" class="block text-sm font-medium text-gray-700"
                  >Country</label
                  >
                  <select
                    id="country"
                    name="country"
                    autocomplete="country-name"
                    class="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-primary-500 focus:outline-none focus:ring-primary-500 sm:text-sm"
                    >
                    <option>United States</option>
                    <option>Canada</option>
                    <option>Mexico</option>
                  </select>
                </div> -->
              </div>
              @if (profileForm.touched) {
                <div class="bg-gray-50 px-4 py-3 text-right sm:px-6">
                  <button
                    type="submit"
                    class="inline-flex justify-center rounded-md border border-transparent bg-primary-600 py-2 px-4 text-sm font-medium text-black shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
                    >
                    {{ { key: 'save', n: 1 } | i18n }}
                  </button>
                </div>
              }
            </div>
          </form>
        </div>
