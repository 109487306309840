import { AsyncPipe } from '@angular/common';
import { Component, inject } from '@angular/core';
import { Router } from '@angular/router';
import { BbitI18n } from '@bbitgmbh/bbit.utils';
import { I18nPipe } from '@bbitgmbh/website.sdk';
import { BehaviorSubject } from 'rxjs';
import { ApiService } from '../api/api.service';
import { PageHeaderComponent } from '../helper/page-header/page-header.component';
import { NavService } from '../nav/nav.service';

@Component({
  selector: 'app-downloads',
  imports: [AsyncPipe, PageHeaderComponent, I18nPipe],
  templateUrl: './downloads.component.html',
  styleUrl: './downloads.component.scss',
})
export class DownloadsComponent {
  private _api = inject(ApiService);
  private _router = inject(Router);
  private _navService = inject(NavService);

  public weapons = new BehaviorSubject<any>([]);

  constructor() {
    this._api.getWeapons().then((weapons) => {
      this.weapons.next(
        weapons.sort(
          (a, b) => a.designation['de-CH']?.['f-']?.localeCompare(b.designation['de-CH']?.['f-'] || '') || 0,
        ),
      );
    });
  }

  filterManualsByLanguage(manuals: any[]): any[] {
    const byLang = manuals?.filter((m) => m.language === `#${BbitI18n.getLanguage()}` && m.s3Key) || [];

    const cleanupUrl = (url: string): string => {
      const urlWithSlash = url.startsWith('/') ? url : `/${url}`;

      const splitted = urlWithSlash.split('/');
      const combined = splitted.map((s) => encodeURIComponent(s)).join('/');
      return `https://phx.swiss${combined}`;
    };

    const getFileName = (url: string): string | undefined => {
      return url.split('/').pop();
    };

    return byLang.map((m) => ({
      url: cleanupUrl(m.s3Key),
      designation: m.designation || getFileName(m.s3Key),
    }));
  }
}
