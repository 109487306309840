<div class="relative overflow-hidden bg-white">
  <div class="mx-auto max-w-7xl">
    <div class="relative z-10 bg-white pb-8 sm:pb-16 md:pb-20 lg:w-full lg:max-w-2xl lg:pb-28 xl:pb-32">
  

      <div>
        <div class="relative px-4 pt-6 sm:px-6 lg:px-8"></div>
      </div>

      <div class="mx-auto mt-10 max-w-7xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28">
        <div class="sm:text-center lg:text-left">
          <h1 class="text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl md:text-6xl">
            <span class="block xl:inline"
              ><span class="text-primary-600">Datenschutzerklärung </span
              ><span class="text-nowrap">{{ (identity | async)?.fullName }}</span></span
            >
          </h1>
          <p class="mt-3 text-base text-gray-500 sm:mx-auto sm:mt-5 sm:max-w-xl sm:text-lg md:mt-5 md:text-xl lg:mx-0">
            Die nachfolgenden Informationen geben Ihnen einen Überblick über die Erhebung und Verarbeitung Ihrer personenbezogenen Daten
            durch uns.
          </p>
        </div>
      </div>
    </div>
  </div>
  <!-- <div class="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
    <img
      class="h-56 w-full object-cover sm:h-72 md:h-96 lg:h-full lg:w-full"
      src="/assets/images/protection.jpg"
      alt=""
    />
  </div> -->
</div>

<div class="bg-white py-4 sm:py-8">
  <div class="mx-auto grid max-w-7xl gap-x-8 px-6 lg:px-8 xl:grid-cols-3">
    <div class="max-w-2xl">
      <h2 class="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Allgemein</h2>
      <p class="mt-6 text-lg leading-8 text-gray-600"></p>
    </div>
    <p class="grid xl:col-span-2">
      Der Schutz Ihrer persönlichen Daten ist uns ein besonderes Anliegen. Wir,
      {{ (identity | async)?.fullName }} mit Sitz in {{ (identity | async)?.locality }}, verarbeiten Ihre Daten daher ausschliesslich auf
      Grundlage der gesetzlichen Bestimmungen des Schweizer Datenschutzgesetzes sowie der EU-Datenschutzgrundverordnung (nachfolgend
      «DSGVO»). In dieser Datenschutzerklärung informieren wir Sie über die wichtigsten Aspekte der Datenverarbeitung im Rahmen unserer
      Website.<br /><br />
      {{ (identity | async)?.fullName }} kann die Datenschutzerklärung jederzeit einseitig ändern. Die aktuelle Fassung publiziert
      {{ (identity | async)?.fullName }} jeweils online.
    </p>
  </div>
</div>

<div class="bg-white py-4 sm:py-8">
  <div class="mx-auto grid max-w-7xl gap-x-8 px-6 lg:px-8 xl:grid-cols-3">
    <div class="max-w-2xl">
      <h2 class="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Kontakt mit uns</h2>
      <p class="mt-6 text-lg leading-8 text-gray-600"></p>
    </div>
    <p class="grid xl:col-span-2">
      Wenn Sie per Formular auf der Website oder per E-Mail Kontakt mit uns aufnehmen, werden Ihre angegeben Daten zwecks Bearbeitung der
      Anfrage und für den Fall von Anschlussfragen bis zu sechs Monate bei uns gespeichert.
    </p>
  </div>
</div>

<div class="bg-white py-4 sm:py-8">
  <div class="mx-auto grid max-w-7xl gap-x-8 px-6 lg:px-8 xl:grid-cols-3">
    <div class="max-w-2xl">
      <h2 class="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Cookies</h2>
      <p class="mt-6 text-lg leading-8 text-gray-600"></p>
    </div>
    <p class="grid xl:col-span-2">
      Bei Cookies handelt es sich um kleine Textdateien, die mit Hilfe des Browsers auf Ihrem Endgerät abgelegt werden. Sie richten keinen
      Schaden an. Wir verwenden diese Cookies dazu, unser Angebot nutzerfreundlich zu gestalten. Einige Cookies bleiben auf Ihrem Endgerät
      gespeichert, bis Sie diese löschen. Sie ermöglichen es uns, Ihren Browser beim nächsten Besuch wiederzuerkennen. Wenn Sie dies nicht
      wünschen, so können Sie Ihren Browser so einrichten, dass er Sie über das Setzen von Cookies informiert und Sie dies nur im Einzelfall
      erlauben. Bei der Deaktivierung von Cookies kann die Funktionalität unserer Website eingeschränkt sein.
    </p>
  </div>
</div>

<div class="bg-white py-4 sm:py-8">
  <div class="mx-auto grid max-w-7xl gap-x-8 px-6 lg:px-8 xl:grid-cols-3">
    <div class="max-w-2xl">
      <h2 class="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Datenspeicherung</h2>
      <p class="mt-6 text-lg leading-8 text-gray-600"></p>
    </div>
    <div class="grid xl:col-span-2">
      <p>
        Die von Ihnen bereit gestellten Daten sind zur Vertragserfüllung beziehungsweise zur Durchführung vorvertraglicher Massnahmen
        erforderlich. Ohne die Daten können wir den Vertrag mit Ihnen nicht abschliessen. Eine Datenübermittlung an Dritte erfolgt nicht,
        mit Ausnahme von:
      </p>
      <ul class="list list-disc list-outside space-y-4 ml-6 mb-6 mt-6">
        <li>
          <a href="https://aws.amazon.com/compliance/gdpr-center/" target="_blank">Amazon Web Services</a>
          das Teile unsere Website hostet und zur fehlerfreien Erfüllung dieser Dienstleistung Einsicht in den Datenverkehr nehmen kann
        </li>
        <li>
          <a href="https://www.cloudflare.com/en-gb/gdpr/introduction/" target="blank">Cloudflare</a>
          das Teile unsere Website hostet und zur fehlerfreien Erfüllung dieser Dienstleistung Einsicht in den Datenverkehr nehmen kann
        </li>
        <li>
          <a href="https://www.algolia.com/policies/terms/" target="_blank">Algolia</a>
          das Teile unsere Website hostet und zur fehlerfreien Erfüllung dieser Dienstleistung Einsicht in den Datenverkehr nimmt,
          zusätzlich werden für die Suche und Filterung relevante Daten für eine verbesserung der Funktionalität gesammelt
        </li>
        <li>
          <a href="https://posthog.com/privacy" target="_blank">PostHog</a>
          das Analyse-Daten für eine bessere Wartung und Zuverlässigkeit unserer Website sammelt und zur fehlerfreien Erfüllung dieser
          beiträgt
        </li>
        <li>
          dem Treuhänder, dem Unternehmensberater sowie den Steuerbehörden zur Erfüllung der Geschäftsbuchführungspflicht nach Schweizer
          Recht
        </li>
        <li>bei technischen Problemen einem allfälligen Supportmitarbeiter der IT-Dienstleister</li>
      </ul>
      <p>
        Mit dem Treuhänder, dem Unternehmensberater, der IT-Dienstleister bestehen entsprechende Geheimhaltungsverträge. Mit Amazon Web
        Services und Cloudflare haben wir einen Vertrag zur Auftragsdatenverarbeitung (Data Processing Addendum) abgeschlossen.
      </p>
    </div>
  </div>
</div>

<div class="bg-white py-4 sm:py-8">
  <div class="mx-auto grid max-w-7xl gap-x-8 px-6 lg:px-8 xl:grid-cols-3">
    <div class="max-w-2xl">
      <h2 class="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Web-Analyse</h2>
      <p class="mt-6 text-lg leading-8 text-gray-600"></p>
    </div>
    <div class="grid xl:col-span-2">
      <p>
        Zur Analyse der Webseite speichern wir seitens Cloudflare für 30 Tage die Logs der Serveranfragen, anhand deren wir statistische
        Angaben über die Anzahl Seitenaufruf und die ungefähre Besucheranzahl erhalten.
      </p>
      <p>
        Die Datenverarbeitung erfolgt auf Basis der gesetzlichen Bestimmungen des Art 6 Abs 1 lit a (Einwilligung) und/oder f (berechtigtes
        Interesse) der DSGVO.
      </p>
      <p>
        Unser Anliegen im Sinne der DSGVO (berechtigtes Interesse) ist die Verbesserung unseres Angebotes und unseres Webauftritts. Da uns
        die Privatsphäre unserer Nutzer wichtig ist, werden die Nutzerdaten pseudonymisiert.
      </p>
    </div>
  </div>
</div>

<div class="bg-white py-4 sm:py-8">
  <div class="mx-auto grid max-w-7xl gap-x-8 px-6 lg:px-8 xl:grid-cols-3">
    <div class="max-w-2xl">
      <h2 class="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Ihre Rechte</h2>
      <p class="mt-6 text-lg leading-8 text-gray-600"></p>
    </div>
    <div class="grid xl:col-span-2">
      <p>
        Ihnen stehen grundsätzlich die Rechte auf Auskunft, Berichtigung, Löschung, Einschränkung, Datenübertragbarkeit, Widerruf und
        Widerspruch zu. Die genannten Rechte können verweigert bzw. eingeschränkt werden, wenn die Interessen, Rechte und Freiheiten von
        Drittpersonen überwiegen oder die Verarbeitung der Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen von
        {{ (identity | async)?.fullName }} dient. Anfragen zur Verarbeitung von Personendaten sind an den Datenschutzverantwortlichen von
        {{ (identity | async)?.fullName }} zu richten.
      </p>
    </div>
  </div>
</div>

<div class="bg-white py-4 sm:py-8">
  <div class="mx-auto grid max-w-7xl gap-x-8 px-6 lg:px-8 xl:grid-cols-3">
    <div class="max-w-2xl">
      <h2 class="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Datenschutz-Verantwortlicher</h2>
      <p class="mt-6 text-lg leading-8 text-gray-600"></p>
    </div>
    <div class="grid xl:col-span-2">
      <p>
        {{ (identity | async)?.fullName }}<br />Datenschutz-Verantwortlicher<br />{{ (identity | async)?.street }}<br />{{
          (identity | async)?.postalCode
        }}&nbsp;{{ (identity | async)?.locality }}<br />{{ (identity | async)?.countryName }}
      </p>
      <p>
        Tel: <a [href]="phoneLink | async" target="_blank">{{ (identity | async)?.mainPhone }}</a>
        <br />
        E-Mail:
        <a [href]="mailToLink | async" target="_blank">{{ (identity | async)?.mainEMail }}</a>
      </p>

      <p class="version">Version 1.0.0 vom 01. Januar 2024 / {{ (identity | async)?.fullName }}</p>
    </div>
  </div>
</div>
