export const countryRecords = [
  {
    _id: '#AD',
    continent: '#EU',
    designation: {
      'de-CH': {
        'f-': 'Andorra',
      },
      'en-US': {
        'f-': 'Andorra',
      },
      'fr-CH': {
        'f-': 'Andorre',
      },
    },
    ePoliceId: '2a88c3a9-e761-11e8-aea4-43375a4a09b8',
    iso3166: 'AD',
    lat: 42.5,
    lon: 1.5,
    officialDesignation: {
      'de-CH': {
        'f-': 'Fürstentum Andorra',
      },
      'en-US': {
        'f-': 'Principality of Andorra',
      },
      'fr-CH': {
        'f-': "Principauté d'Andorre",
      },
    },
  },
  {
    _id: '#AE',
    continent: '#AS',
    designation: {
      'de-CH': {
        'f-': 'Vereinigte Arabische Emirate',
      },
      'en-US': {
        'f-': 'United Arab Emirates',
      },
      'fr-CH': {
        'f-': 'Émirats arabes unis',
      },
    },
    iso3166: 'AE',
    lat: 24,
    lon: 54,
    officialDesignation: {
      'de-CH': {
        'f-': 'Vereinigte Arabische Emirate',
      },
      'en-US': {
        'f-': 'United Arab Emirates',
      },
      'fr-CH': {
        'f-': 'Émirats arabes unis',
      },
    },
  },
  {
    _id: '#AF',
    continent: '#AS',
    designation: {
      'de-CH': {
        'f-': 'Afghanistan',
      },
      'en-US': {
        'f-': 'Afghanistan',
      },
      'fr-CH': {
        'f-': 'Afghanistan',
      },
    },
    iso3166: 'AF',
    lat: 33,
    lon: 65,
    officialDesignation: {
      'de-CH': {
        'f-': 'Islamische Republik Afghanistan',
      },
      'en-US': {
        'f-': 'Islamic Republic of Afghanistan',
      },
      'fr-CH': {
        'f-': "République islamique d'Afghanistan",
      },
    },
  },
  {
    _id: '#AG',
    continent: '#NA',
    designation: {
      'de-CH': {
        'f-': 'Antigua und Barbuda',
      },
      'en-US': {
        'f-': 'Antigua and Barbuda',
      },
      'fr-CH': {
        'f-': 'Antigua-et-Barbuda',
      },
    },
    iso3166: 'AG',
    lat: 17.05,
    lon: -61.8,
    officialDesignation: {
      'de-CH': {
        'f-': 'Antigua und Barbuda',
      },
      'en-US': {
        'f-': 'Antigua and Barbuda',
      },
      'fr-CH': {
        'f-': 'Antigua -et-Barbuda',
      },
    },
  },
  {
    _id: '#AI',
    continent: '#NA',
    designation: {
      'de-CH': {
        'f-': 'Anguilla',
      },
      'en-US': {
        'f-': 'Anguilla',
      },
      'fr-CH': {
        'f-': 'Anguilla',
      },
    },
    iso3166: 'AI',
    lat: 18.25,
    lon: -63.16666666,
    officialDesignation: {
      'de-CH': {
        'f-': 'Anguilla',
      },
      'en-US': {
        'f-': 'Anguilla',
      },
      'fr-CH': {
        'f-': 'Anguilla',
      },
    },
  },
  {
    _id: '#AL',
    continent: '#EU',
    designation: {
      'de-CH': {
        'f-': 'Albanien',
      },
      'en-US': {
        'f-': 'Albania',
      },
      'fr-CH': {
        'f-': 'Albanie',
      },
    },
    ePoliceId: '2a88c3a8-e761-11e8-aea4-43375a4a09b8',
    iso3166: 'AL',
    lat: 41,
    lon: 20,
    officialDesignation: {
      'de-CH': {
        'f-': 'Republik Albanien',
      },
      'en-US': {
        'f-': 'Republic of Albania',
      },
      'fr-CH': {
        'f-': "République d'Albanie",
      },
    },
  },
  {
    _id: '#AM',
    continent: '#AS',
    designation: {
      'de-CH': {
        'f-': 'Armenien',
      },
      'en-US': {
        'f-': 'Armenia',
      },
      'fr-CH': {
        'f-': 'Arménie',
      },
    },
    iso3166: 'AM',
    lat: 40,
    lon: 45,
    officialDesignation: {
      'de-CH': {
        'f-': 'Republik Armenien',
      },
      'en-US': {
        'f-': 'Republic of Armenia',
      },
      'fr-CH': {
        'f-': "République d'Arménie",
      },
    },
  },
  {
    _id: '#AO',
    continent: '#AF',
    designation: {
      'de-CH': {
        'f-': 'Angola',
      },
      'en-US': {
        'f-': 'Angola',
      },
      'fr-CH': {
        'f-': 'Angola',
      },
    },
    iso3166: 'AO',
    lat: -12.5,
    lon: 18.5,
    officialDesignation: {
      'de-CH': {
        'f-': 'Republik Angola',
      },
      'en-US': {
        'f-': 'Republic of Angola',
      },
      'fr-CH': {
        'f-': "République d'Angola",
      },
    },
  },
  {
    _id: '#AQ',
    continent: '#AN',
    designation: {
      'de-CH': {
        'f-': 'Antarktis',
      },
      'en-US': {
        'f-': 'Antarctica',
      },
      'fr-CH': {
        'f-': 'Antarctique',
      },
    },
    iso3166: 'AQ',
    lat: -90,
    lon: 0,
    officialDesignation: {
      'de-CH': {
        'f-': 'Antarktika',
      },
      'en-US': {
        'f-': 'Antarctica',
      },
      'fr-CH': {
        'f-': 'Antarctique',
      },
    },
  },
  {
    _id: '#AR',
    continent: '#SA',
    designation: {
      'de-CH': {
        'f-': 'Argentinien',
      },
      'en-US': {
        'f-': 'Argentina',
      },
      'fr-CH': {
        'f-': 'Argentine',
      },
    },
    ePoliceId: '2a893949-e761-11e8-aea4-43375a4a09b8',
    iso3166: 'AR',
    lat: -34,
    lon: -64,
    officialDesignation: {
      'de-CH': {
        'f-': 'Argentinische Republik',
      },
      'en-US': {
        'f-': 'Argentine Republic',
      },
      'fr-CH': {
        'f-': 'République argentine',
      },
    },
  },
  {
    _id: '#AS',
    continent: '#OC',
    designation: {
      'de-CH': {
        'f-': 'Amerikanisch-Samoa',
      },
      'en-US': {
        'f-': 'American Samoa',
      },
      'fr-CH': {
        'f-': 'Samoa américaines',
      },
    },
    iso3166: 'AS',
    lat: -14.33333333,
    lon: -170,
    officialDesignation: {
      'de-CH': {
        'f-': 'Amerikanisch-Samoa',
      },
      'en-US': {
        'f-': 'American Samoa',
      },
      'fr-CH': {
        'f-': 'Samoa américaines',
      },
    },
  },
  {
    _id: '#AT',
    continent: '#EU',
    designation: {
      'de-CH': {
        'f-': 'Österreich',
      },
      'en-US': {
        'f-': 'Austria',
      },
      'fr-CH': {
        'f-': 'Autriche',
      },
    },
    ePoliceId: '2a88c3be-e761-11e8-aea4-43375a4a09b8',
    iso3166: 'AT',
    lat: 47.33333333,
    lon: 13.33333333,
    officialDesignation: {
      'de-CH': {
        'f-': 'Republik Österreich',
      },
      'en-US': {
        'f-': 'Republic of Austria',
      },
      'fr-CH': {
        'f-': "République d'Autriche",
      },
    },
  },
  {
    _id: '#AU',
    continent: '#OC',
    ePoliceId: '2a9127ef-e761-11e8-aea4-43375a4a09b8',
    designation: {
      'de-CH': {
        'f-': 'Australien',
      },
      'en-US': {
        'f-': 'Australia',
      },
      'fr-CH': {
        'f-': 'Australie',
      },
    },
    iso3166: 'AU',
    lat: -27,
    lon: 133,
    officialDesignation: {
      'de-CH': {
        'f-': 'Commonwealth Australien',
      },
      'en-US': {
        'f-': 'Commonwealth of Australia',
      },
      'fr-CH': {
        'f-': 'Australie',
      },
    },
  },
  {
    _id: '#AW',
    continent: '#NA',
    designation: {
      'de-CH': {
        'f-': 'Aruba',
      },
      'en-US': {
        'f-': 'Aruba',
      },
      'fr-CH': {
        'f-': 'Aruba',
      },
    },
    iso3166: 'AW',
    lat: 12.5,
    lon: -69.96666666,
    officialDesignation: {
      'de-CH': {
        'f-': 'Aruba',
      },
      'en-US': {
        'f-': 'Aruba',
      },
      'fr-CH': {
        'f-': 'Aruba',
      },
    },
  },
  {
    _id: '#AX',
    continent: '#EU',
    designation: {
      'de-CH': {
        'f-': 'Åland',
      },
      'en-US': {
        'f-': 'Åland Islands',
      },
      'fr-CH': {
        'f-': 'Ahvenanmaa',
      },
    },
    iso3166: 'AX',
    lat: 60.116667,
    lon: 19.9,
    officialDesignation: {
      'de-CH': {
        'f-': 'Åland-Inseln',
      },
      'en-US': {
        'f-': 'Åland Islands',
      },
      'fr-CH': {
        'f-': 'Ahvenanmaa',
      },
    },
  },
  {
    _id: '#AZ',
    continent: '#AS',
    designation: {
      'de-CH': {
        'f-': 'Aserbaidschan',
      },
      'en-US': {
        'f-': 'Azerbaijan',
      },
      'fr-CH': {
        'f-': 'Azerbaïdjan',
      },
    },
    iso3166: 'AZ',
    lat: 40.5,
    lon: 47.5,
    officialDesignation: {
      'de-CH': {
        'f-': 'Republik Aserbaidschan',
      },
      'en-US': {
        'f-': 'Republic of Azerbaijan',
      },
      'fr-CH': {
        'f-': "République d'Azerbaïdjan",
      },
    },
  },
  {
    _id: '#BA',
    continent: '#EU',
    designation: {
      'de-CH': {
        'f-': 'Bosnien und Herzegowina',
      },
      'en-US': {
        'f-': 'Bosnia and Herzegovina',
      },
      'fr-CH': {
        'f-': 'Bosnie-Herzégovine',
      },
    },
    ePoliceId: '2a88eadf-e761-11e8-aea4-43375a4a09b8',
    iso3166: 'BA',
    lat: 44,
    lon: 18,
    officialDesignation: {
      'de-CH': {
        'f-': 'Bosnien und Herzegowina',
      },
      'en-US': {
        'f-': 'Bosnia and Herzegovina',
      },
      'fr-CH': {
        'f-': 'Bosnie-et-Herzégovine',
      },
    },
  },
  {
    _id: '#BB',
    continent: '#NA',
    designation: {
      'de-CH': {
        'f-': 'Barbados',
      },
      'en-US': {
        'f-': 'Barbados',
      },
      'fr-CH': {
        'f-': 'Barbade',
      },
    },
    iso3166: 'BB',
    lat: 13.16666666,
    lon: -59.53333333,
    officialDesignation: {
      'de-CH': {
        'f-': 'Barbados',
      },
      'en-US': {
        'f-': 'Barbados',
      },
      'fr-CH': {
        'f-': 'Barbade',
      },
    },
  },
  {
    _id: '#BD',
    continent: '#AS',
    designation: {
      'de-CH': {
        'f-': 'Bangladesch',
      },
      'en-US': {
        'f-': 'Bangladesh',
      },
      'fr-CH': {
        'f-': 'Bangladesh',
      },
    },
    iso3166: 'BD',
    lat: 24,
    lon: 90,
    officialDesignation: {
      'de-CH': {
        'f-': 'Volksrepublik Bangladesch',
      },
      'en-US': {
        'f-': "People's Republic of Bangladesh",
      },
      'fr-CH': {
        'f-': 'La République populaire du Bangladesh',
      },
    },
  },
  {
    _id: '#BE',
    continent: '#EU',
    designation: {
      'de-CH': {
        'f-': 'Belgien',
      },
      'en-US': {
        'f-': 'Belgium',
      },
      'fr-CH': {
        'f-': 'Belgique',
      },
    },
    ePoliceId: '2a88c3aa-e761-11e8-aea4-43375a4a09b8',
    iso3166: 'BE',
    lat: 50.83333333,
    lon: 4,
    officialDesignation: {
      'de-CH': {
        'f-': 'Königreich Belgien',
      },
      'en-US': {
        'f-': 'Kingdom of Belgium',
      },
      'fr-CH': {
        'f-': 'Royaume de Belgique',
      },
    },
  },
  {
    _id: '#BF',
    continent: '#AF',
    designation: {
      'de-CH': {
        'f-': 'Burkina Faso',
      },
      'en-US': {
        'f-': 'Burkina Faso',
      },
      'fr-CH': {
        'f-': 'Burkina Faso',
      },
    },
    iso3166: 'BF',
    lat: 13,
    lon: -2,
    officialDesignation: {
      'de-CH': {
        'f-': 'Burkina Faso',
      },
      'en-US': {
        'f-': 'Burkina Faso',
      },
      'fr-CH': {
        'f-': 'République du Burkina',
      },
    },
  },
  {
    _id: '#BG',
    continent: '#EU',
    designation: {
      'de-CH': {
        'f-': 'Bulgarien',
      },
      'en-US': {
        'f-': 'Bulgaria',
      },
      'fr-CH': {
        'f-': 'Bulgarie',
      },
    },
    ePoliceId: '2a88c3ab-e761-11e8-aea4-43375a4a09b8',
    iso3166: 'BG',
    lat: 43,
    lon: 25,
    officialDesignation: {
      'de-CH': {
        'f-': 'Republik Bulgarien',
      },
      'en-US': {
        'f-': 'Republic of Bulgaria',
      },
      'fr-CH': {
        'f-': 'République de Bulgarie',
      },
    },
  },
  {
    _id: '#BH',
    continent: '#AS',
    designation: {
      'de-CH': {
        'f-': 'Bahrain',
      },
      'en-US': {
        'f-': 'Bahrain',
      },
      'fr-CH': {
        'f-': 'Bahreïn',
      },
    },
    iso3166: 'BH',
    lat: 26,
    lon: 50.55,
    officialDesignation: {
      'de-CH': {
        'f-': 'Königreich Bahrain',
      },
      'en-US': {
        'f-': 'Kingdom of Bahrain',
      },
      'fr-CH': {
        'f-': 'Royaume de Bahreïn',
      },
    },
  },
  {
    _id: '#BI',
    continent: '#AF',
    designation: {
      'de-CH': {
        'f-': 'Burundi',
      },
      'en-US': {
        'f-': 'Burundi',
      },
      'fr-CH': {
        'f-': 'Burundi',
      },
    },
    ePoliceId: '2a88eaf3-e761-11e8-aea4-43375a4a09b8',
    iso3166: 'BI',
    lat: -3.5,
    lon: 30,
    officialDesignation: {
      'de-CH': {
        'f-': 'Republik Burundi',
      },
      'en-US': {
        'f-': 'Republic of Burundi',
      },
      'fr-CH': {
        'f-': 'République du Burundi',
      },
    },
  },
  {
    _id: '#BJ',
    continent: '#AF',
    designation: {
      'de-CH': {
        'f-': 'Benin',
      },
      'en-US': {
        'f-': 'Benin',
      },
      'fr-CH': {
        'f-': 'Bénin',
      },
    },
    ePoliceId: '2a88eaf4-e761-11e8-aea4-43375a4a09b8',
    iso3166: 'BJ',
    lat: 9.5,
    lon: 2.25,
    officialDesignation: {
      'de-CH': {
        'f-': 'Republik Benin',
      },
      'en-US': {
        'f-': 'Republic of Benin',
      },
      'fr-CH': {
        'f-': 'République du Bénin',
      },
    },
  },
  {
    _id: '#BL',
    continent: '#NA',
    designation: {
      'de-CH': {
        'f-': 'Saint-Barthélemy',
      },
      'en-US': {
        'f-': 'Saint Barthélemy',
      },
      'fr-CH': {
        'f-': 'Saint-Barthélemy',
      },
    },
    iso3166: 'BL',
    lat: 18.5,
    lon: -63.41666666,
    officialDesignation: {
      'de-CH': {
        'f-': 'Gebietskörperschaft Saint -Barthélemy',
      },
      'en-US': {
        'f-': 'Collectivity of Saint Barthélemy',
      },
      'fr-CH': {
        'f-': 'Collectivité de Saint-Barthélemy',
      },
    },
  },
  {
    _id: '#BM',
    continent: '#NA',
    designation: {
      'de-CH': {
        'f-': 'Bermuda',
      },
      'en-US': {
        'f-': 'Bermuda',
      },
      'fr-CH': {
        'f-': 'Bermudes',
      },
    },
    ePoliceId: '2a89394c-e761-11e8-aea4-43375a4a09b8',
    iso3166: 'BM',
    lat: 32.33333333,
    lon: -64.75,
    officialDesignation: {
      'de-CH': {
        'f-': 'Bermuda',
      },
      'en-US': {
        'f-': 'Bermuda',
      },
      'fr-CH': {
        'f-': 'Bermudes',
      },
    },
  },
  {
    _id: '#BN',
    continent: '#AS',
    designation: {
      'de-CH': {
        'f-': 'Brunei',
      },
      'en-US': {
        'f-': 'Brunei',
      },
      'fr-CH': {
        'f-': 'Brunei',
      },
    },
    ePoliceId: '2a8b3450-e761-11e8-aea4-43375a4a09b8',
    iso3166: 'BN',
    lat: 4.5,
    lon: 114.66666666,
    officialDesignation: {
      'de-CH': {
        'f-': 'Nation von Brunei, Wohnung des Friedens',
      },
      'en-US': {
        'f-': 'Nation of Brunei, Abode of Peace',
      },
      'fr-CH': {
        'f-': 'État de Brunei Darussalam',
      },
    },
  },
  {
    _id: '#BO',
    continent: '#SA',
    designation: {
      'de-CH': {
        'f-': 'Bolivien',
      },
      'en-US': {
        'f-': 'Bolivia',
      },
      'fr-CH': {
        'f-': 'Bolivie',
      },
    },
    ePoliceId: '2a89394d-e761-11e8-aea4-43375a4a09b8',
    iso3166: 'BO',
    lat: -17,
    lon: -65,
    officialDesignation: {
      'de-CH': {
        'f-': 'Multinationaler Staat von Bolivien',
      },
      'en-US': {
        'f-': 'Plurinational State of Bolivia',
      },
      'fr-CH': {
        'f-': 'État plurinational de Bolivie',
      },
    },
  },
  {
    _id: '#BR',
    continent: '#SA',
    designation: {
      'de-CH': {
        'f-': 'Brasilien',
      },
      'en-US': {
        'f-': 'Brazil',
      },
      'fr-CH': {
        'f-': 'Brésil',
      },
    },
    ePoliceId: '2a89394e-e761-11e8-aea4-43375a4a09b8',
    iso3166: 'BR',
    lat: -10,
    lon: -55,
    officialDesignation: {
      'de-CH': {
        'f-': 'Föderative Republik Brasilien',
      },
      'en-US': {
        'f-': 'Federative Republic of Brazil',
      },
      'fr-CH': {
        'f-': 'République fédérative du Brésil',
      },
    },
  },
  {
    _id: '#BS',
    continent: '#NA',
    designation: {
      'de-CH': {
        'f-': 'Bahamas',
      },
      'en-US': {
        'f-': 'Bahamas',
      },
      'fr-CH': {
        'f-': 'Bahamas',
      },
    },
    ePoliceId: '2a89394a-e761-11e8-aea4-43375a4a09b8',
    iso3166: 'BS',
    lat: 24.25,
    lon: -76,
    officialDesignation: {
      'de-CH': {
        'f-': 'Commonwealth der Bahamas',
      },
      'en-US': {
        'f-': 'Commonwealth of the Bahamas',
      },
      'fr-CH': {
        'f-': 'Commonwealth des Bahamas',
      },
    },
  },
  {
    _id: '#BT',
    continent: '#AS',
    designation: {
      'de-CH': {
        'f-': 'Bhutan',
      },
      'en-US': {
        'f-': 'Bhutan',
      },
      'fr-CH': {
        'f-': 'Bhoutan',
      },
    },
    ePoliceId: '2a8b0d3f-e761-11e8-aea4-43375a4a09b8',
    iso3166: 'BT',
    lat: 27.5,
    lon: 90.5,
    officialDesignation: {
      'de-CH': {
        'f-': 'Königreich Bhutan',
      },
      'en-US': {
        'f-': 'Kingdom of Bhutan',
      },
      'fr-CH': {
        'f-': 'Royaume du Bhoutan',
      },
    },
  },
  {
    _id: '#BV',
    continent: '#AN',
    designation: {
      'de-CH': {
        'f-': 'Bouvetinsel',
      },
      'en-US': {
        'f-': 'Bouvet Island',
      },
      'fr-CH': {
        'f-': 'Île Bouvet',
      },
    },
    ePoliceId: '2a91762c-e761-11e8-aea4-43375a4a09b8',
    iso3166: 'BV',
    lat: -54.43333333,
    lon: 3.4,
    officialDesignation: {
      'de-CH': {
        'f-': 'Bouvet-Insel',
      },
      'en-US': {
        'f-': 'Bouvet Island',
      },
      'fr-CH': {
        'f-': 'Île Bouvet',
      },
    },
  },
  {
    _id: '#BW',
    continent: '#AF',
    designation: {
      'de-CH': {
        'f-': 'Botswana',
      },
      'en-US': {
        'f-': 'Botswana',
      },
      'fr-CH': {
        'f-': 'Botswana',
      },
    },
    ePoliceId: '2a88eaf2-e761-11e8-aea4-43375a4a09b8',
    iso3166: 'BW',
    lat: -22,
    lon: 24,
    officialDesignation: {
      'de-CH': {
        'f-': 'Republik Botsuana',
      },
      'en-US': {
        'f-': 'Republic of Botswana',
      },
      'fr-CH': {
        'f-': 'République du Botswana',
      },
    },
  },
  {
    _id: '#BY',
    continent: '#EU',
    designation: {
      'de-CH': {
        'f-': 'Weißrussland',
      },
      'en-US': {
        'f-': 'Belarus',
      },
      'fr-CH': {
        'f-': 'Biélorussie',
      },
    },
    ePoliceId: '2a88eaea-e761-11e8-aea4-43375a4a09b8',
    iso3166: 'BY',
    lat: 53,
    lon: 28,
    officialDesignation: {
      'de-CH': {
        'f-': 'Republik Belarus',
      },
      'en-US': {
        'f-': 'Republic of Belarus',
      },
      'fr-CH': {
        'f-': 'République de Biélorussie',
      },
    },
  },
  {
    _id: '#BZ',
    continent: '#NA',
    designation: {
      'de-CH': {
        'f-': 'Belize',
      },
      'en-US': {
        'f-': 'Belize',
      },
      'fr-CH': {
        'f-': 'Belize',
      },
    },
    ePoliceId: '2a89395b-e761-11e8-aea4-43375a4a09b8',
    iso3166: 'BZ',
    lat: 17.25,
    lon: -88.75,
    officialDesignation: {
      'de-CH': {
        'f-': 'Belize',
      },
      'en-US': {
        'f-': 'Belize',
      },
      'fr-CH': {
        'f-': 'Belize',
      },
    },
  },
  {
    _id: '#CA',
    continent: '#NA',
    designation: {
      'de-CH': {
        'f-': 'Kanada',
      },
      'en-US': {
        'f-': 'Canada',
      },
      'fr-CH': {
        'f-': 'Canada',
      },
    },
    ePoliceId: '2a895f6e-e761-11e8-aea4-43375a4a09b8',
    iso3166: 'CA',
    lat: 60,
    lon: -95,
    officialDesignation: {
      'de-CH': {
        'f-': 'Kanada',
      },
      'en-US': {
        'f-': 'Canada',
      },
      'fr-CH': {
        'f-': 'Canada',
      },
    },
  },
  {
    _id: '#CC',
    continent: '#AS',
    designation: {
      'de-CH': {
        'f-': 'Kokosinseln',
      },
      'en-US': {
        'f-': 'Cocos (Keeling) Islands',
      },
      'fr-CH': {
        'f-': 'Îles Cocos',
      },
    },
    ePoliceId: '2a914f12-e761-11e8-aea4-43375a4a09b8',
    iso3166: 'CC',
    lat: -12.5,
    lon: 96.83333333,
    officialDesignation: {
      'de-CH': {
        'f-': 'Gebiet der Cocos (Keeling) Islands',
      },
      'en-US': {
        'f-': 'Territory of the Cocos (Keeling) Islands',
      },
      'fr-CH': {
        'f-': 'Territoire des îles Cocos (Keeling)',
      },
    },
  },
  {
    _id: '#CD',
    continent: '#AF',
    designation: {
      'de-CH': {
        'f-': 'Kongo (Dem. Rep.)',
      },
      'en-US': {
        'f-': 'DR Congo',
      },
      'fr-CH': {
        'f-': 'Congo (Rép. dém.)',
      },
    },
    ePoliceId: '2a891211-e761-11e8-aea4-43375a4a09b8',
    iso3166: 'CD',
    lat: 0,
    lon: 25,
    officialDesignation: {
      'de-CH': {
        'f-': 'Demokratische Republik Kongo',
      },
      'en-US': {
        'f-': 'Democratic Republic of the Congo',
      },
      'fr-CH': {
        'f-': 'République démocratique du Congo',
      },
    },
  },
  {
    _id: '#CF',
    continent: '#AF',
    designation: {
      'de-CH': {
        'f-': 'Zentralafrikanische Republik',
      },
      'en-US': {
        'f-': 'Central African Republic',
      },
      'fr-CH': {
        'f-': 'République centrafricaine',
      },
    },
    ePoliceId: '2a893942-e761-11e8-aea4-43375a4a09b8',
    iso3166: 'CF',
    lat: 7,
    lon: 21,
    officialDesignation: {
      'de-CH': {
        'f-': 'Zentralafrikanische Republik',
      },
      'en-US': {
        'f-': 'Central African Republic',
      },
      'fr-CH': {
        'f-': 'République centrafricaine',
      },
    },
  },
  {
    _id: '#CG',
    continent: '#AF',
    designation: {
      'de-CH': {
        'f-': 'Kongo',
      },
      'en-US': {
        'f-': 'Republic of the Congo',
      },
      'fr-CH': {
        'f-': 'Congo',
      },
    },
    ePoliceId: '2a891210-e761-11e8-aea4-43375a4a09b8',
    iso3166: 'CG',
    lat: -1,
    lon: 15,
    officialDesignation: {
      'de-CH': {
        'f-': 'Republik Kongo',
      },
      'en-US': {
        'f-': 'Republic of the Congo',
      },
      'fr-CH': {
        'f-': 'République du Congo',
      },
    },
  },
  {
    _id: '#CH',
    continent: '#EU',
    designation: {
      'de-CH': {
        'f-': 'Schweiz',
      },
      'en-US': {
        'f-': 'Switzerland',
      },
      'fr-CH': {
        'f-': 'Suisse',
      },
    },
    ePoliceId: '2a88c3a7-e761-11e8-aea4-43375a4a09b8',
    iso3166: 'CH',
    lat: 47,
    lon: 8,
    officialDesignation: {
      'de-CH': {
        'f-': 'Schweizerische Eidgenossenschaft',
      },
      'en-US': {
        'f-': 'Swiss Confederation',
      },
      'fr-CH': {
        'f-': 'Confédération suisse',
      },
    },
  },
  {
    _id: '#CI',
    continent: '#AF',
    designation: {
      'de-CH': {
        'f-': 'Elfenbeinküste',
      },
      'en-US': {
        'f-': 'Ivory Coast',
      },
      'fr-CH': {
        'f-': "Côte d'Ivoire",
      },
    },
    ePoliceId: '2a88eaf5-e761-11e8-aea4-43375a4a09b8',
    iso3166: 'CI',
    lat: 8,
    lon: -5,
    officialDesignation: {
      'de-CH': {
        'f-': "Republik Côte d'Ivoire",
      },
      'en-US': {
        'f-': "Republic of Côte d'Ivoire",
      },
      'fr-CH': {
        'f-': "République de Côte d' Ivoire",
      },
    },
  },
  {
    _id: '#CK',
    continent: '#OC',
    designation: {
      'de-CH': {
        'f-': 'Cookinseln',
      },
      'en-US': {
        'f-': 'Cook Islands',
      },
      'fr-CH': {
        'f-': 'Îles Cook',
      },
    },
    ePoliceId: '2a914f17-e761-11e8-aea4-43375a4a09b8',
    iso3166: 'CK',
    lat: -21.23333333,
    lon: -159.76666666,
    officialDesignation: {
      'de-CH': {
        'f-': 'Cook-Inseln',
      },
      'en-US': {
        'f-': 'Cook Islands',
      },
      'fr-CH': {
        'f-': 'Îles Cook',
      },
    },
  },
  {
    _id: '#CL',
    continent: '#SA',
    designation: {
      'de-CH': {
        'f-': 'Chile',
      },
      'en-US': {
        'f-': 'Chile',
      },
      'fr-CH': {
        'f-': 'Chili',
      },
    },
    ePoliceId: '2a89394f-e761-11e8-aea4-43375a4a09b8',
    iso3166: 'CL',
    lat: -30,
    lon: -71,
    officialDesignation: {
      'de-CH': {
        'f-': 'Republik Chile',
      },
      'en-US': {
        'f-': 'Republic of Chile',
      },
      'fr-CH': {
        'f-': 'République du Chili',
      },
    },
  },
  {
    _id: '#CM',
    continent: '#AF',
    designation: {
      'de-CH': {
        'f-': 'Kamerun',
      },
      'en-US': {
        'f-': 'Cameroon',
      },
      'fr-CH': {
        'f-': 'Cameroun',
      },
    },
    ePoliceId: '2a89120b-e761-11e8-aea4-43375a4a09b8',
    iso3166: 'CM',
    lat: 6,
    lon: 12,
    officialDesignation: {
      'de-CH': {
        'f-': 'Republik Kamerun',
      },
      'en-US': {
        'f-': 'Republic of Cameroon',
      },
      'fr-CH': {
        'f-': 'République du Cameroun',
      },
    },
  },
  {
    _id: '#CN',
    continent: '#AS',
    designation: {
      'de-CH': {
        'f-': 'China',
      },
      'en-US': {
        'f-': 'China',
      },
      'fr-CH': {
        'f-': 'Chine',
      },
    },
    ePoliceId: '2a8bd094-e761-11e8-aea4-43375a4a09b8',
    iso3166: 'CN',
    lat: 35,
    lon: 105,
    officialDesignation: {
      'de-CH': {
        'f-': 'Volksrepublik China',
      },
      'en-US': {
        'f-': "People's Republic of China",
      },
      'fr-CH': {
        'f-': 'République populaire de Chine',
      },
    },
  },
  {
    _id: '#CO',
    continent: '#SA',
    designation: {
      'de-CH': {
        'f-': 'Kolumbien',
      },
      'en-US': {
        'f-': 'Colombia',
      },
      'fr-CH': {
        'f-': 'Colombie',
      },
    },
    ePoliceId: '2a895f6f-e761-11e8-aea4-43375a4a09b8',
    iso3166: 'CO',
    lat: 4,
    lon: -72,
    officialDesignation: {
      'de-CH': {
        'f-': 'Republik Kolumbien',
      },
      'en-US': {
        'f-': 'Republic of Colombia',
      },
      'fr-CH': {
        'f-': 'République de Colombie',
      },
    },
  },
  {
    _id: '#CR',
    continent: '#NA',
    designation: {
      'de-CH': {
        'f-': 'Costa Rica',
      },
      'en-US': {
        'f-': 'Costa Rica',
      },
      'fr-CH': {
        'f-': 'Costa Rica',
      },
    },
    ePoliceId: '2a893950-e761-11e8-aea4-43375a4a09b8',
    iso3166: 'CR',
    lat: 10,
    lon: -84,
    officialDesignation: {
      'de-CH': {
        'f-': 'Republik Costa Rica',
      },
      'en-US': {
        'f-': 'Republic of Costa Rica',
      },
      'fr-CH': {
        'f-': 'République du Costa Rica',
      },
    },
  },
  {
    _id: '#CU',
    continent: '#NA',
    designation: {
      'de-CH': {
        'f-': 'Kuba',
      },
      'en-US': {
        'f-': 'Cuba',
      },
      'fr-CH': {
        'f-': 'Cuba',
      },
    },
    ePoliceId: '2a895f70-e761-11e8-aea4-43375a4a09b8',
    iso3166: 'CU',
    lat: 21.5,
    lon: -80,
    officialDesignation: {
      'de-CH': {
        'f-': 'Republik Kuba',
      },
      'en-US': {
        'f-': 'Republic of Cuba',
      },
      'fr-CH': {
        'f-': 'République de Cuba',
      },
    },
  },
  {
    _id: '#CV',
    continent: '#AF',
    designation: {
      'de-CH': {
        'f-': 'Kap Verde',
      },
      'en-US': {
        'f-': 'Cape Verde',
      },
      'fr-CH': {
        'f-': 'Îles du Cap-Vert',
      },
    },
    ePoliceId: '2a89120d-e761-11e8-aea4-43375a4a09b8',
    iso3166: 'CV',
    lat: 16,
    lon: -24,
    officialDesignation: {
      'de-CH': {
        'f-': 'Republik Cabo Verde',
      },
      'en-US': {
        'f-': 'Republic of Cabo Verde',
      },
      'fr-CH': {
        'f-': 'République du Cap-Vert',
      },
    },
  },
  {
    _id: '#CW',
    continent: '#NA',
    designation: {
      'de-CH': {
        'f-': 'Curaçao',
      },
      'en-US': {
        'f-': 'Curaçao',
      },
      'fr-CH': {
        'f-': 'Curaçao',
      },
    },
    ePoliceId: '2a8a980c-e761-11e8-aea4-43375a4a09b8',
    iso3166: 'CW',
    lat: 12.116667,
    lon: -68.933333,
    officialDesignation: {
      'de-CH': {
        'f-': 'Land Curaçao',
      },
      'en-US': {
        'f-': 'Country of Curaçao',
      },
      'fr-CH': {
        'f-': 'Curaçao',
      },
    },
  },
  {
    _id: '#CX',
    continent: '#AS',
    designation: {
      'de-CH': {
        'f-': 'Weihnachtsinsel',
      },
      'en-US': {
        'f-': 'Christmas Island',
      },
      'fr-CH': {
        'f-': 'Île Christmas',
      },
    },
    ePoliceId: '2a914f15-e761-11e8-aea4-43375a4a09b8',
    iso3166: 'CX',
    lat: -10.5,
    lon: 105.66666666,
    officialDesignation: {
      'de-CH': {
        'f-': 'Gebiet der Weihnachtsinsel',
      },
      'en-US': {
        'f-': 'Territory of Christmas Island',
      },
      'fr-CH': {
        'f-': "Territoire de l'île Christmas",
      },
    },
  },
  {
    _id: '#CY',
    continent: '#AS',
    designation: {
      'de-CH': {
        'f-': 'Zypern',
      },
      'en-US': {
        'f-': 'Cyprus',
      },
      'fr-CH': {
        'f-': 'Chypre',
      },
    },
    ePoliceId: '2a88c3c8-e761-11e8-aea4-43375a4a09b8',
    iso3166: 'CY',
    lat: 35,
    lon: 33,
    officialDesignation: {
      'de-CH': {
        'f-': 'Republik Zypern',
      },
      'en-US': {
        'f-': 'Republic of Cyprus',
      },
      'fr-CH': {
        'f-': 'République de Chypre',
      },
    },
  },
  {
    _id: '#CZ',
    continent: '#EU',
    designation: {
      'de-CH': {
        'f-': 'Tschechische Republik',
      },
      'en-US': {
        'f-': 'Czech Republic',
      },
      'fr-CH': {
        'f-': 'République tchèque',
      },
    },
    ePoliceId: '2a88eada-e761-11e8-aea4-43375a4a09b8',
    iso3166: 'CZ',
    lat: 49.75,
    lon: 15.5,
    officialDesignation: {
      'de-CH': {
        'f-': 'Tschechische Republik',
      },
      'en-US': {
        'f-': 'Czech Republic',
      },
      'fr-CH': {
        'f-': 'République tchèque',
      },
    },
  },
  {
    _id: '#DE',
    continent: '#EU',
    designation: {
      'de-CH': {
        'f-': 'Deutschland',
      },
      'en-US': {
        'f-': 'Germany',
      },
      'fr-CH': {
        'f-': 'Allemagne',
      },
    },
    ePoliceId: '2a88c3ad-e761-11e8-aea4-43375a4a09b8',
    iso3166: 'DE',
    lat: 51,
    lon: 9,
    officialDesignation: {
      'de-CH': {
        'f-': 'Bundesrepublik Deutschland',
      },
      'en-US': {
        'f-': 'Federal Republic of Germany',
      },
      'fr-CH': {
        'f-': "République fédérale d'Allemagne",
      },
    },
  },
  {
    _id: '#DJ',
    continent: '#AF',
    designation: {
      'de-CH': {
        'f-': 'Dschibuti',
      },
      'en-US': {
        'f-': 'Djibouti',
      },
      'fr-CH': {
        'f-': 'Djibouti',
      },
    },
    ePoliceId: '2a88eaef-e761-11e8-aea4-43375a4a09b8',
    iso3166: 'DJ',
    lat: 11.5,
    lon: 43,
    officialDesignation: {
      'de-CH': {
        'f-': 'Republik Dschibuti',
      },
      'en-US': {
        'f-': 'Republic of Djibouti',
      },
      'fr-CH': {
        'f-': 'République de Djibouti',
      },
    },
  },
  {
    _id: '#DK',
    continent: '#EU',
    designation: {
      'de-CH': {
        'f-': 'Dänemark',
      },
      'en-US': {
        'f-': 'Denmark',
      },
      'fr-CH': {
        'f-': 'Danemark',
      },
    },
    ePoliceId: '2a88c3ac-e761-11e8-aea4-43375a4a09b8',
    iso3166: 'DK',
    lat: 56,
    lon: 10,
    officialDesignation: {
      'de-CH': {
        'f-': 'Königreich Dänemark',
      },
      'en-US': {
        'f-': 'Kingdom of Denmark',
      },
      'fr-CH': {
        'f-': 'Royaume du Danemark',
      },
    },
  },
  {
    _id: '#DM',
    continent: '#NA',
    designation: {
      'de-CH': {
        'f-': 'Dominica',
      },
      'en-US': {
        'f-': 'Dominica',
      },
      'fr-CH': {
        'f-': 'Dominique',
      },
    },
    ePoliceId: '2a895f7e-e761-11e8-aea4-43375a4a09b8',
    iso3166: 'DM',
    lat: 15.41666666,
    lon: -61.33333333,
    officialDesignation: {
      'de-CH': {
        'f-': 'Commonwealth von Dominica',
      },
      'en-US': {
        'f-': 'Commonwealth of Dominica',
      },
      'fr-CH': {
        'f-': 'Commonwealth de la Dominique',
      },
    },
  },
  {
    _id: '#DO',
    continent: '#NA',
    designation: {
      'de-CH': {
        'f-': 'Dominikanische Republik',
      },
      'en-US': {
        'f-': 'Dominican Republic',
      },
      'fr-CH': {
        'f-': 'République dominicaine',
      },
    },
    ePoliceId: '2a893951-e761-11e8-aea4-43375a4a09b8',
    iso3166: 'DO',
    lat: 19,
    lon: -70.66666666,
    officialDesignation: {
      'de-CH': {
        'f-': 'Dominikanische Republik',
      },
      'en-US': {
        'f-': 'Dominican Republic',
      },
      'fr-CH': {
        'f-': 'République Dominicaine',
      },
    },
  },
  {
    _id: '#DZ',
    continent: '#AF',
    designation: {
      'de-CH': {
        'f-': 'Algerien',
      },
      'en-US': {
        'f-': 'Algeria',
      },
      'fr-CH': {
        'f-': 'Algérie',
      },
    },
    ePoliceId: '2a88eaf0-e761-11e8-aea4-43375a4a09b8',
    iso3166: 'DZ',
    lat: 28,
    lon: 3,
    officialDesignation: {
      'de-CH': {
        'f-': 'Demokratische Volksrepublik Algerien',
      },
      'en-US': {
        'f-': "People's Democratic Republic of Algeria",
      },
      'fr-CH': {
        'f-': "République démocratique et populaire d'Algérie",
      },
    },
  },
  {
    _id: '#EC',
    continent: '#SA',
    designation: {
      'de-CH': {
        'f-': 'Ecuador',
      },
      'en-US': {
        'f-': 'Ecuador',
      },
      'fr-CH': {
        'f-': 'Équateur',
      },
    },
    ePoliceId: '2a893952-e761-11e8-aea4-43375a4a09b8',
    iso3166: 'EC',
    lat: -2,
    lon: -77.5,
    officialDesignation: {
      'de-CH': {
        'f-': 'Republik Ecuador',
      },
      'en-US': {
        'f-': 'Republic of Ecuador',
      },
      'fr-CH': {
        'f-': "République de l'Équateur",
      },
    },
  },
  {
    _id: '#EE',
    continent: '#EU',
    designation: {
      'de-CH': {
        'f-': 'Estland',
      },
      'en-US': {
        'f-': 'Estonia',
      },
      'fr-CH': {
        'f-': 'Estonie',
      },
    },
    ePoliceId: '2a88eae4-e761-11e8-aea4-43375a4a09b8',
    iso3166: 'EE',
    lat: 59,
    lon: 26,
    officialDesignation: {
      'de-CH': {
        'f-': 'Republik Estland',
      },
      'en-US': {
        'f-': 'Republic of Estonia',
      },
      'fr-CH': {
        'f-': "République d'Estonie",
      },
    },
  },
  {
    _id: '#EG',
    continent: '#AF',
    designation: {
      'de-CH': {
        'f-': 'Ägypten',
      },
      'en-US': {
        'f-': 'Egypt',
      },
      'fr-CH': {
        'f-': 'Égypte',
      },
    },
    ePoliceId: '2a893941-e761-11e8-aea4-43375a4a09b8',
    iso3166: 'EG',
    lat: 27,
    lon: 30,
    officialDesignation: {
      'de-CH': {
        'f-': 'Arabische Republik Ägypten',
      },
      'en-US': {
        'f-': 'Arab Republic of Egypt',
      },
      'fr-CH': {
        'f-': "République arabe d'Égypte",
      },
    },
  },
  {
    _id: '#EH',
    continent: '#AF',
    designation: {
      'de-CH': {
        'f-': 'Westsahara',
      },
      'en-US': {
        'f-': 'Western Sahara',
      },
      'fr-CH': {
        'f-': 'Sahara Occidental',
      },
    },
    ePoliceId: '2a893947-e761-11e8-aea4-43375a4a09b8',
    iso3166: 'EH',
    lat: 24.5,
    lon: -13,
    officialDesignation: {
      'de-CH': {
        'f-': 'Demokratische Arabische Republik Sahara',
      },
      'en-US': {
        'f-': 'Sahrawi Arab Democratic Republic',
      },
      'fr-CH': {
        'f-': 'République arabe sahraouie démocratique',
      },
    },
  },
  {
    _id: '#ER',
    continent: '#AF',
    designation: {
      'de-CH': {
        'f-': 'Eritrea',
      },
      'en-US': {
        'f-': 'Eritrea',
      },
      'fr-CH': {
        'f-': 'Érythrée',
      },
    },
    ePoliceId: '2a893944-e761-11e8-aea4-43375a4a09b8',
    iso3166: 'ER',
    lat: 15,
    lon: 39,
    officialDesignation: {
      'de-CH': {
        'f-': 'Staat Eritrea',
      },
      'en-US': {
        'f-': 'State of Eritrea',
      },
      'fr-CH': {
        'f-': "État d'Érythrée",
      },
    },
  },
  {
    _id: '#ES',
    continent: '#EU',
    designation: {
      'de-CH': {
        'f-': 'Spanien',
      },
      'en-US': {
        'f-': 'Spain',
      },
      'fr-CH': {
        'f-': 'Espagne',
      },
    },
    ePoliceId: '2a88c3c4-e761-11e8-aea4-43375a4a09b8',
    iso3166: 'ES',
    lat: 40,
    lon: -4,
    officialDesignation: {
      'de-CH': {
        'f-': 'Königreich Spanien',
      },
      'en-US': {
        'f-': 'Kingdom of Spain',
      },
      'fr-CH': {
        'f-': "Royaume d'Espagne",
      },
    },
  },
  {
    _id: '#ET',
    continent: '#AF',
    designation: {
      'de-CH': {
        'f-': 'Äthiopien',
      },
      'en-US': {
        'f-': 'Ethiopia',
      },
      'fr-CH': {
        'f-': 'Éthiopie',
      },
    },
    ePoliceId: '2a88eaee-e761-11e8-aea4-43375a4a09b8',
    iso3166: 'ET',
    lat: 8,
    lon: 38,
    officialDesignation: {
      'de-CH': {
        'f-': 'Demokratische Bundesrepublik Äthiopien',
      },
      'en-US': {
        'f-': 'Federal Democratic Republic of Ethiopia',
      },
      'fr-CH': {
        'f-': "République fédérale démocratique d'Éthiopie",
      },
    },
  },
  {
    _id: '#FI',
    continent: '#EU',
    designation: {
      'de-CH': {
        'f-': 'Finnland',
      },
      'en-US': {
        'f-': 'Finland',
      },
      'fr-CH': {
        'f-': 'Finlande',
      },
    },
    ePoliceId: '2a88c3af-e761-11e8-aea4-43375a4a09b8',
    iso3166: 'FI',
    lat: 64,
    lon: 26,
    officialDesignation: {
      'de-CH': {
        'f-': 'Republik Finnland',
      },
      'en-US': {
        'f-': 'Republic of Finland',
      },
      'fr-CH': {
        'f-': 'République de Finlande',
      },
    },
  },
  {
    _id: '#FJ',
    continent: '#OC',
    designation: {
      'de-CH': {
        'f-': 'Fidschi',
      },
      'en-US': {
        'f-': 'Fiji',
      },
      'fr-CH': {
        'f-': 'Fidji',
      },
    },
    ePoliceId: '2a914f00-e761-11e8-aea4-43375a4a09b8',
    iso3166: 'FJ',
    lat: -18,
    lon: 175,
    officialDesignation: {
      'de-CH': {
        'f-': 'Republik Fidschi',
      },
      'en-US': {
        'f-': 'Republic of Fiji',
      },
      'fr-CH': {
        'f-': 'République des Fidji',
      },
    },
  },
  {
    _id: '#FK',
    continent: '#SA',
    designation: {
      'de-CH': {
        'f-': 'Falklandinseln',
      },
      'en-US': {
        'f-': 'Falkland Islands',
      },
      'fr-CH': {
        'f-': 'Îles Malouines',
      },
    },
    ePoliceId: '2a893954-e761-11e8-aea4-43375a4a09b8',
    iso3166: 'FK',
    lat: -51.75,
    lon: -59,
    officialDesignation: {
      'de-CH': {
        'f-': 'Falkland-Inseln',
      },
      'en-US': {
        'f-': 'Falkland Islands',
      },
      'fr-CH': {
        'f-': 'Îles Malouines',
      },
    },
  },
  {
    _id: '#FM',
    continent: '#OC',
    designation: {
      'de-CH': {
        'f-': 'Mikronesien',
      },
      'en-US': {
        'f-': 'Micronesia',
      },
      'fr-CH': {
        'f-': 'Micronésie',
      },
    },
    ePoliceId: '2a914f0d-e761-11e8-aea4-43375a4a09b8',
    iso3166: 'FM',
    lat: 6.91666666,
    lon: 158.25,
    officialDesignation: {
      'de-CH': {
        'f-': 'Föderierte Staaten von Mikronesien',
      },
      'en-US': {
        'f-': 'Federated States of Micronesia',
      },
      'fr-CH': {
        'f-': 'États fédérés de Micronésie',
      },
    },
  },
  {
    _id: '#FO',
    continent: '#EU',
    designation: {
      'de-CH': {
        'f-': 'Färöer-Inseln',
      },
      'en-US': {
        'f-': 'Faroe Islands',
      },
      'fr-CH': {
        'f-': 'Îles Féroé',
      },
    },
    ePoliceId: '2a88c3ae-e761-11e8-aea4-43375a4a09b8',
    iso3166: 'FO',
    lat: 62,
    lon: -7,
    officialDesignation: {
      'de-CH': {
        'f-': 'Färöer',
      },
      'en-US': {
        'f-': 'Faroe Islands',
      },
      'fr-CH': {
        'f-': 'Îles Féroé',
      },
    },
  },
  {
    _id: '#FR',
    continent: '#EU',
    designation: {
      'de-CH': {
        'f-': 'Frankreich',
      },
      'en-US': {
        'f-': 'France',
      },
      'fr-CH': {
        'f-': 'France',
      },
    },
    ePoliceId: '2a88c3b0-e761-11e8-aea4-43375a4a09b8',
    iso3166: 'FR',
    lat: 46,
    lon: 2,
    officialDesignation: {
      'de-CH': {
        'f-': 'Französische Republik',
      },
      'en-US': {
        'f-': 'French Republic',
      },
      'fr-CH': {
        'f-': 'République française',
      },
    },
  },
  {
    _id: '#GA',
    continent: '#AF',
    designation: {
      'de-CH': {
        'f-': 'Gabun',
      },
      'en-US': {
        'f-': 'Gabon',
      },
      'fr-CH': {
        'f-': 'Gabon',
      },
    },
    ePoliceId: '2a88eaf6-e761-11e8-aea4-43375a4a09b8',
    iso3166: 'GA',
    lat: -1,
    lon: 11.75,
    officialDesignation: {
      'de-CH': {
        'f-': 'Gabunische Republik',
      },
      'en-US': {
        'f-': 'Gabonese Republic',
      },
      'fr-CH': {
        'f-': 'République gabonaise',
      },
    },
  },
  {
    _id: '#GB',
    continent: '#EU',
    designation: {
      'de-CH': {
        'f-': 'Grossbritannien',
      },
      'en-US': {
        'f-': 'United Kingdom',
      },
      'fr-CH': {
        'f-': 'Royaume-Uni',
      },
    },
    ePoliceId: '2a88c3b3-e761-11e8-aea4-43375a4a09b8',
    iso3166: 'GB',
    lat: 54,
    lon: -2,
    officialDesignation: {
      'de-CH': {
        'f-': 'Vereinigtes Königreich Grossbritannien und Nordirland',
      },
      'en-US': {
        'f-': 'United Kingdom of Great Britain and Northern Ireland',
      },
      'fr-CH': {
        'f-': "Royaume-Uni de Grande-Bretagne et d'Irlande du Nord",
      },
    },
  },
  {
    _id: '#GD',
    continent: '#NA',
    designation: {
      'de-CH': {
        'f-': 'Grenada',
      },
      'en-US': {
        'f-': 'Grenada',
      },
      'fr-CH': {
        'f-': 'Grenade',
      },
    },
    ePoliceId: '2a895f7f-e761-11e8-aea4-43375a4a09b8',
    iso3166: 'GD',
    lat: 12.11666666,
    lon: -61.66666666,
    officialDesignation: {
      'de-CH': {
        'f-': 'Grenada',
      },
      'en-US': {
        'f-': 'Grenada',
      },
      'fr-CH': {
        'f-': 'Grenade',
      },
    },
  },
  {
    _id: '#GE',
    continent: '#AS',
    designation: {
      'de-CH': {
        'f-': 'Georgien',
      },
      'en-US': {
        'f-': 'Georgia',
      },
      'fr-CH': {
        'f-': 'Géorgie',
      },
    },
    ePoliceId: '2a9127e9-e761-11e8-aea4-43375a4a09b8',
    iso3166: 'GE',
    lat: 42,
    lon: 43.5,
    officialDesignation: {
      'de-CH': {
        'f-': 'Georgia',
      },
      'en-US': {
        'f-': 'Georgia',
      },
      'fr-CH': {
        'f-': 'République de Géorgie',
      },
    },
  },
  {
    _id: '#GF',
    continent: '#SA',
    designation: {
      'de-CH': {
        'f-': 'Französisch Guyana',
      },
      'en-US': {
        'f-': 'French Guiana',
      },
      'fr-CH': {
        'f-': 'Guyane',
      },
    },
    ePoliceId: '2a893958-e761-11e8-aea4-43375a4a09b8',
    iso3166: 'GF',
    lat: 4,
    lon: -53,
    officialDesignation: {
      'de-CH': {
        'f-': 'Guayana',
      },
      'en-US': {
        'f-': 'Guiana',
      },
      'fr-CH': {
        'f-': 'Guyane',
      },
    },
  },
  {
    _id: '#GG',
    continent: '#EU',
    designation: {
      'de-CH': {
        'f-': 'Guernsey',
      },
      'en-US': {
        'f-': 'Guernsey',
      },
      'fr-CH': {
        'f-': 'Guernesey',
      },
    },
    ePoliceId: '2a88eaeb-e761-11e8-aea4-43375a4a09b8',
    iso3166: 'GG',
    lat: 49.46666666,
    lon: -2.58333333,
    officialDesignation: {
      'de-CH': {
        'f-': 'Guernsey',
      },
      'en-US': {
        'f-': 'Bailiwick of Guernsey',
      },
      'fr-CH': {
        'f-': 'Bailliage de Guernesey',
      },
    },
  },
  {
    _id: '#GH',
    continent: '#AF',
    designation: {
      'de-CH': {
        'f-': 'Ghana',
      },
      'en-US': {
        'f-': 'Ghana',
      },
      'fr-CH': {
        'f-': 'Ghana',
      },
    },
    ePoliceId: '2a88eaf8-e761-11e8-aea4-43375a4a09b8',
    iso3166: 'GH',
    lat: 8,
    lon: -2,
    officialDesignation: {
      'de-CH': {
        'f-': 'Republik Ghana',
      },
      'en-US': {
        'f-': 'Republic of Ghana',
      },
      'fr-CH': {
        'f-': 'République du Ghana',
      },
    },
  },
  {
    _id: '#GI',
    continent: '#EU',
    designation: {
      'de-CH': {
        'f-': 'Gibraltar',
      },
      'en-US': {
        'f-': 'Gibraltar',
      },
      'fr-CH': {
        'f-': 'Gibraltar',
      },
    },
    ePoliceId: '2a88c3b1-e761-11e8-aea4-43375a4a09b8',
    iso3166: 'GI',
    lat: 36.13333333,
    lon: -5.35,
    officialDesignation: {
      'de-CH': {
        'f-': 'Gibraltar',
      },
      'en-US': {
        'f-': 'Gibraltar',
      },
      'fr-CH': {
        'f-': 'Gibraltar',
      },
    },
  },
  {
    _id: '#GL',
    continent: '#NA',
    designation: {
      'de-CH': {
        'f-': 'Grönland',
      },
      'en-US': {
        'f-': 'Greenland',
      },
      'fr-CH': {
        'f-': 'Groenland',
      },
    },
    ePoliceId: '2a893955-e761-11e8-aea4-43375a4a09b8',
    iso3166: 'GL',
    lat: 72,
    lon: -40,
    officialDesignation: {
      'de-CH': {
        'f-': 'Grönland',
      },
      'en-US': {
        'f-': 'Greenland',
      },
      'fr-CH': {
        'f-': 'Groenland',
      },
    },
  },
  {
    _id: '#GM',
    continent: '#AF',
    designation: {
      'de-CH': {
        'f-': 'Gambia',
      },
      'en-US': {
        'f-': 'Gambia',
      },
      'fr-CH': {
        'f-': 'Gambie',
      },
    },
    ePoliceId: '2a88eaf7-e761-11e8-aea4-43375a4a09b8',
    iso3166: 'GM',
    lat: 13.46666666,
    lon: -16.56666666,
    officialDesignation: {
      'de-CH': {
        'f-': 'Republik Gambia',
      },
      'en-US': {
        'f-': 'Republic of the Gambia',
      },
      'fr-CH': {
        'f-': 'République de Gambie',
      },
    },
  },
  {
    _id: '#GN',
    continent: '#AF',
    designation: {
      'de-CH': {
        'f-': 'Guinea',
      },
      'en-US': {
        'f-': 'Guinea',
      },
      'fr-CH': {
        'f-': 'Guinée',
      },
    },
    ePoliceId: '2a88eafa-e761-11e8-aea4-43375a4a09b8',
    iso3166: 'GN',
    lat: 11,
    lon: -10,
    officialDesignation: {
      'de-CH': {
        'f-': 'Republik Guinea',
      },
      'en-US': {
        'f-': 'Republic of Guinea',
      },
      'fr-CH': {
        'f-': 'République de Guinée',
      },
    },
  },
  {
    _id: '#GP',
    continent: '#NA',
    designation: {
      'de-CH': {
        'f-': 'Guadeloupe',
      },
      'en-US': {
        'f-': 'Guadeloupe',
      },
      'fr-CH': {
        'f-': 'Guadeloupe',
      },
    },
    ePoliceId: '2a893956-e761-11e8-aea4-43375a4a09b8',
    iso3166: 'GP',
    lat: 16.25,
    lon: -61.583333,
    officialDesignation: {
      'de-CH': {
        'f-': 'Guadeloupe',
      },
      'en-US': {
        'f-': 'Guadeloupe',
      },
      'fr-CH': {
        'f-': 'Guadeloupe',
      },
    },
  },
  {
    _id: '#GQ',
    continent: '#AF',
    designation: {
      'de-CH': {
        'f-': 'Äquatorialguinea',
      },
      'en-US': {
        'f-': 'Equatorial Guinea',
      },
      'fr-CH': {
        'f-': 'Guinée équatoriale',
      },
    },
    ePoliceId: '2a88eaed-e761-11e8-aea4-43375a4a09b8',
    iso3166: 'GQ',
    lat: 2,
    lon: 10,
    officialDesignation: {
      'de-CH': {
        'f-': 'Republik Äquatorialguinea',
      },
      'en-US': {
        'f-': 'Republic of Equatorial Guinea',
      },
      'fr-CH': {
        'f-': 'République de Guinée équatoriale',
      },
    },
  },
  {
    _id: '#GR',
    continent: '#EU',
    designation: {
      'de-CH': {
        'f-': 'Griechenland',
      },
      'en-US': {
        'f-': 'Greece',
      },
      'fr-CH': {
        'f-': 'Grèce',
      },
    },
    ePoliceId: '2a88c3b2-e761-11e8-aea4-43375a4a09b8',
    iso3166: 'GR',
    lat: 39,
    lon: 22,
    officialDesignation: {
      'de-CH': {
        'f-': 'Hellenische Republik',
      },
      'en-US': {
        'f-': 'Hellenic Republic',
      },
      'fr-CH': {
        'f-': 'République hellénique',
      },
    },
  },
  {
    _id: '#GS',
    continent: '#AN',
    designation: {
      'de-CH': {
        'f-': 'Südgeorgien und die Südlichen Sandwichinseln',
      },
      'en-US': {
        'f-': 'South Georgia',
      },
      'fr-CH': {
        'f-': 'Géorgie du Sud-et-les Îles Sandwich du Sud',
      },
    },
    iso3166: 'GS',
    lat: -54.5,
    lon: -37,
    officialDesignation: {
      'de-CH': {
        'f-': 'Südgeorgien und die Südlichen Sandwichinseln',
      },
      'en-US': {
        'f-': 'South Georgia and the South Sandwich Islands',
      },
      'fr-CH': {
        'f-': 'Géorgie du Sud et les îles Sandwich du Sud',
      },
    },
  },
  {
    _id: '#GT',
    continent: '#NA',
    designation: {
      'de-CH': {
        'f-': 'Guatemala',
      },
      'en-US': {
        'f-': 'Guatemala',
      },
      'fr-CH': {
        'f-': 'Guatemala',
      },
    },
    ePoliceId: '2a893957-e761-11e8-aea4-43375a4a09b8',
    iso3166: 'GT',
    lat: 15.5,
    lon: -90.25,
    officialDesignation: {
      'de-CH': {
        'f-': 'Republik Guatemala',
      },
      'en-US': {
        'f-': 'Republic of Guatemala',
      },
      'fr-CH': {
        'f-': 'République du Guatemala',
      },
    },
  },
  {
    _id: '#GU',
    continent: '#OC',
    designation: {
      'de-CH': {
        'f-': 'Guam',
      },
      'en-US': {
        'f-': 'Guam',
      },
      'fr-CH': {
        'f-': 'Guam',
      },
    },
    ePoliceId: '2a914f10-e761-11e8-aea4-43375a4a09b8',
    iso3166: 'GU',
    lat: 13.46666666,
    lon: 144.78333333,
    officialDesignation: {
      'de-CH': {
        'f-': 'Guam',
      },
      'en-US': {
        'f-': 'Guam',
      },
      'fr-CH': {
        'f-': 'Guam',
      },
    },
  },
  {
    _id: '#GW',
    continent: '#AF',
    designation: {
      'de-CH': {
        'f-': 'Guinea-Bissau',
      },
      'en-US': {
        'f-': 'Guinea-Bissau',
      },
      'fr-CH': {
        'f-': 'Guinée-Bissau',
      },
    },
    ePoliceId: '2a88eaf9-e761-11e8-aea4-43375a4a09b8',
    iso3166: 'GW',
    lat: 12,
    lon: -15,
    officialDesignation: {
      'de-CH': {
        'f-': 'Republik Guinea-Bissau',
      },
      'en-US': {
        'f-': 'Republic of Guinea-Bissau',
      },
      'fr-CH': {
        'f-': 'République de Guinée-Bissau',
      },
    },
  },
  {
    _id: '#GY',
    continent: '#SA',
    designation: {
      'de-CH': {
        'f-': 'Guyana',
      },
      'en-US': {
        'f-': 'Guyana',
      },
      'fr-CH': {
        'f-': 'Guyana',
      },
    },
    ePoliceId: '2a893959-e761-11e8-aea4-43375a4a09b8',
    iso3166: 'GY',
    lat: 5,
    lon: -59,
    officialDesignation: {
      'de-CH': {
        'f-': 'Kooperative Republik Guyana',
      },
      'en-US': {
        'f-': 'Co-operative Republic of Guyana',
      },
      'fr-CH': {
        'f-': 'République coopérative de Guyana',
      },
    },
  },
  {
    _id: '#HK',
    continent: '#AS',
    designation: {
      'de-CH': {
        'f-': 'Hongkong',
      },
      'en-US': {
        'f-': 'Hong Kong',
      },
      'fr-CH': {
        'f-': 'Hong Kong',
      },
    },
    ePoliceId: '2a8bf7a5-e761-11e8-aea4-43375a4a09b8',
    iso3166: 'HK',
    lat: 22.267,
    lon: 114.188,
    officialDesignation: {
      'de-CH': {
        'f-': 'Sonderverwaltungszone der Volksrepublik China',
      },
      'en-US': {
        'f-': "Hong Kong Special Administrative Region of the People's Republic of China",
      },
      'fr-CH': {
        'f-': 'Région administrative spéciale de Hong Kong de la République populaire de Chine',
      },
    },
  },
  {
    _id: '#HM',
    continent: '#AN',
    designation: {
      'de-CH': {
        'f-': 'Heard und die McDonaldinseln',
      },
      'en-US': {
        'f-': 'Heard Island and McDonald Islands',
      },
      'fr-CH': {
        'f-': 'Îles Heard-et-MacDonald',
      },
    },
    ePoliceId: '2a914f13-e761-11e8-aea4-43375a4a09b8',
    iso3166: 'HM',
    lat: -53.1,
    lon: 72.51666666,
    officialDesignation: {
      'de-CH': {
        'f-': 'Heard und McDonaldinseln',
      },
      'en-US': {
        'f-': 'Heard Island and McDonald Islands',
      },
      'fr-CH': {
        'f-': 'Des îles Heard et McDonald',
      },
    },
  },
  {
    _id: '#HN',
    continent: '#NA',
    designation: {
      'de-CH': {
        'f-': 'Honduras',
      },
      'en-US': {
        'f-': 'Honduras',
      },
      'fr-CH': {
        'f-': 'Honduras',
      },
    },
    ePoliceId: '2a89395c-e761-11e8-aea4-43375a4a09b8',
    iso3166: 'HN',
    lat: 15,
    lon: -86.5,
    officialDesignation: {
      'de-CH': {
        'f-': 'Republik Honduras',
      },
      'en-US': {
        'f-': 'Republic of Honduras',
      },
      'fr-CH': {
        'f-': 'République du Honduras',
      },
    },
  },
  {
    _id: '#HR',
    continent: '#EU',
    designation: {
      'de-CH': {
        'f-': 'Kroatien',
      },
      'en-US': {
        'f-': 'Croatia',
      },
      'fr-CH': {
        'f-': 'Croatie',
      },
    },
    ePoliceId: '2a88eadd-e761-11e8-aea4-43375a4a09b8',
    iso3166: 'HR',
    lat: 45.16666666,
    lon: 15.5,
    officialDesignation: {
      'de-CH': {
        'f-': 'Republik Kroatien',
      },
      'en-US': {
        'f-': 'Republic of Croatia',
      },
      'fr-CH': {
        'f-': 'République de Croatie',
      },
    },
  },
  {
    _id: '#HT',
    continent: '#NA',
    designation: {
      'de-CH': {
        'f-': 'Haiti',
      },
      'en-US': {
        'f-': 'Haiti',
      },
      'fr-CH': {
        'f-': 'Haïti',
      },
    },
    ePoliceId: '2a89395a-e761-11e8-aea4-43375a4a09b8',
    iso3166: 'HT',
    lat: 19,
    lon: -72.41666666,
    officialDesignation: {
      'de-CH': {
        'f-': 'Republik Haiti',
      },
      'en-US': {
        'f-': 'Republic of Haiti',
      },
      'fr-CH': {
        'f-': "République d'Haïti",
      },
    },
  },
  {
    _id: '#HU',
    continent: '#EU',
    designation: {
      'de-CH': {
        'f-': 'Ungarn',
      },
      'en-US': {
        'f-': 'Hungary',
      },
      'fr-CH': {
        'f-': 'Hongrie',
      },
    },
    ePoliceId: '2a88c3c6-e761-11e8-aea4-43375a4a09b8',
    iso3166: 'HU',
    lat: 47,
    lon: 20,
    officialDesignation: {
      'de-CH': {
        'f-': 'Ungarn',
      },
      'en-US': {
        'f-': 'Hungary',
      },
      'fr-CH': {
        'f-': 'Hongrie',
      },
    },
  },
  {
    _id: '#ID',
    continent: '#AS',
    designation: {
      'de-CH': {
        'f-': 'Indonesien',
      },
      'en-US': {
        'f-': 'Indonesia',
      },
      'fr-CH': {
        'f-': 'Indonésie',
      },
    },
    ePoliceId: '2a8c45c7-e761-11e8-aea4-43375a4a09b8',
    iso3166: 'ID',
    lat: -5,
    lon: 120,
    officialDesignation: {
      'de-CH': {
        'f-': 'Republik Indonesien',
      },
      'en-US': {
        'f-': 'Republic of Indonesia',
      },
      'fr-CH': {
        'f-': "République d'Indonésie",
      },
    },
  },
  {
    _id: '#IE',
    continent: '#EU',
    designation: {
      'de-CH': {
        'f-': 'Irland',
      },
      'en-US': {
        'f-': 'Ireland',
      },
      'fr-CH': {
        'f-': 'Irlande',
      },
    },
    ePoliceId: '2a88c3b4-e761-11e8-aea4-43375a4a09b8',
    iso3166: 'IE',
    lat: 53,
    lon: -8,
    officialDesignation: {
      'de-CH': {
        'f-': 'Republik Irland',
      },
      'en-US': {
        'f-': 'Republic of Ireland',
      },
      'fr-CH': {
        'f-': "République d'Irlande",
      },
    },
  },
  {
    _id: '#IL',
    continent: '#AS',
    designation: {
      'de-CH': {
        'f-': 'Israel',
      },
      'en-US': {
        'f-': 'Israel',
      },
      'fr-CH': {
        'f-': 'Israël',
      },
    },
    ePoliceId: '2a8cbafa-e761-11e8-aea4-43375a4a09b8',
    iso3166: 'IL',
    lat: 31.47,
    lon: 35.13,
    officialDesignation: {
      'de-CH': {
        'f-': 'Staat Israel',
      },
      'en-US': {
        'f-': 'State of Israel',
      },
      'fr-CH': {
        'f-': "État d'Israël",
      },
    },
  },
  {
    _id: '#IM',
    continent: '#EU',
    designation: {
      'de-CH': {
        'f-': 'Insel Man',
      },
      'en-US': {
        'f-': 'Isle of Man',
      },
      'fr-CH': {
        'f-': 'Île de Man',
      },
    },
    iso3166: 'IM',
    lat: 54.25,
    lon: -4.5,
    officialDesignation: {
      'de-CH': {
        'f-': 'Isle of Man',
      },
      'en-US': {
        'f-': 'Isle of Man',
      },
      'fr-CH': {
        'f-': 'Isle of Man',
      },
    },
  },
  {
    _id: '#IN',
    continent: '#AS',
    designation: {
      'de-CH': {
        'f-': 'Indien',
      },
      'en-US': {
        'f-': 'India',
      },
      'fr-CH': {
        'f-': 'Inde',
      },
    },
    ePoliceId: '2a8c1eb6-e761-11e8-aea4-43375a4a09b8',
    iso3166: 'IN',
    lat: 20,
    lon: 77,
    officialDesignation: {
      'de-CH': {
        'f-': 'Republik Indien',
      },
      'en-US': {
        'f-': 'Republic of India',
      },
      'fr-CH': {
        'f-': "République de l'Inde",
      },
    },
  },
  {
    _id: '#IO',
    continent: '#AF',
    designation: {
      'de-CH': {
        'f-': 'Britisches Territorium im Indischen Ozean',
      },
      'en-US': {
        'f-': 'British Indian Ocean Territory',
      },
      'fr-CH': {
        'f-': "Territoire britannique de l'océan Indien",
      },
    },
    ePoliceId: '2a893946-e761-11e8-aea4-43375a4a09b8',
    iso3166: 'IO',
    lat: -6,
    lon: 71.5,
    officialDesignation: {
      'de-CH': {
        'f-': 'Britisch-Indischer Ozean',
      },
      'en-US': {
        'f-': 'British Indian Ocean Territory',
      },
      'fr-CH': {
        'f-': "Territoire britannique de l' océan Indien",
      },
    },
  },
  {
    _id: '#IQ',
    continent: '#AS',
    designation: {
      'de-CH': {
        'f-': 'Irak',
      },
      'en-US': {
        'f-': 'Iraq',
      },
      'fr-CH': {
        'f-': 'Irak',
      },
    },
    ePoliceId: '2a8c6cd8-e761-11e8-aea4-43375a4a09b8',
    iso3166: 'IQ',
    lat: 33,
    lon: 44,
    officialDesignation: {
      'de-CH': {
        'f-': 'Republik Irak',
      },
      'en-US': {
        'f-': 'Republic of Iraq',
      },
      'fr-CH': {
        'f-': "République d'Irak",
      },
    },
  },
  {
    _id: '#IR',
    continent: '#AS',
    designation: {
      'de-CH': {
        'f-': 'Iran',
      },
      'en-US': {
        'f-': 'Iran',
      },
      'fr-CH': {
        'f-': 'Iran',
      },
    },
    ePoliceId: '2a8c93e9-e761-11e8-aea4-43375a4a09b8',
    iso3166: 'IR',
    lat: 32,
    lon: 53,
    officialDesignation: {
      'de-CH': {
        'f-': 'Islamische Republik Iran',
      },
      'en-US': {
        'f-': 'Islamic Republic of Iran',
      },
      'fr-CH': {
        'f-': "République islamique d'Iran",
      },
    },
  },
  {
    _id: '#IS',
    continent: '#EU',
    designation: {
      'de-CH': {
        'f-': 'Island',
      },
      'en-US': {
        'f-': 'Iceland',
      },
      'fr-CH': {
        'f-': 'Islande',
      },
    },
    ePoliceId: '2a88c3b5-e761-11e8-aea4-43375a4a09b8',
    iso3166: 'IS',
    lat: 65,
    lon: -18,
    officialDesignation: {
      'de-CH': {
        'f-': 'Island',
      },
      'en-US': {
        'f-': 'Iceland',
      },
      'fr-CH': {
        'f-': "République d'Islande",
      },
    },
  },
  {
    _id: '#IT',
    continent: '#EU',
    designation: {
      'de-CH': {
        'f-': 'Italien',
      },
      'en-US': {
        'f-': 'Italy',
      },
      'fr-CH': {
        'f-': 'Italie',
      },
    },
    ePoliceId: '2a88c3b6-e761-11e8-aea4-43375a4a09b8',
    iso3166: 'IT',
    lat: 42.83333333,
    lon: 12.83333333,
    officialDesignation: {
      'de-CH': {
        'f-': 'Italienische Republik',
      },
      'en-US': {
        'f-': 'Italian Republic',
      },
      'fr-CH': {
        'f-': 'République italienne',
      },
    },
  },
  {
    _id: '#JE',
    continent: '#EU',
    designation: {
      'de-CH': {
        'f-': 'Jersey',
      },
      'en-US': {
        'f-': 'Jersey',
      },
      'fr-CH': {
        'f-': 'Jersey',
      },
    },
    iso3166: 'JE',
    lat: 49.25,
    lon: -2.16666666,
    officialDesignation: {
      'de-CH': {
        'f-': 'Vogtei Jersey',
      },
      'en-US': {
        'f-': 'Bailiwick of Jersey',
      },
      'fr-CH': {
        'f-': 'Bailliage de Jersey',
      },
    },
  },
  {
    _id: '#JM',
    continent: '#NA',
    designation: {
      'de-CH': {
        'f-': 'Jamaika',
      },
      'en-US': {
        'f-': 'Jamaica',
      },
      'fr-CH': {
        'f-': 'Jamaïque',
      },
    },
    ePoliceId: '2a89395d-e761-11e8-aea4-43375a4a09b8',
    iso3166: 'JM',
    lat: 18.25,
    lon: -77.5,
    officialDesignation: {
      'de-CH': {
        'f-': 'Jamaika',
      },
      'en-US': {
        'f-': 'Jamaica',
      },
      'fr-CH': {
        'f-': 'Jamaïque',
      },
    },
  },
  {
    _id: '#JO',
    continent: '#AS',
    designation: {
      'de-CH': {
        'f-': 'Jordanien',
      },
      'en-US': {
        'f-': 'Jordan',
      },
      'fr-CH': {
        'f-': 'Jordanie',
      },
    },
    ePoliceId: '2a8d302d-e761-11e8-aea4-43375a4a09b8',
    iso3166: 'JO',
    lat: 31,
    lon: 36,
    officialDesignation: {
      'de-CH': {
        'f-': 'Haschemitisches Königreich Jordanien',
      },
      'en-US': {
        'f-': 'Hashemite Kingdom of Jordan',
      },
      'fr-CH': {
        'f-': 'Royaume hachémite de Jordanie',
      },
    },
  },
  {
    _id: '#JP',
    continent: '#AS',
    designation: {
      'de-CH': {
        'f-': 'Japan',
      },
      'en-US': {
        'f-': 'Japan',
      },
      'fr-CH': {
        'f-': 'Japon',
      },
    },
    ePoliceId: '2a8ce20b-e761-11e8-aea4-43375a4a09b8',
    iso3166: 'JP',
    lat: 36,
    lon: 138,
    officialDesignation: {
      'de-CH': {
        'f-': 'Japan',
      },
      'en-US': {
        'f-': 'Japan',
      },
      'fr-CH': {
        'f-': 'Japon',
      },
    },
  },
  {
    _id: '#KE',
    continent: '#AF',
    designation: {
      'de-CH': {
        'f-': 'Kenia',
      },
      'en-US': {
        'f-': 'Kenya',
      },
      'fr-CH': {
        'f-': 'Kenya',
      },
    },
    ePoliceId: '2a89120e-e761-11e8-aea4-43375a4a09b8',
    iso3166: 'KE',
    lat: 1,
    lon: 38,
    officialDesignation: {
      'de-CH': {
        'f-': 'Republik Kenia',
      },
      'en-US': {
        'f-': 'Republic of Kenya',
      },
      'fr-CH': {
        'f-': 'République du Kenya',
      },
    },
  },
  {
    _id: '#KG',
    continent: '#AS',
    designation: {
      'de-CH': {
        'f-': 'Kirgisistan',
      },
      'en-US': {
        'f-': 'Kyrgyzstan',
      },
      'fr-CH': {
        'f-': 'Kirghizistan',
      },
    },
    ePoliceId: '2a9127eb-e761-11e8-aea4-43375a4a09b8',
    iso3166: 'KG',
    lat: 41,
    lon: 75,
    officialDesignation: {
      'de-CH': {
        'f-': 'Kirgisische Republik',
      },
      'en-US': {
        'f-': 'Kyrgyz Republic',
      },
      'fr-CH': {
        'f-': 'République kirghize',
      },
    },
  },
  {
    _id: '#KH',
    continent: '#AS',
    designation: {
      'de-CH': {
        'f-': 'Kambodscha',
      },
      'en-US': {
        'f-': 'Cambodia',
      },
      'fr-CH': {
        'f-': 'Cambodge',
      },
    },
    ePoliceId: '2a8d573e-e761-11e8-aea4-43375a4a09b8',
    iso3166: 'KH',
    lat: 13,
    lon: 105,
    officialDesignation: {
      'de-CH': {
        'f-': 'Königreich Kambodscha',
      },
      'en-US': {
        'f-': 'Kingdom of Cambodia',
      },
      'fr-CH': {
        'f-': 'Royaume du Cambodge',
      },
    },
  },
  {
    _id: '#KI',
    continent: '#OC',
    designation: {
      'de-CH': {
        'f-': 'Kiribati',
      },
      'en-US': {
        'f-': 'Kiribati',
      },
      'fr-CH': {
        'f-': 'Kiribati',
      },
    },
    ePoliceId: '2a914f0b-e761-11e8-aea4-43375a4a09b8',
    iso3166: 'KI',
    lat: 1.41666666,
    lon: 173,
    officialDesignation: {
      'de-CH': {
        'f-': 'Unabhängige und souveräne Republik Kiribati',
      },
      'en-US': {
        'f-': 'Independent and Sovereign Republic of Kiribati',
      },
      'fr-CH': {
        'f-': 'République de Kiribati',
      },
    },
  },
  {
    _id: '#KM',
    continent: '#AF',
    designation: {
      'de-CH': {
        'f-': 'Union der Komoren',
      },
      'en-US': {
        'f-': 'Comoros',
      },
      'fr-CH': {
        'f-': 'Comores',
      },
    },
    ePoliceId: '2a89120f-e761-11e8-aea4-43375a4a09b8',
    iso3166: 'KM',
    lat: -12.16666666,
    lon: 44.25,
    officialDesignation: {
      'de-CH': {
        'f-': 'Union der Komoren',
      },
      'en-US': {
        'f-': 'Union of the Comoros',
      },
      'fr-CH': {
        'f-': 'Union des Comores',
      },
    },
  },
  {
    _id: '#KN',
    continent: '#NA',
    designation: {
      'de-CH': {
        'f-': 'Saint Christopher und Nevis',
      },
      'en-US': {
        'f-': 'Saint Kitts and Nevis',
      },
      'fr-CH': {
        'f-': 'Saint-Christophe-et-Niévès',
      },
    },
    ePoliceId: '2a895f83-e761-11e8-aea4-43375a4a09b8',
    iso3166: 'KN',
    lat: 17.33333333,
    lon: -62.75,
    officialDesignation: {
      'de-CH': {
        'f-': 'Föderation von Saint Kitts und Nevisa',
      },
      'en-US': {
        'f-': 'Federation of Saint Christopher and Nevisa',
      },
      'fr-CH': {
        'f-': 'Fédération de Saint -Christophe-et Nevisa',
      },
    },
  },
  {
    _id: '#KP',
    continent: '#AS',
    designation: {
      'de-CH': {
        'f-': 'Nordkorea',
      },
      'en-US': {
        'f-': 'North Korea',
      },
      'fr-CH': {
        'f-': 'Corée du Nord',
      },
    },
    ePoliceId: '2a8f04f9-e761-11e8-aea4-43375a4a09b8',
    iso3166: 'KP',
    lat: 40,
    lon: 127,
    officialDesignation: {
      'de-CH': {
        'f-': 'Demokratische Volksrepublik Korea',
      },
      'en-US': {
        'f-': "Democratic People's Republic of Korea",
      },
      'fr-CH': {
        'f-': 'République populaire démocratique de Corée',
      },
    },
  },
  {
    _id: '#KR',
    continent: '#AS',
    designation: {
      'de-CH': {
        'f-': 'Südkorea',
      },
      'en-US': {
        'f-': 'South Korea',
      },
      'fr-CH': {
        'f-': 'Corée du Sud',
      },
    },
    ePoliceId: '2a8fef5f-e761-11e8-aea4-43375a4a09b8',
    iso3166: 'KR',
    lat: 37,
    lon: 127.5,
    officialDesignation: {
      'de-CH': {
        'f-': 'Republik Korea',
      },
      'en-US': {
        'f-': 'Republic of Korea',
      },
      'fr-CH': {
        'f-': 'République de Corée',
      },
    },
  },
  {
    _id: '#KW',
    continent: '#AS',
    designation: {
      'de-CH': {
        'f-': 'Kuwait',
      },
      'en-US': {
        'f-': 'Kuwait',
      },
      'fr-CH': {
        'f-': 'Koweït',
      },
    },
    ePoliceId: '2a8da560-e761-11e8-aea4-43375a4a09b8',
    iso3166: 'KW',
    lat: 29.5,
    lon: 45.75,
    officialDesignation: {
      'de-CH': {
        'f-': 'Staat Kuwait',
      },
      'en-US': {
        'f-': 'State of Kuwait',
      },
      'fr-CH': {
        'f-': 'État du Koweït',
      },
    },
  },
  {
    _id: '#KY',
    continent: '#NA',
    designation: {
      'de-CH': {
        'f-': 'Kaimaninseln',
      },
      'en-US': {
        'f-': 'Cayman Islands',
      },
      'fr-CH': {
        'f-': 'Îles Caïmans',
      },
    },
    ePoliceId: '2a89ada6-e761-11e8-aea4-43375a4a09b8',
    iso3166: 'KY',
    lat: 19.5,
    lon: -80.5,
    officialDesignation: {
      'de-CH': {
        'f-': 'Cayman-Inseln',
      },
      'en-US': {
        'f-': 'Cayman Islands',
      },
      'fr-CH': {
        'f-': 'Îles Caïmans',
      },
    },
  },
  {
    _id: '#KZ',
    continent: '#AS',
    designation: {
      'de-CH': {
        'f-': 'Kasachstan',
      },
      'en-US': {
        'f-': 'Kazakhstan',
      },
      'fr-CH': {
        'f-': 'Kazakhstan',
      },
    },
    ePoliceId: '2a9127ea-e761-11e8-aea4-43375a4a09b8',
    iso3166: 'KZ',
    lat: 48,
    lon: 68,
    officialDesignation: {
      'de-CH': {
        'f-': 'Republik Kasachstan',
      },
      'en-US': {
        'f-': 'Republic of Kazakhstan',
      },
      'fr-CH': {
        'f-': 'République du Kazakhstan',
      },
    },
  },
  {
    _id: '#LA',
    continent: '#AS',
    designation: {
      'de-CH': {
        'f-': 'Laos',
      },
      'en-US': {
        'f-': 'Laos',
      },
      'fr-CH': {
        'f-': 'Laos',
      },
    },
    ePoliceId: '2a8dcc71-e761-11e8-aea4-43375a4a09b8',
    iso3166: 'LA',
    lat: 18,
    lon: 105,
    officialDesignation: {
      'de-CH': {
        'f-': 'Laos, Demokratische Volksrepublik',
      },
      'en-US': {
        'f-': "Lao People's Democratic Republic",
      },
      'fr-CH': {
        'f-': 'République démocratique populaire lao',
      },
    },
  },
  {
    _id: '#LB',
    continent: '#AS',
    designation: {
      'de-CH': {
        'f-': 'Libanon',
      },
      'en-US': {
        'f-': 'Lebanon',
      },
      'fr-CH': {
        'f-': 'Liban',
      },
    },
    ePoliceId: '2a8df382-e761-11e8-aea4-43375a4a09b8',
    iso3166: 'LB',
    lat: 33.83333333,
    lon: 35.83333333,
    officialDesignation: {
      'de-CH': {
        'f-': 'Libanesische Republik',
      },
      'en-US': {
        'f-': 'Lebanese Republic',
      },
      'fr-CH': {
        'f-': 'République libanaise',
      },
    },
  },
  {
    _id: '#LC',
    continent: '#NA',
    designation: {
      'de-CH': {
        'f-': 'Saint Lucia',
      },
      'en-US': {
        'f-': 'Saint Lucia',
      },
      'fr-CH': {
        'f-': 'Sainte-Lucie',
      },
    },
    ePoliceId: '2a895f81-e761-11e8-aea4-43375a4a09b8',
    iso3166: 'LC',
    lat: 13.88333333,
    lon: -60.96666666,
    officialDesignation: {
      'de-CH': {
        'f-': 'St. Lucia',
      },
      'en-US': {
        'f-': 'Saint Lucia',
      },
      'fr-CH': {
        'f-': 'Sainte-Lucie',
      },
    },
  },
  {
    _id: '#LI',
    continent: '#EU',
    designation: {
      'de-CH': {
        'f-': 'Liechtenstein',
      },
      'en-US': {
        'f-': 'Liechtenstein',
      },
      'fr-CH': {
        'f-': 'Liechtenstein',
      },
    },
    ePoliceId: '2a88c3b8-e761-11e8-aea4-43375a4a09b8',
    iso3166: 'LI',
    lat: 47.26666666,
    lon: 9.53333333,
    officialDesignation: {
      'de-CH': {
        'f-': 'Fürstentum Liechtenstein',
      },
      'en-US': {
        'f-': 'Principality of Liechtenstein',
      },
      'fr-CH': {
        'f-': 'Principauté du Liechtenstein',
      },
    },
  },
  {
    _id: '#LK',
    continent: '#AS',
    designation: {
      'de-CH': {
        'f-': 'Sri Lanka',
      },
      'en-US': {
        'f-': 'Sri Lanka',
      },
      'fr-CH': {
        'f-': 'Sri Lanka',
      },
    },
    ePoliceId: '2a8b8272-e761-11e8-aea4-43375a4a09b8',
    iso3166: 'LK',
    lat: 7,
    lon: 81,
    officialDesignation: {
      'de-CH': {
        'f-': 'Demokratische Sozialistische Republik Sri Lanka',
      },
      'en-US': {
        'f-': 'Democratic Socialist Republic of Sri Lanka',
      },
      'fr-CH': {
        'f-': 'République démocratique socialiste du Sri Lanka',
      },
    },
  },
  {
    _id: '#LR',
    continent: '#AF',
    designation: {
      'de-CH': {
        'f-': 'Liberia',
      },
      'en-US': {
        'f-': 'Liberia',
      },
      'fr-CH': {
        'f-': 'Liberia',
      },
    },
    ePoliceId: '2a891213-e761-11e8-aea4-43375a4a09b8',
    iso3166: 'LR',
    lat: 6.5,
    lon: -9.5,
    officialDesignation: {
      'de-CH': {
        'f-': 'Republik Liberia',
      },
      'en-US': {
        'f-': 'Republic of Liberia',
      },
      'fr-CH': {
        'f-': 'République du Libéria',
      },
    },
  },
  {
    _id: '#LS',
    continent: '#AF',
    designation: {
      'de-CH': {
        'f-': 'Lesotho',
      },
      'en-US': {
        'f-': 'Lesotho',
      },
      'fr-CH': {
        'f-': 'Lesotho',
      },
    },
    ePoliceId: '2a891212-e761-11e8-aea4-43375a4a09b8',
    iso3166: 'LS',
    lat: -29.5,
    lon: 28.5,
    officialDesignation: {
      'de-CH': {
        'f-': 'Königreich Lesotho',
      },
      'en-US': {
        'f-': 'Kingdom of Lesotho',
      },
      'fr-CH': {
        'f-': 'Royaume du Lesotho',
      },
    },
  },
  {
    _id: '#LT',
    continent: '#EU',
    designation: {
      'de-CH': {
        'f-': 'Litauen',
      },
      'en-US': {
        'f-': 'Lithuania',
      },
      'fr-CH': {
        'f-': 'Lituanie',
      },
    },
    ePoliceId: '2a88eae6-e761-11e8-aea4-43375a4a09b8',
    iso3166: 'LT',
    lat: 56,
    lon: 24,
    officialDesignation: {
      'de-CH': {
        'f-': 'Republik Litauen',
      },
      'en-US': {
        'f-': 'Republic of Lithuania',
      },
      'fr-CH': {
        'f-': 'République de Lituanie',
      },
    },
  },
  {
    _id: '#LU',
    continent: '#EU',
    designation: {
      'de-CH': {
        'f-': 'Luxemburg',
      },
      'en-US': {
        'f-': 'Luxembourg',
      },
      'fr-CH': {
        'f-': 'Luxembourg',
      },
    },
    ePoliceId: '2a88c3b9-e761-11e8-aea4-43375a4a09b8',
    iso3166: 'LU',
    lat: 49.75,
    lon: 6.16666666,
    officialDesignation: {
      'de-CH': {
        'f-': 'Großherzogtum Luxemburg,',
      },
      'en-US': {
        'f-': 'Grand Duchy of Luxembourg',
      },
      'fr-CH': {
        'f-': 'Grand-Duché de Luxembourg',
      },
    },
  },
  {
    _id: '#LV',
    continent: '#EU',
    designation: {
      'de-CH': {
        'f-': 'Lettland',
      },
      'en-US': {
        'f-': 'Latvia',
      },
      'fr-CH': {
        'f-': 'Lettonie',
      },
    },
    ePoliceId: '2a88eae5-e761-11e8-aea4-43375a4a09b8',
    iso3166: 'LV',
    lat: 57,
    lon: 25,
    officialDesignation: {
      'de-CH': {
        'f-': 'Republik Lettland',
      },
      'en-US': {
        'f-': 'Republic of Latvia',
      },
      'fr-CH': {
        'f-': 'République de Lettonie',
      },
    },
  },
  {
    _id: '#LY',
    continent: '#AF',
    designation: {
      'de-CH': {
        'f-': 'Libyen',
      },
      'en-US': {
        'f-': 'Libya',
      },
      'fr-CH': {
        'f-': 'Libye',
      },
    },
    ePoliceId: '2a891214-e761-11e8-aea4-43375a4a09b8',
    iso3166: 'LY',
    lat: 25,
    lon: 17,
    officialDesignation: {
      'de-CH': {
        'f-': 'Staat Libyen',
      },
      'en-US': {
        'f-': 'State of Libya',
      },
      'fr-CH': {
        'f-': 'Grande République arabe libyenne populaire et socialiste',
      },
    },
  },
  {
    _id: '#MA',
    continent: '#AF',
    designation: {
      'de-CH': {
        'f-': 'Marokko',
      },
      'en-US': {
        'f-': 'Morocco',
      },
      'fr-CH': {
        'f-': 'Maroc',
      },
    },
    ePoliceId: '2a891218-e761-11e8-aea4-43375a4a09b8',
    iso3166: 'MA',
    lat: 32,
    lon: -5,
    officialDesignation: {
      'de-CH': {
        'f-': 'Königreich Marokko',
      },
      'en-US': {
        'f-': 'Kingdom of Morocco',
      },
      'fr-CH': {
        'f-': 'Royaume du Maroc',
      },
    },
  },
  {
    _id: '#MC',
    continent: '#EU',
    designation: {
      'de-CH': {
        'f-': 'Monaco',
      },
      'en-US': {
        'f-': 'Monaco',
      },
      'fr-CH': {
        'f-': 'Monaco',
      },
    },
    ePoliceId: '2a88c3bb-e761-11e8-aea4-43375a4a09b8',
    iso3166: 'MC',
    lat: 43.73333333,
    lon: 7.4,
    officialDesignation: {
      'de-CH': {
        'f-': 'Fürstentum Monaco',
      },
      'en-US': {
        'f-': 'Principality of Monaco',
      },
      'fr-CH': {
        'f-': 'Principauté de Monaco',
      },
    },
  },
  {
    _id: '#MD',
    continent: '#EU',
    designation: {
      'de-CH': {
        'f-': 'Moldawie',
      },
      'en-US': {
        'f-': 'Moldova',
      },
      'fr-CH': {
        'f-': 'Moldavie',
      },
    },
    ePoliceId: '2a88eae7-e761-11e8-aea4-43375a4a09b8',
    iso3166: 'MD',
    lat: 47,
    lon: 29,
    officialDesignation: {
      'de-CH': {
        'f-': 'Republik Moldau',
      },
      'en-US': {
        'f-': 'Republic of Moldova',
      },
      'fr-CH': {
        'f-': 'République de Moldavie',
      },
    },
  },
  {
    _id: '#ME',
    continent: '#EU',
    designation: {
      'de-CH': {
        'f-': 'Montenegro',
      },
      'en-US': {
        'f-': 'Montenegro',
      },
      'fr-CH': {
        'f-': 'Monténégro',
      },
    },
    ePoliceId: '2a88eae1-e761-11e8-aea4-43375a4a09b8',
    iso3166: 'ME',
    lat: 42.5,
    lon: 19.3,
    officialDesignation: {
      'de-CH': {
        'f-': 'Montenegro',
      },
      'en-US': {
        'f-': 'Montenegro',
      },
      'fr-CH': {
        'f-': 'Monténégro',
      },
    },
  },
  {
    _id: '#MF',
    continent: '#NA',
    designation: {
      'de-CH': {
        'f-': 'Saint Martin',
      },
      'en-US': {
        'f-': 'Saint Martin',
      },
      'fr-CH': {
        'f-': 'Saint-Martin',
      },
    },
    iso3166: 'MF',
    lat: 18.08333333,
    lon: -63.95,
    officialDesignation: {
      'de-CH': {
        'f-': 'St. Martin',
      },
      'en-US': {
        'f-': 'Saint Martin',
      },
      'fr-CH': {
        'f-': 'Saint-Martin',
      },
    },
  },
  {
    _id: '#MG',
    continent: '#AF',
    designation: {
      'de-CH': {
        'f-': 'Madagaskar',
      },
      'en-US': {
        'f-': 'Madagascar',
      },
      'fr-CH': {
        'f-': 'Madagascar',
      },
    },
    ePoliceId: '2a891215-e761-11e8-aea4-43375a4a09b8',
    iso3166: 'MG',
    lat: -20,
    lon: 47,
    officialDesignation: {
      'de-CH': {
        'f-': 'Republik Madagaskar',
      },
      'en-US': {
        'f-': 'Republic of Madagascar',
      },
      'fr-CH': {
        'f-': 'République de Madagascar',
      },
    },
  },
  {
    _id: '#MH',
    continent: '#OC',
    designation: {
      'de-CH': {
        'f-': 'Marshallinseln',
      },
      'en-US': {
        'f-': 'Marshall Islands',
      },
      'fr-CH': {
        'f-': 'Îles Marshall',
      },
    },
    ePoliceId: '2a914f0c-e761-11e8-aea4-43375a4a09b8',
    iso3166: 'MH',
    lat: 9,
    lon: 168,
    officialDesignation: {
      'de-CH': {
        'f-': 'Republik der Marshall-Inseln',
      },
      'en-US': {
        'f-': 'Republic of the Marshall Islands',
      },
      'fr-CH': {
        'f-': 'République des Îles Marshall',
      },
    },
  },
  {
    _id: '#MK',
    continent: '#EU',
    designation: {
      'de-CH': {
        'f-': 'Mazedonien',
      },
      'en-US': {
        'f-': 'Macedonia',
      },
      'fr-CH': {
        'f-': 'Macédoine',
      },
    },
    ePoliceId: '2a88eae2-e761-11e8-aea4-43375a4a09b8',
    iso3166: 'MK',
    lat: 41.83333333,
    lon: 22,
    officialDesignation: {
      'de-CH': {
        'f-': 'Republik Mazedonien',
      },
      'en-US': {
        'f-': 'Republic of Macedonia',
      },
      'fr-CH': {
        'f-': 'République de Macédoine',
      },
    },
  },
  {
    _id: '#ML',
    continent: '#AF',
    designation: {
      'de-CH': {
        'f-': 'Mali',
      },
      'en-US': {
        'f-': 'Mali',
      },
      'fr-CH': {
        'f-': 'Mali',
      },
    },
    ePoliceId: '2a891217-e761-11e8-aea4-43375a4a09b8',
    iso3166: 'ML',
    lat: 17,
    lon: -4,
    officialDesignation: {
      'de-CH': {
        'f-': 'Republik Mali',
      },
      'en-US': {
        'f-': 'Republic of Mali',
      },
      'fr-CH': {
        'f-': 'République du Mali',
      },
    },
  },
  {
    _id: '#MM',
    continent: '#AS',
    designation: {
      'de-CH': {
        'f-': 'Myanmar',
      },
      'en-US': {
        'f-': 'Myanmar',
      },
      'fr-CH': {
        'f-': 'Birmanie',
      },
    },
    ePoliceId: '2a8b5b61-e761-11e8-aea4-43375a4a09b8',
    iso3166: 'MM',
    lat: 22,
    lon: 98,
    officialDesignation: {
      'de-CH': {
        'f-': 'Republik der Union von Myanmar',
      },
      'en-US': {
        'f-': 'Republic of the Union of Myanmar',
      },
      'fr-CH': {
        'f-': "République de l'Union du Myanmar",
      },
    },
  },
  {
    _id: '#MN',
    continent: '#AS',
    designation: {
      'de-CH': {
        'f-': 'Mongolei',
      },
      'en-US': {
        'f-': 'Mongolia',
      },
      'fr-CH': {
        'f-': 'Mongolie',
      },
    },
    ePoliceId: '2a8eb6d7-e761-11e8-aea4-43375a4a09b8',
    iso3166: 'MN',
    lat: 46,
    lon: 105,
    officialDesignation: {
      'de-CH': {
        'f-': 'Mongolei',
      },
      'en-US': {
        'f-': 'Mongolia',
      },
      'fr-CH': {
        'f-': 'Mongolie',
      },
    },
  },
  {
    _id: '#MO',
    continent: '#AS',
    designation: {
      'de-CH': {
        'f-': 'Macao',
      },
      'en-US': {
        'f-': 'Macau',
      },
      'fr-CH': {
        'f-': 'Macao',
      },
    },
    ePoliceId: '2a8e1a93-e761-11e8-aea4-43375a4a09b8',
    iso3166: 'MO',
    lat: 22.16666666,
    lon: 113.55,
    officialDesignation: {
      'de-CH': {
        'f-': 'Sonderverwaltungsregion Macau der Volksrepublik China',
      },
      'en-US': {
        'f-': "Macao Special Administrative Region of the People's Republic of China",
      },
      'fr-CH': {
        'f-': 'Région administrative spéciale de Macao de la République populaire de Chine',
      },
    },
  },
  {
    _id: '#MP',
    continent: '#OC',
    designation: {
      'de-CH': {
        'f-': 'Nördliche Marianen',
      },
      'en-US': {
        'f-': 'Northern Mariana Islands',
      },
      'fr-CH': {
        'f-': 'Îles Mariannes du Nord',
      },
    },
    iso3166: 'MP',
    lat: 15.2,
    lon: 145.75,
    officialDesignation: {
      'de-CH': {
        'f-': 'Commonwealth der Nördlichen Marianen',
      },
      'en-US': {
        'f-': 'Commonwealth of the Northern Mariana Islands',
      },
      'fr-CH': {
        'f-': 'Commonwealth des îles Mariannes du Nord',
      },
    },
  },
  {
    _id: '#MQ',
    continent: '#NA',
    designation: {
      'de-CH': {
        'f-': 'Martinique',
      },
      'en-US': {
        'f-': 'Martinique',
      },
      'fr-CH': {
        'f-': 'Martinique',
      },
    },
    ePoliceId: '2a895f71-e761-11e8-aea4-43375a4a09b8',
    iso3166: 'MQ',
    lat: 14.666667,
    lon: -61,
    officialDesignation: {
      'de-CH': {
        'f-': 'Martinique',
      },
      'en-US': {
        'f-': 'Martinique',
      },
      'fr-CH': {
        'f-': 'Martinique',
      },
    },
  },
  {
    _id: '#MR',
    continent: '#AF',
    designation: {
      'de-CH': {
        'f-': 'Mauretanien',
      },
      'en-US': {
        'f-': 'Mauritania',
      },
      'fr-CH': {
        'f-': 'Mauritanie',
      },
    },
    ePoliceId: '2a891219-e761-11e8-aea4-43375a4a09b8',
    iso3166: 'MR',
    lat: 20,
    lon: -12,
    officialDesignation: {
      'de-CH': {
        'f-': 'Islamische Republik Mauretanien',
      },
      'en-US': {
        'f-': 'Islamic Republic of Mauritania',
      },
      'fr-CH': {
        'f-': 'République islamique de Mauritanie',
      },
    },
  },
  {
    _id: '#MS',
    continent: '#NA',
    designation: {
      'de-CH': {
        'f-': 'Montserrat',
      },
      'en-US': {
        'f-': 'Montserrat',
      },
      'fr-CH': {
        'f-': 'Montserrat',
      },
    },
    ePoliceId: '2a89fbc8-e761-11e8-aea4-43375a4a09b8',
    iso3166: 'MS',
    lat: 16.75,
    lon: -62.2,
    officialDesignation: {
      'de-CH': {
        'f-': 'Montserrat',
      },
      'en-US': {
        'f-': 'Montserrat',
      },
      'fr-CH': {
        'f-': 'Montserrat',
      },
    },
  },
  {
    _id: '#MT',
    continent: '#EU',
    designation: {
      'de-CH': {
        'f-': 'Malta',
      },
      'en-US': {
        'f-': 'Malta',
      },
      'fr-CH': {
        'f-': 'Malte',
      },
    },
    ePoliceId: '2a88c3ba-e761-11e8-aea4-43375a4a09b8',
    iso3166: 'MT',
    lat: 35.83333333,
    lon: 14.58333333,
    officialDesignation: {
      'de-CH': {
        'f-': 'Republik Malta',
      },
      'en-US': {
        'f-': 'Republic of Malta',
      },
      'fr-CH': {
        'f-': 'République de Malte',
      },
    },
  },
  {
    _id: '#MU',
    continent: '#AF',
    designation: {
      'de-CH': {
        'f-': 'Mauritius',
      },
      'en-US': {
        'f-': 'Mauritius',
      },
      'fr-CH': {
        'f-': 'Île Maurice',
      },
    },
    ePoliceId: '2a89121a-e761-11e8-aea4-43375a4a09b8',
    iso3166: 'MU',
    lat: -20.28333333,
    lon: 57.55,
    officialDesignation: {
      'de-CH': {
        'f-': 'Republik Mauritius',
      },
      'en-US': {
        'f-': 'Republic of Mauritius',
      },
      'fr-CH': {
        'f-': 'République de Maurice',
      },
    },
  },
  {
    _id: '#MV',
    continent: '#AS',
    designation: {
      'de-CH': {
        'f-': 'Malediven',
      },
      'en-US': {
        'f-': 'Maldives',
      },
      'fr-CH': {
        'f-': 'Maldives',
      },
    },
    ePoliceId: '2a8e68b5-e761-11e8-aea4-43375a4a09b8',
    iso3166: 'MV',
    lat: 3.25,
    lon: 73,
    officialDesignation: {
      'de-CH': {
        'f-': 'Republik Malediven',
      },
      'en-US': {
        'f-': 'Republic of the Maldives',
      },
      'fr-CH': {
        'f-': 'République des Maldives',
      },
    },
  },
  {
    _id: '#MW',
    continent: '#AF',
    designation: {
      'de-CH': {
        'f-': 'Malawi',
      },
      'en-US': {
        'f-': 'Malawi',
      },
      'fr-CH': {
        'f-': 'Malawi',
      },
    },
    ePoliceId: '2a891216-e761-11e8-aea4-43375a4a09b8',
    iso3166: 'MW',
    lat: -13.5,
    lon: 34,
    officialDesignation: {
      'de-CH': {
        'f-': 'Republik Malawi',
      },
      'en-US': {
        'f-': 'Republic of Malawi',
      },
      'fr-CH': {
        'f-': 'République du Malawi',
      },
    },
  },
  {
    _id: '#MX',
    continent: '#NA',
    designation: {
      'de-CH': {
        'f-': 'Mexiko',
      },
      'en-US': {
        'f-': 'Mexico',
      },
      'fr-CH': {
        'f-': 'Mexique',
      },
    },
    ePoliceId: '2a895f72-e761-11e8-aea4-43375a4a09b8',
    iso3166: 'MX',
    lat: 23,
    lon: -102,
    officialDesignation: {
      'de-CH': {
        'f-': 'Vereinigte Mexikanische Staaten',
      },
      'en-US': {
        'f-': 'United Mexican States',
      },
      'fr-CH': {
        'f-': 'États-Unis du Mexique',
      },
    },
  },
  {
    _id: '#MY',
    continent: '#AS',
    designation: {
      'de-CH': {
        'f-': 'Malaysia',
      },
      'en-US': {
        'f-': 'Malaysia',
      },
      'fr-CH': {
        'f-': 'Malaisie',
      },
    },
    ePoliceId: '2a8e41a4-e761-11e8-aea4-43375a4a09b8',
    iso3166: 'MY',
    lat: 2.5,
    lon: 112.5,
    officialDesignation: {
      'de-CH': {
        'f-': 'Malaysia',
      },
      'en-US': {
        'f-': 'Malaysia',
      },
      'fr-CH': {
        'f-': 'Fédération de Malaisie',
      },
    },
  },
  {
    _id: '#MZ',
    continent: '#AF',
    designation: {
      'de-CH': {
        'f-': 'Mosambik',
      },
      'en-US': {
        'f-': 'Mozambique',
      },
      'fr-CH': {
        'f-': 'Mozambique',
      },
    },
    ePoliceId: '2a89121b-e761-11e8-aea4-43375a4a09b8',
    iso3166: 'MZ',
    lat: -18.25,
    lon: 35,
    officialDesignation: {
      'de-CH': {
        'f-': 'Republik Mosambik',
      },
      'en-US': {
        'f-': 'Republic of Mozambique',
      },
      'fr-CH': {
        'f-': 'République du Mozambique',
      },
    },
  },
  {
    _id: '#NA',
    continent: '#AF',
    designation: {
      'de-CH': {
        'f-': 'Namibia',
      },
      'en-US': {
        'f-': 'Namibia',
      },
      'fr-CH': {
        'f-': 'Namibie',
      },
    },
    ePoliceId: '2a89122a-e761-11e8-aea4-43375a4a09b8',
    iso3166: 'NA',
    lat: -22,
    lon: 17,
    officialDesignation: {
      'de-CH': {
        'f-': 'Republik Namibia',
      },
      'en-US': {
        'f-': 'Republic of Namibia',
      },
      'fr-CH': {
        'f-': 'République de Namibie',
      },
    },
  },
  {
    _id: '#NC',
    continent: '#OC',
    designation: {
      'de-CH': {
        'f-': 'Neukaledonien',
      },
      'en-US': {
        'f-': 'New Caledonia',
      },
      'fr-CH': {
        'f-': 'Nouvelle-Calédonie',
      },
    },
    ePoliceId: '2a914f03-e761-11e8-aea4-43375a4a09b8',
    iso3166: 'NC',
    lat: -21.5,
    lon: 165.5,
    officialDesignation: {
      'de-CH': {
        'f-': 'Neukaledonien',
      },
      'en-US': {
        'f-': 'New Caledonia',
      },
      'fr-CH': {
        'f-': 'Nouvelle-Calédonie',
      },
    },
  },
  {
    _id: '#NE',
    continent: '#AF',
    designation: {
      'de-CH': {
        'f-': 'Niger',
      },
      'en-US': {
        'f-': 'Niger',
      },
      'fr-CH': {
        'f-': 'Niger',
      },
    },
    ePoliceId: '2a89121c-e761-11e8-aea4-43375a4a09b8',
    iso3166: 'NE',
    lat: 16,
    lon: 8,
    officialDesignation: {
      'de-CH': {
        'f-': 'Republik Niger',
      },
      'en-US': {
        'f-': 'Republic of Niger',
      },
      'fr-CH': {
        'f-': 'République du Niger',
      },
    },
  },
  {
    _id: '#NF',
    continent: '#OC',
    designation: {
      'de-CH': {
        'f-': 'Norfolkinsel',
      },
      'en-US': {
        'f-': 'Norfolk Island',
      },
      'fr-CH': {
        'f-': 'Île Norfolk',
      },
    },
    ePoliceId: '2a914f14-e761-11e8-aea4-43375a4a09b8',
    iso3166: 'NF',
    lat: -29.03333333,
    lon: 167.95,
    officialDesignation: {
      'de-CH': {
        'f-': 'Gebiet der Norfolk-Insel',
      },
      'en-US': {
        'f-': 'Territory of Norfolk Island',
      },
      'fr-CH': {
        'f-': "Territoire de l'île Norfolk",
      },
    },
  },
  {
    _id: '#NG',
    continent: '#AF',
    designation: {
      'de-CH': {
        'f-': 'Nigeria',
      },
      'en-US': {
        'f-': 'Nigeria',
      },
      'fr-CH': {
        'f-': 'Nigéria',
      },
    },
    ePoliceId: '2a89121d-e761-11e8-aea4-43375a4a09b8',
    iso3166: 'NG',
    lat: 10,
    lon: 8,
    officialDesignation: {
      'de-CH': {
        'f-': 'Bundesrepublik Nigeria',
      },
      'en-US': {
        'f-': 'Federal Republic of Nigeria',
      },
      'fr-CH': {
        'f-': 'République fédérale du Nigeria',
      },
    },
  },
  {
    _id: '#NI',
    continent: '#NA',
    designation: {
      'de-CH': {
        'f-': 'Nicaragua',
      },
      'en-US': {
        'f-': 'Nicaragua',
      },
      'fr-CH': {
        'f-': 'Nicaragua',
      },
    },
    ePoliceId: '2a895f73-e761-11e8-aea4-43375a4a09b8',
    iso3166: 'NI',
    lat: 13,
    lon: -85,
    officialDesignation: {
      'de-CH': {
        'f-': 'Republik Nicaragua',
      },
      'en-US': {
        'f-': 'Republic of Nicaragua',
      },
      'fr-CH': {
        'f-': 'République du Nicaragua',
      },
    },
  },
  {
    _id: '#NL',
    continent: '#EU',
    designation: {
      'de-CH': {
        'f-': 'Niederlande',
      },
      'en-US': {
        'f-': 'Netherlands',
      },
      'fr-CH': {
        'f-': 'Pays-Bas',
      },
    },
    ePoliceId: '2a88c3bc-e761-11e8-aea4-43375a4a09b8',
    iso3166: 'NL',
    lat: 52.5,
    lon: 5.75,
    officialDesignation: {
      'de-CH': {
        'f-': 'Niederlande',
      },
      'en-US': {
        'f-': 'Netherlands',
      },
      'fr-CH': {
        'f-': 'Pays-Bas',
      },
    },
  },
  {
    _id: '#NO',
    continent: '#EU',
    designation: {
      'de-CH': {
        'f-': 'Norwegen',
      },
      'en-US': {
        'f-': 'Norway',
      },
      'fr-CH': {
        'f-': 'Norvège',
      },
    },
    ePoliceId: '2a88c3bd-e761-11e8-aea4-43375a4a09b8',
    iso3166: 'NO',
    lat: 62,
    lon: 10,
    officialDesignation: {
      'de-CH': {
        'f-': 'Königreich Norwegen',
      },
      'en-US': {
        'f-': 'Kingdom of Norway',
      },
      'fr-CH': {
        'f-': 'Royaume de Norvège',
      },
    },
  },
  {
    _id: '#NP',
    continent: '#AS',
    designation: {
      'de-CH': {
        'f-': 'Népal',
      },
      'en-US': {
        'f-': 'Nepal',
      },
      'fr-CH': {
        'f-': 'Népal',
      },
    },
    ePoliceId: '2a8edde8-e761-11e8-aea4-43375a4a09b8',
    iso3166: 'NP',
    lat: 28,
    lon: 84,
    officialDesignation: {
      'de-CH': {
        'f-': 'Demokratischen Bundesrepublik Nepal',
      },
      'en-US': {
        'f-': 'Federal Democratic Republic of Nepal',
      },
      'fr-CH': {
        'f-': 'République du Népal',
      },
    },
  },
  {
    _id: '#NR',
    continent: '#OC',
    designation: {
      'de-CH': {
        'f-': 'Nauru',
      },
      'en-US': {
        'f-': 'Nauru',
      },
      'fr-CH': {
        'f-': 'Nauru',
      },
    },
    ePoliceId: '2a914f01-e761-11e8-aea4-43375a4a09b8',
    iso3166: 'NR',
    lat: -0.53333333,
    lon: 166.91666666,
    officialDesignation: {
      'de-CH': {
        'f-': 'Republik Nauru',
      },
      'en-US': {
        'f-': 'Republic of Nauru',
      },
      'fr-CH': {
        'f-': 'République de Nauru',
      },
    },
  },
  {
    _id: '#NU',
    continent: '#OC',
    designation: {
      'de-CH': {
        'f-': 'Niue',
      },
      'en-US': {
        'f-': 'Niue',
      },
      'fr-CH': {
        'f-': 'Niue',
      },
    },
    ePoliceId: '2a914f18-e761-11e8-aea4-43375a4a09b8',
    iso3166: 'NU',
    lat: -19.03333333,
    lon: -169.86666666,
    officialDesignation: {
      'de-CH': {
        'f-': 'Niue',
      },
      'en-US': {
        'f-': 'Niue',
      },
      'fr-CH': {
        'f-': 'Niue',
      },
    },
  },
  {
    _id: '#NZ',
    continent: '#OC',
    designation: {
      'de-CH': {
        'f-': 'Neuseeland',
      },
      'en-US': {
        'f-': 'New Zealand',
      },
      'fr-CH': {
        'f-': 'Nouvelle-Zélande',
      },
    },
    ePoliceId: '2a914f04-e761-11e8-aea4-43375a4a09b8',
    iso3166: 'NZ',
    lat: -41,
    lon: 174,
    officialDesignation: {
      'de-CH': {
        'f-': 'Neuseeland',
      },
      'en-US': {
        'f-': 'New Zealand',
      },
      'fr-CH': {
        'f-': 'Nouvelle-Zélande',
      },
    },
  },
  {
    _id: '#OM',
    continent: '#AS',
    designation: {
      'de-CH': {
        'f-': 'Oman',
      },
      'en-US': {
        'f-': 'Oman',
      },
      'fr-CH': {
        'f-': 'Oman',
      },
    },
    ePoliceId: '2a8e8fc6-e761-11e8-aea4-43375a4a09b8',
    iso3166: 'OM',
    lat: 21,
    lon: 57,
    officialDesignation: {
      'de-CH': {
        'f-': 'Sultanat Oman',
      },
      'en-US': {
        'f-': 'Sultanate of Oman',
      },
      'fr-CH': {
        'f-': "Sultanat d'Oman",
      },
    },
  },
  {
    _id: '#PA',
    continent: '#NA',
    designation: {
      'de-CH': {
        'f-': 'Panama',
      },
      'en-US': {
        'f-': 'Panama',
      },
      'fr-CH': {
        'f-': 'Panama',
      },
    },
    ePoliceId: '2a895f74-e761-11e8-aea4-43375a4a09b8',
    iso3166: 'PA',
    lat: 9,
    lon: -80,
    officialDesignation: {
      'de-CH': {
        'f-': 'Republik Panama',
      },
      'en-US': {
        'f-': 'Republic of Panama',
      },
      'fr-CH': {
        'f-': 'République du Panama',
      },
    },
  },
  {
    _id: '#PE',
    continent: '#SA',
    designation: {
      'de-CH': {
        'f-': 'Peru',
      },
      'en-US': {
        'f-': 'Peru',
      },
      'fr-CH': {
        'f-': 'Pérou',
      },
    },
    ePoliceId: '2a895f76-e761-11e8-aea4-43375a4a09b8',
    iso3166: 'PE',
    lat: -10,
    lon: -76,
    officialDesignation: {
      'de-CH': {
        'f-': 'Republik Peru',
      },
      'en-US': {
        'f-': 'Republic of Peru',
      },
      'fr-CH': {
        'f-': 'République du Pérou',
      },
    },
  },
  {
    _id: '#PF',
    continent: '#OC',
    designation: {
      'de-CH': {
        'f-': 'Französisch-Polynesien',
      },
      'en-US': {
        'f-': 'French Polynesia',
      },
      'fr-CH': {
        'f-': 'Polynésie française',
      },
    },
    ePoliceId: '2a914f16-e761-11e8-aea4-43375a4a09b8',
    iso3166: 'PF',
    lat: -15,
    lon: -140,
    officialDesignation: {
      'de-CH': {
        'f-': 'Französisch-Polynesien',
      },
      'en-US': {
        'f-': 'French Polynesia',
      },
      'fr-CH': {
        'f-': 'Polynésie française',
      },
    },
  },
  {
    _id: '#PG',
    continent: '#OC',
    designation: {
      'de-CH': {
        'f-': 'Papua-Neuguinea',
      },
      'en-US': {
        'f-': 'Papua New Guinea',
      },
      'fr-CH': {
        'f-': 'Papouasie-Nouvelle-Guinée',
      },
    },
    ePoliceId: '2a914f05-e761-11e8-aea4-43375a4a09b8',
    iso3166: 'PG',
    lat: -6,
    lon: 147,
    officialDesignation: {
      'de-CH': {
        'f-': 'Unabhängige Staat Papua-Neuguinea',
      },
      'en-US': {
        'f-': 'Independent State of Papua New Guinea',
      },
      'fr-CH': {
        'f-': 'État indépendant de Papouasie-Nouvelle-Guinée',
      },
    },
  },
  {
    _id: '#PH',
    continent: '#AS',
    designation: {
      'de-CH': {
        'f-': 'Philippinen',
      },
      'en-US': {
        'f-': 'Philippines',
      },
      'fr-CH': {
        'f-': 'Philippines',
      },
    },
    ePoliceId: '2a8f7a2c-e761-11e8-aea4-43375a4a09b8',
    iso3166: 'PH',
    lat: 13,
    lon: 122,
    officialDesignation: {
      'de-CH': {
        'f-': 'Republik der Philippinen',
      },
      'en-US': {
        'f-': 'Republic of the Philippines',
      },
      'fr-CH': {
        'f-': 'République des Philippines',
      },
    },
  },
  {
    _id: '#PK',
    continent: '#AS',
    designation: {
      'de-CH': {
        'f-': 'Pakistan',
      },
      'en-US': {
        'f-': 'Pakistan',
      },
      'fr-CH': {
        'f-': 'Pakistan',
      },
    },
    ePoliceId: '2a8f531b-e761-11e8-aea4-43375a4a09b8',
    iso3166: 'PK',
    lat: 30,
    lon: 70,
    officialDesignation: {
      'de-CH': {
        'f-': 'Islamische Republik Pakistan',
      },
      'en-US': {
        'f-': 'Islamic Republic of Pakistan',
      },
      'fr-CH': {
        'f-': 'République islamique du Pakistan',
      },
    },
  },
  {
    _id: '#PL',
    continent: '#EU',
    designation: {
      'de-CH': {
        'f-': 'Polen',
      },
      'en-US': {
        'f-': 'Poland',
      },
      'fr-CH': {
        'f-': 'Pologne',
      },
    },
    ePoliceId: '2a88c3bf-e761-11e8-aea4-43375a4a09b8',
    iso3166: 'PL',
    lat: 52,
    lon: 20,
    officialDesignation: {
      'de-CH': {
        'f-': 'Republik Polen',
      },
      'en-US': {
        'f-': 'Republic of Poland',
      },
      'fr-CH': {
        'f-': 'République de Pologne',
      },
    },
  },
  {
    _id: '#PM',
    continent: '#NA',
    designation: {
      'de-CH': {
        'f-': 'Saint-Pierre und Miquelon',
      },
      'en-US': {
        'f-': 'Saint Pierre and Miquelon',
      },
      'fr-CH': {
        'f-': 'Saint-Pierre-et-Miquelon',
      },
    },
    ePoliceId: '2a895f78-e761-11e8-aea4-43375a4a09b8',
    iso3166: 'PM',
    lat: 46.83333333,
    lon: -56.33333333,
    officialDesignation: {
      'de-CH': {
        'f-': 'St. Pierre und Miquelon',
      },
      'en-US': {
        'f-': 'Saint Pierre and Miquelon',
      },
      'fr-CH': {
        'f-': 'Saint-Pierre-et-Miquelon',
      },
    },
  },
  {
    _id: '#PN',
    continent: '#OC',
    designation: {
      'de-CH': {
        'f-': 'Pitcairn',
      },
      'en-US': {
        'f-': 'Pitcairn Islands',
      },
      'fr-CH': {
        'f-': 'Îles Pitcairn',
      },
    },
    ePoliceId: '2a914f1a-e761-11e8-aea4-43375a4a09b8',
    iso3166: 'PN',
    lat: -25.06666666,
    lon: -130.1,
    officialDesignation: {
      'de-CH': {
        'f-': 'Pitcairn Inselgruppe',
      },
      'en-US': {
        'f-': 'Pitcairn Group of Islands',
      },
      'fr-CH': {
        'f-': "Groupe d'îles Pitcairn",
      },
    },
  },
  {
    _id: '#PR',
    continent: '#NA',
    designation: {
      'de-CH': {
        'f-': 'Puerto Rico',
      },
      'en-US': {
        'f-': 'Puerto Rico',
      },
      'fr-CH': {
        'f-': 'Porto Rico',
      },
    },
    ePoliceId: '2a895f77-e761-11e8-aea4-43375a4a09b8',
    iso3166: 'PR',
    lat: 18.25,
    lon: -66.5,
    officialDesignation: {
      'de-CH': {
        'f-': 'Commonwealth von Puerto Rico',
      },
      'en-US': {
        'f-': 'Commonwealth of Puerto Rico',
      },
      'fr-CH': {
        'f-': 'Porto Rico',
      },
    },
  },
  {
    _id: '#PS',
    continent: '#AS',
    designation: {
      'de-CH': {
        'f-': 'Palästina',
      },
      'en-US': {
        'f-': 'Palestine',
      },
      'fr-CH': {
        'f-': 'Palestine',
      },
    },
    ePoliceId: '2a9100d6-e761-11e8-aea4-43375a4a09b8',
    iso3166: 'PS',
    lat: 31.9,
    lon: 35.2,
    officialDesignation: {
      'de-CH': {
        'f-': 'Staat Palästina',
      },
      'en-US': {
        'f-': 'State of Palestine',
      },
      'fr-CH': {
        'f-': 'État de Palestine',
      },
    },
  },
  {
    _id: '#PT',
    continent: '#EU',
    designation: {
      'de-CH': {
        'f-': 'Portugal',
      },
      'en-US': {
        'f-': 'Portugal',
      },
      'fr-CH': {
        'f-': 'Portugal',
      },
    },
    ePoliceId: '2a88c3c0-e761-11e8-aea4-43375a4a09b8',
    iso3166: 'PT',
    lat: 39.5,
    lon: -8,
    officialDesignation: {
      'de-CH': {
        'f-': 'Portugiesische Republik',
      },
      'en-US': {
        'f-': 'Portuguese Republic',
      },
      'fr-CH': {
        'f-': 'République portugaise',
      },
    },
  },
  {
    _id: '#PW',
    continent: '#OC',
    designation: {
      'de-CH': {
        'f-': 'Palau',
      },
      'en-US': {
        'f-': 'Palau',
      },
      'fr-CH': {
        'f-': 'Palaos (Palau)',
      },
    },
    ePoliceId: '2a914f0e-e761-11e8-aea4-43375a4a09b8',
    iso3166: 'PW',
    lat: 7.5,
    lon: 134.5,
    officialDesignation: {
      'de-CH': {
        'f-': 'Palau',
      },
      'en-US': {
        'f-': 'Republic of Palau',
      },
      'fr-CH': {
        'f-': 'République des Palaos (Palau)',
      },
    },
  },
  {
    _id: '#PY',
    continent: '#SA',
    designation: {
      'de-CH': {
        'f-': 'Paraguay',
      },
      'en-US': {
        'f-': 'Paraguay',
      },
      'fr-CH': {
        'f-': 'Paraguay',
      },
    },
    ePoliceId: '2a895f75-e761-11e8-aea4-43375a4a09b8',
    iso3166: 'PY',
    lat: -23,
    lon: -58,
    officialDesignation: {
      'de-CH': {
        'f-': 'Republik Paraguay',
      },
      'en-US': {
        'f-': 'Republic of Paraguay',
      },
      'fr-CH': {
        'f-': 'République du Paraguay',
      },
    },
  },
  {
    _id: '#QA',
    continent: '#AS',
    designation: {
      'de-CH': {
        'f-': 'Katar',
      },
      'en-US': {
        'f-': 'Qatar',
      },
      'fr-CH': {
        'f-': 'Qatar',
      },
    },
    ePoliceId: '2a8d7e4f-e761-11e8-aea4-43375a4a09b8',
    iso3166: 'QA',
    lat: 25.5,
    lon: 51.25,
    officialDesignation: {
      'de-CH': {
        'f-': 'Staat Katar',
      },
      'en-US': {
        'f-': 'State of Qatar',
      },
      'fr-CH': {
        'f-': 'État du Qatar',
      },
    },
  },
  {
    _id: '#RE',
    continent: '#AF',
    designation: {
      'de-CH': {
        'f-': 'Réunion',
      },
      'en-US': {
        'f-': 'Réunion',
      },
      'fr-CH': {
        'f-': 'Réunion',
      },
    },
    ePoliceId: '2a89121f-e761-11e8-aea4-43375a4a09b8',
    iso3166: 'RE',
    lat: -21.15,
    lon: 55.5,
    officialDesignation: {
      'de-CH': {
        'f-': 'Réunion',
      },
      'en-US': {
        'f-': 'Réunion Island',
      },
      'fr-CH': {
        'f-': 'Ile de la Réunion',
      },
    },
  },
  {
    _id: '#RO',
    continent: '#EU',
    designation: {
      'de-CH': {
        'f-': 'Rumänien',
      },
      'en-US': {
        'f-': 'Romania',
      },
      'fr-CH': {
        'f-': 'Roumanie',
      },
    },
    ePoliceId: '2a88c3c1-e761-11e8-aea4-43375a4a09b8',
    iso3166: 'RO',
    lat: 46,
    lon: 25,
    officialDesignation: {
      'de-CH': {
        'f-': 'Rumänien',
      },
      'en-US': {
        'f-': 'Romania',
      },
      'fr-CH': {
        'f-': 'Roumanie',
      },
    },
  },
  {
    _id: '#RS',
    continent: '#EU',
    designation: {
      'de-CH': {
        'f-': 'Serbien',
      },
      'en-US': {
        'f-': 'Serbia',
      },
      'fr-CH': {
        'f-': 'Serbie',
      },
    },
    ePoliceId: '2a88eadb-e761-11e8-aea4-43375a4a09b8',
    iso3166: 'RS',
    lat: 44,
    lon: 21,
    officialDesignation: {
      'de-CH': {
        'f-': 'Republik Serbien',
      },
      'en-US': {
        'f-': 'Republic of Serbia',
      },
      'fr-CH': {
        'f-': 'République de Serbie',
      },
    },
  },
  {
    _id: '#RU',
    continent: '#AS',
    designation: {
      'de-CH': {
        'f-': 'Russland',
      },
      'en-US': {
        'f-': 'Russia',
      },
      'fr-CH': {
        'f-': 'Russie',
      },
    },
    ePoliceId: '2a88eae8-e761-11e8-aea4-43375a4a09b8',
    iso3166: 'RU',
    lat: 60,
    lon: 100,
    officialDesignation: {
      'de-CH': {
        'f-': 'Russische Föderation',
      },
      'en-US': {
        'f-': 'Russian Federation',
      },
      'fr-CH': {
        'f-': 'Fédération de Russie',
      },
    },
  },
  {
    _id: '#RW',
    continent: '#AF',
    designation: {
      'de-CH': {
        'f-': 'Ruanda',
      },
      'en-US': {
        'f-': 'Rwanda',
      },
      'fr-CH': {
        'f-': 'Rwanda',
      },
    },
    ePoliceId: '2a891221-e761-11e8-aea4-43375a4a09b8',
    iso3166: 'RW',
    lat: -2,
    lon: 30,
    officialDesignation: {
      'de-CH': {
        'f-': 'Republik Ruanda',
      },
      'en-US': {
        'f-': 'Republic of Rwanda',
      },
      'fr-CH': {
        'f-': 'République rwandaise',
      },
    },
  },
  {
    _id: '#SA',
    continent: '#AS',
    designation: {
      'de-CH': {
        'f-': 'Saudi-Arabien',
      },
      'en-US': {
        'f-': 'Saudi Arabia',
      },
      'fr-CH': {
        'f-': 'Arabie Saoudite',
      },
    },
    ePoliceId: '2a8fa13d-e761-11e8-aea4-43375a4a09b8',
    iso3166: 'SA',
    lat: 25,
    lon: 45,
    officialDesignation: {
      'de-CH': {
        'f-': 'Königreich Saudi-Arabien',
      },
      'en-US': {
        'f-': 'Kingdom of Saudi Arabia',
      },
      'fr-CH': {
        'f-': "Royaume d'Arabie Saoudite",
      },
    },
  },
  {
    _id: '#SB',
    continent: '#OC',
    designation: {
      'de-CH': {
        'f-': 'Salomonen',
      },
      'en-US': {
        'f-': 'Solomon Islands',
      },
      'fr-CH': {
        'f-': 'Îles Salomon',
      },
    },
    ePoliceId: '2a914f09-e761-11e8-aea4-43375a4a09b8',
    iso3166: 'SB',
    lat: -8,
    lon: 159,
    officialDesignation: {
      'de-CH': {
        'f-': 'Salomon-Inseln',
      },
      'en-US': {
        'f-': 'Solomon Islands',
      },
      'fr-CH': {
        'f-': 'Îles Salomon',
      },
    },
  },
  {
    _id: '#SC',
    continent: '#AF',
    designation: {
      'de-CH': {
        'f-': 'Seychellen',
      },
      'en-US': {
        'f-': 'Seychelles',
      },
      'fr-CH': {
        'f-': 'Seychelles',
      },
    },
    ePoliceId: '2a891225-e761-11e8-aea4-43375a4a09b8',
    iso3166: 'SC',
    lat: -4.58333333,
    lon: 55.66666666,
    officialDesignation: {
      'de-CH': {
        'f-': 'Republik der Seychellen',
      },
      'en-US': {
        'f-': 'Republic of Seychelles',
      },
      'fr-CH': {
        'f-': 'République des Seychelles',
      },
    },
  },
  {
    _id: '#SD',
    continent: '#AF',
    designation: {
      'de-CH': {
        'f-': 'Sudan',
      },
      'en-US': {
        'f-': 'Sudan',
      },
      'fr-CH': {
        'f-': 'Soudan',
      },
    },
    ePoliceId: '2a891229-e761-11e8-aea4-43375a4a09b8',
    iso3166: 'SD',
    lat: 15,
    lon: 30,
    officialDesignation: {
      'de-CH': {
        'f-': 'Republik Sudan',
      },
      'en-US': {
        'f-': 'Republic of the Sudan',
      },
      'fr-CH': {
        'f-': 'République du Soudan',
      },
    },
  },
  {
    _id: '#SE',
    continent: '#EU',
    designation: {
      'de-CH': {
        'f-': 'Schweden',
      },
      'en-US': {
        'f-': 'Sweden',
      },
      'fr-CH': {
        'f-': 'Suède',
      },
    },
    ePoliceId: '2a88c3c3-e761-11e8-aea4-43375a4a09b8',
    iso3166: 'SE',
    lat: 62,
    lon: 15,
    officialDesignation: {
      'de-CH': {
        'f-': 'Königreich Schweden',
      },
      'en-US': {
        'f-': 'Kingdom of Sweden',
      },
      'fr-CH': {
        'f-': 'Royaume de Suède',
      },
    },
  },
  {
    _id: '#SG',
    continent: '#AS',
    designation: {
      'de-CH': {
        'f-': 'Singapur',
      },
      'en-US': {
        'f-': 'Singapore',
      },
      'fr-CH': {
        'f-': 'Singapour',
      },
    },
    ePoliceId: '2a8fc84e-e761-11e8-aea4-43375a4a09b8',
    iso3166: 'SG',
    lat: 1.36666666,
    lon: 103.8,
    officialDesignation: {
      'de-CH': {
        'f-': 'Republik Singapur',
      },
      'en-US': {
        'f-': 'Republic of Singapore',
      },
      'fr-CH': {
        'f-': 'République de Singapour',
      },
    },
  },
  {
    _id: '#SI',
    continent: '#EU',
    designation: {
      'de-CH': {
        'f-': 'Slowenien',
      },
      'en-US': {
        'f-': 'Slovenia',
      },
      'fr-CH': {
        'f-': 'Slovénie',
      },
    },
    ePoliceId: '2a88eade-e761-11e8-aea4-43375a4a09b8',
    iso3166: 'SI',
    lat: 46.11666666,
    lon: 14.81666666,
    officialDesignation: {
      'de-CH': {
        'f-': 'Republik Slowenien',
      },
      'en-US': {
        'f-': 'Republic of Slovenia',
      },
      'fr-CH': {
        'f-': 'République de Slovénie',
      },
    },
  },
  {
    _id: '#SJ',
    continent: '#EU',
    designation: {
      'de-CH': {
        'f-': 'Spitzbergen',
      },
      'en-US': {
        'f-': 'Svalbard and Jan Mayen',
      },
      'fr-CH': {
        'f-': 'Svalbard et Jan Mayen',
      },
    },
    iso3166: 'SJ',
    lat: 78,
    lon: 20,
    officialDesignation: {
      'de-CH': {
        'f-': 'Inselgruppe Spitzbergen',
      },
      'en-US': {
        'f-': 'Svalbard og Jan Mayen',
      },
      'fr-CH': {
        'f-': 'Jan Mayen Svalbard',
      },
    },
  },
  {
    _id: '#SK',
    continent: '#EU',
    designation: {
      'de-CH': {
        'f-': 'Slowakei',
      },
      'en-US': {
        'f-': 'Slovakia',
      },
      'fr-CH': {
        'f-': 'Slovaquie',
      },
    },
    ePoliceId: '2a88ead9-e761-11e8-aea4-43375a4a09b8',
    iso3166: 'SK',
    lat: 48.66666666,
    lon: 19.5,
    officialDesignation: {
      'de-CH': {
        'f-': 'Slowakische Republik',
      },
      'en-US': {
        'f-': 'Slovak Republic',
      },
      'fr-CH': {
        'f-': 'République slovaque',
      },
    },
  },
  {
    _id: '#SL',
    continent: '#AF',
    designation: {
      'de-CH': {
        'f-': 'Sierra Leone',
      },
      'en-US': {
        'f-': 'Sierra Leone',
      },
      'fr-CH': {
        'f-': 'Sierra Leone',
      },
    },
    ePoliceId: '2a891226-e761-11e8-aea4-43375a4a09b8',
    iso3166: 'SL',
    lat: 8.5,
    lon: -11.5,
    officialDesignation: {
      'de-CH': {
        'f-': 'Republik Sierra Leone',
      },
      'en-US': {
        'f-': 'Republic of Sierra Leone',
      },
      'fr-CH': {
        'f-': 'République de Sierra Leone',
      },
    },
  },
  {
    _id: '#SM',
    continent: '#EU',
    designation: {
      'de-CH': {
        'f-': 'San Marino',
      },
      'en-US': {
        'f-': 'San Marino',
      },
      'fr-CH': {
        'f-': 'Saint-Marin',
      },
    },
    ePoliceId: '2a88c3c2-e761-11e8-aea4-43375a4a09b8',
    iso3166: 'SM',
    lat: 43.76666666,
    lon: 12.41666666,
    officialDesignation: {
      'de-CH': {
        'f-': 'Republik San Marino',
      },
      'en-US': {
        'f-': 'Most Serene Republic of San Marino',
      },
      'fr-CH': {
        'f-': 'République de Saint-Marin',
      },
    },
  },
  {
    _id: '#SN',
    continent: '#AF',
    designation: {
      'de-CH': {
        'f-': 'Senegal',
      },
      'en-US': {
        'f-': 'Senegal',
      },
      'fr-CH': {
        'f-': 'Sénégal',
      },
    },
    ePoliceId: '2a891224-e761-11e8-aea4-43375a4a09b8',
    iso3166: 'SN',
    lat: 14,
    lon: -14,
    officialDesignation: {
      'de-CH': {
        'f-': 'Republik Senegal',
      },
      'en-US': {
        'f-': 'Republic of Senegal',
      },
      'fr-CH': {
        'f-': 'République du Sénégal',
      },
    },
  },
  {
    _id: '#SO',
    continent: '#AF',
    designation: {
      'de-CH': {
        'f-': 'Somalia',
      },
      'en-US': {
        'f-': 'Somalia',
      },
      'fr-CH': {
        'f-': 'Somalie',
      },
    },
    ePoliceId: '2a891227-e761-11e8-aea4-43375a4a09b8',
    iso3166: 'SO',
    lat: 10,
    lon: 49,
    officialDesignation: {
      'de-CH': {
        'f-': 'Bundesrepublik Somalia',
      },
      'en-US': {
        'f-': 'Federal Republic of Somalia',
      },
      'fr-CH': {
        'f-': 'République fédérale de Somalie',
      },
    },
  },
  {
    _id: '#SR',
    continent: '#SA',
    designation: {
      'de-CH': {
        'f-': 'Suriname',
      },
      'en-US': {
        'f-': 'Suriname',
      },
      'fr-CH': {
        'f-': 'Surinam',
      },
    },
    ePoliceId: '2a895f79-e761-11e8-aea4-43375a4a09b8',
    iso3166: 'SR',
    lat: 4,
    lon: -56,
    officialDesignation: {
      'de-CH': {
        'f-': 'Republik Suriname',
      },
      'en-US': {
        'f-': 'Republic of Suriname',
      },
      'fr-CH': {
        'f-': 'République du Suriname',
      },
    },
  },
  {
    _id: '#SS',
    continent: '#AF',
    designation: {
      'de-CH': {
        'f-': 'Südsudan',
      },
      'en-US': {
        'f-': 'South Sudan',
      },
      'fr-CH': {
        'f-': 'Soudan du Sud',
      },
    },
    ePoliceId: '2a893945-e761-11e8-aea4-43375a4a09b8',
    iso3166: 'SS',
    lat: 7,
    lon: 30,
    officialDesignation: {
      'de-CH': {
        'f-': 'Republik Südsudan',
      },
      'en-US': {
        'f-': 'Republic of South Sudan',
      },
      'fr-CH': {
        'f-': 'République du Soudan du Sud',
      },
    },
  },
  {
    _id: '#ST',
    continent: '#AF',
    designation: {
      'de-CH': {
        'f-': 'São Tomé und Príncipe',
      },
      'en-US': {
        'f-': 'São Tomé and Príncipe',
      },
      'fr-CH': {
        'f-': 'São Tomé et Príncipe',
      },
    },
    ePoliceId: '2a891223-e761-11e8-aea4-43375a4a09b8',
    iso3166: 'ST',
    lat: 1,
    lon: 7,
    officialDesignation: {
      'de-CH': {
        'f-': 'Demokratische Republik São Tomé und Príncipe',
      },
      'en-US': {
        'f-': 'Democratic Republic of São Tomé and Príncipe',
      },
      'fr-CH': {
        'f-': 'République démocratique de São Tomé et Príncipe',
      },
    },
  },
  {
    _id: '#SV',
    continent: '#NA',
    designation: {
      'de-CH': {
        'f-': 'El Salvador',
      },
      'en-US': {
        'f-': 'El Salvador',
      },
      'fr-CH': {
        'f-': 'Salvador',
      },
    },
    ePoliceId: '2a893953-e761-11e8-aea4-43375a4a09b8',
    iso3166: 'SV',
    lat: 13.83333333,
    lon: -88.91666666,
    officialDesignation: {
      'de-CH': {
        'f-': 'Republik El Salvador',
      },
      'en-US': {
        'f-': 'Republic of El Salvador',
      },
      'fr-CH': {
        'f-': 'République du Salvador',
      },
    },
  },
  {
    _id: '#SX',
    continent: '#NA',
    designation: {
      'de-CH': {
        'f-': 'Sint Maarten',
      },
      'en-US': {
        'f-': 'Sint Maarten',
      },
      'fr-CH': {
        'f-': 'Saint-Martin',
      },
    },
    iso3166: 'SX',
    lat: 18.033333,
    lon: -63.05,
    officialDesignation: {
      'de-CH': {
        'f-': 'Sint Maarten',
      },
      'en-US': {
        'f-': 'Sint Maarten',
      },
      'fr-CH': {
        'f-': 'Sint Maarten',
      },
    },
  },
  {
    _id: '#SY',
    continent: '#AS',
    designation: {
      'de-CH': {
        'f-': 'Syrien',
      },
      'en-US': {
        'f-': 'Syria',
      },
      'fr-CH': {
        'f-': 'Syrie',
      },
    },
    ePoliceId: '2a901670-e761-11e8-aea4-43375a4a09b8',
    iso3166: 'SY',
    lat: 35,
    lon: 38,
    officialDesignation: {
      'de-CH': {
        'f-': 'Arabische Republik Syrien',
      },
      'en-US': {
        'f-': 'Syrian Arab Republic',
      },
      'fr-CH': {
        'f-': 'République arabe syrienne',
      },
    },
  },
  {
    _id: '#SZ',
    continent: '#AF',
    designation: {
      'de-CH': {
        'f-': 'Eswatini',
      },
      'en-US': {
        'f-': 'Eswatini',
      },
      'fr-CH': {
        'f-': 'Eswatini',
      },
    },
    ePoliceId: '2a89122b-e761-11e8-aea4-43375a4a09b8',
    iso3166: 'SZ',
    lat: -26.5,
    lon: 31.5,
    officialDesignation: {
      'de-CH': {
        'f-': 'Königreich Eswatini',
      },
      'en-US': {
        'f-': 'Kingdom of Eswatini',
      },
      'fr-CH': {
        'f-': 'Royaume du Eswatini',
      },
    },
  },
  {
    _id: '#TC',
    continent: '#NA',
    designation: {
      'de-CH': {
        'f-': 'Turks-und Caicosinseln',
      },
      'en-US': {
        'f-': 'Turks and Caicos Islands',
      },
      'fr-CH': {
        'f-': 'Îles Turques-et-Caïques',
      },
    },
    ePoliceId: '2a914f12-e761-11e8-aea4-43375a4a09b8',
    iso3166: 'TC',
    lat: 21.75,
    lon: -71.58333333,
    officialDesignation: {
      'de-CH': {
        'f-': 'Turks und Caicos Inseln',
      },
      'en-US': {
        'f-': 'Turks and Caicos Islands',
      },
      'fr-CH': {
        'f-': 'Îles Turques et Caïques',
      },
    },
  },
  {
    _id: '#TD',
    continent: '#AF',
    designation: {
      'de-CH': {
        'f-': 'Tschad',
      },
      'en-US': {
        'f-': 'Chad',
      },
      'fr-CH': {
        'f-': 'Tchad',
      },
    },
    ePoliceId: '2a89393e-e761-11e8-aea4-43375a4a09b8',
    iso3166: 'TD',
    lat: 15,
    lon: 19,
    officialDesignation: {
      'de-CH': {
        'f-': 'Republik Tschad',
      },
      'en-US': {
        'f-': 'Republic of Chad',
      },
      'fr-CH': {
        'f-': 'République du Tchad',
      },
    },
  },
  {
    _id: '#TF',
    continent: '#AF',
    designation: {
      'de-CH': {
        'f-': 'Französische Süd-und Antarktisgebiete',
      },
      'en-US': {
        'f-': 'French Southern and Antarctic Lands',
      },
      'fr-CH': {
        'f-': 'Terres australes et antarctiques françaises',
      },
    },
    iso3166: 'TF',
    lat: -49.25,
    lon: 69.167,
    officialDesignation: {
      'de-CH': {
        'f-': 'Gebiet der Französisch Süd-und Antarktisgebiete',
      },
      'en-US': {
        'f-': 'Territory of the French Southern and Antarctic Lands',
      },
      'fr-CH': {
        'f-': 'Territoire des Terres australes et antarctiques françaises',
      },
    },
  },
  {
    _id: '#TG',
    continent: '#AF',
    designation: {
      'de-CH': {
        'f-': 'Togo',
      },
      'en-US': {
        'f-': 'Togo',
      },
      'fr-CH': {
        'f-': 'Togo',
      },
    },
    ePoliceId: '2a89122d-e761-11e8-aea4-43375a4a09b8',
    iso3166: 'TG',
    lat: 8,
    lon: 1.16666666,
    officialDesignation: {
      'de-CH': {
        'f-': 'Republik Togo',
      },
      'en-US': {
        'f-': 'Togolese Republic',
      },
      'fr-CH': {
        'f-': 'République togolaise',
      },
    },
  },
  {
    _id: '#TH',
    continent: '#AS',
    designation: {
      'de-CH': {
        'f-': 'Thailand',
      },
      'en-US': {
        'f-': 'Thailand',
      },
      'fr-CH': {
        'f-': 'Thaïlande',
      },
    },
    ePoliceId: '2a903d81-e761-11e8-aea4-43375a4a09b8',
    iso3166: 'TH',
    lat: 15,
    lon: 100,
    officialDesignation: {
      'de-CH': {
        'f-': 'Königreich Thailand',
      },
      'en-US': {
        'f-': 'Kingdom of Thailand',
      },
      'fr-CH': {
        'f-': 'Royaume de Thaïlande',
      },
    },
  },
  {
    _id: '#TJ',
    continent: '#AS',
    designation: {
      'de-CH': {
        'f-': 'Tadschikistan',
      },
      'en-US': {
        'f-': 'Tajikistan',
      },
      'fr-CH': {
        'f-': 'Tadjikistan',
      },
    },
    ePoliceId: '2a9127ec-e761-11e8-aea4-43375a4a09b8',
    iso3166: 'TJ',
    lat: 39,
    lon: 71,
    officialDesignation: {
      'de-CH': {
        'f-': 'Republik Tadschikistan',
      },
      'en-US': {
        'f-': 'Republic of Tajikistan',
      },
      'fr-CH': {
        'f-': 'République du Tadjikistan',
      },
    },
  },
  {
    _id: '#TK',
    continent: '#OC',
    designation: {
      'de-CH': {
        'f-': 'Tokelau',
      },
      'en-US': {
        'f-': 'Tokelau',
      },
      'fr-CH': {
        'f-': 'Tokelau',
      },
    },
    ePoliceId: '2a914f19-e761-11e8-aea4-43375a4a09b8',
    iso3166: 'TK',
    lat: -9,
    lon: -172,
    officialDesignation: {
      'de-CH': {
        'f-': 'Tokelau',
      },
      'en-US': {
        'f-': 'Tokelau',
      },
      'fr-CH': {
        'f-': 'Îles Tokelau',
      },
    },
  },
  {
    _id: '#TL',
    continent: '#AS',
    designation: {
      'de-CH': {
        'f-': 'Timor-Leste',
      },
      'en-US': {
        'f-': 'Timor-Leste',
      },
      'fr-CH': {
        'f-': 'Timor oriental',
      },
    },
    ePoliceId: '2a90d9c5-e761-11e8-aea4-43375a4a09b8',
    iso3166: 'TL',
    lat: -8.83333333,
    lon: 125.91666666,
    officialDesignation: {
      'de-CH': {
        'f-': 'Demokratische Republik Timor-Leste',
      },
      'en-US': {
        'f-': 'Democratic Republic of Timor-Leste',
      },
      'fr-CH': {
        'f-': 'République démocratique du Timor oriental',
      },
    },
  },
  {
    _id: '#TM',
    continent: '#AS',
    designation: {
      'de-CH': {
        'f-': 'Turkmenistan',
      },
      'en-US': {
        'f-': 'Turkmenistan',
      },
      'fr-CH': {
        'f-': 'Turkménistan',
      },
    },
    ePoliceId: '2a9127ed-e761-11e8-aea4-43375a4a09b8',
    iso3166: 'TM',
    lat: 40,
    lon: 60,
    officialDesignation: {
      'de-CH': {
        'f-': 'Turkmenistan',
      },
      'en-US': {
        'f-': 'Turkmenistan',
      },
      'fr-CH': {
        'f-': 'Turkménistan',
      },
    },
  },
  {
    _id: '#TN',
    continent: '#AF',
    designation: {
      'de-CH': {
        'f-': 'Tunesien',
      },
      'en-US': {
        'f-': 'Tunisia',
      },
      'fr-CH': {
        'f-': 'Tunisie',
      },
    },
    ePoliceId: '2a89393f-e761-11e8-aea4-43375a4a09b8',
    iso3166: 'TN',
    lat: 34,
    lon: 9,
    officialDesignation: {
      'de-CH': {
        'f-': 'Tunesische Republik',
      },
      'en-US': {
        'f-': 'Tunisian Republic',
      },
      'fr-CH': {
        'f-': 'République tunisienne',
      },
    },
  },
  {
    _id: '#TO',
    continent: '#OC',
    designation: {
      'de-CH': {
        'f-': 'Tonga',
      },
      'en-US': {
        'f-': 'Tonga',
      },
      'fr-CH': {
        'f-': 'Tonga',
      },
    },
    ePoliceId: '2a914f06-e761-11e8-aea4-43375a4a09b8',
    iso3166: 'TO',
    lat: -20,
    lon: -175,
    officialDesignation: {
      'de-CH': {
        'f-': 'Königreich Tonga',
      },
      'en-US': {
        'f-': 'Kingdom of Tonga',
      },
      'fr-CH': {
        'f-': 'Royaume des Tonga',
      },
    },
  },
  {
    _id: '#TR',
    continent: '#AS',
    designation: {
      'de-CH': {
        'f-': 'Türkei',
      },
      'en-US': {
        'f-': 'Turkey',
      },
      'fr-CH': {
        'f-': 'Turquie',
      },
    },
    ePoliceId: '2a88c3c5-e761-11e8-aea4-43375a4a09b8',
    iso3166: 'TR',
    lat: 39,
    lon: 35,
    officialDesignation: {
      'de-CH': {
        'f-': 'Republik Türkei',
      },
      'en-US': {
        'f-': 'Republic of Turkey',
      },
      'fr-CH': {
        'f-': 'République de Turquie',
      },
    },
  },
  {
    _id: '#TT',
    continent: '#NA',
    designation: {
      'de-CH': {
        'f-': 'Trinidad und Tobago',
      },
      'en-US': {
        'f-': 'Trinidad and Tobago',
      },
      'fr-CH': {
        'f-': 'Trinité-et-Tobago',
      },
    },
    ePoliceId: '2a895f7a-e761-11e8-aea4-43375a4a09b8',
    iso3166: 'TT',
    lat: 11,
    lon: -61,
    officialDesignation: {
      'de-CH': {
        'f-': 'Republik Trinidad und Tobago',
      },
      'en-US': {
        'f-': 'Republic of Trinidad and Tobago',
      },
      'fr-CH': {
        'f-': 'République de Trinité-et-Tobago',
      },
    },
  },
  {
    _id: '#TV',
    continent: '#OC',
    designation: {
      'de-CH': {
        'f-': 'Tuvalu',
      },
      'en-US': {
        'f-': 'Tuvalu',
      },
      'fr-CH': {
        'f-': 'Tuvalu',
      },
    },
    ePoliceId: '2a914f0a-e761-11e8-aea4-43375a4a09b8',
    iso3166: 'TV',
    lat: -8,
    lon: 178,
    officialDesignation: {
      'de-CH': {
        'f-': 'Tuvalu',
      },
      'en-US': {
        'f-': 'Tuvalu',
      },
      'fr-CH': {
        'f-': 'Tuvalu',
      },
    },
  },
  {
    _id: '#TW',
    continent: '#AS',
    designation: {
      'de-CH': {
        'f-': 'Taiwan',
      },
      'en-US': {
        'f-': 'Taiwan',
      },
      'fr-CH': {
        'f-': 'Taïwan',
      },
    },
    ePoliceId: '2a8ba983-e761-11e8-aea4-43375a4a09b8',
    iso3166: 'TW',
    lat: 23.5,
    lon: 121,
    officialDesignation: {
      'de-CH': {
        'f-': 'Republik China (Taiwan)',
      },
      'en-US': {
        'f-': 'Republic of China (Taiwan)',
      },
      'fr-CH': {
        'f-': 'République de Chine (Taïwan)',
      },
    },
  },
  {
    _id: '#TZ',
    continent: '#AF',
    designation: {
      'de-CH': {
        'f-': 'Tansania',
      },
      'en-US': {
        'f-': 'Tanzania',
      },
      'fr-CH': {
        'f-': 'Tanzanie',
      },
    },
    ePoliceId: '2a89122c-e761-11e8-aea4-43375a4a09b8',
    iso3166: 'TZ',
    lat: -6,
    lon: 35,
    officialDesignation: {
      'de-CH': {
        'f-': 'Vereinigte Republik Tansania',
      },
      'en-US': {
        'f-': 'United Republic of Tanzania',
      },
      'fr-CH': {
        'f-': 'République -Unie de Tanzanie',
      },
    },
  },
  {
    _id: '#UA',
    continent: '#EU',
    designation: {
      'de-CH': {
        'f-': 'Ukraine',
      },
      'en-US': {
        'f-': 'Ukraine',
      },
      'fr-CH': {
        'f-': 'Ukraine',
      },
    },
    ePoliceId: '2a88eae9-e761-11e8-aea4-43375a4a09b8',
    iso3166: 'UA',
    lat: 49,
    lon: 32,
    officialDesignation: {
      'de-CH': {
        'f-': 'Ukraine',
      },
      'en-US': {
        'f-': 'Ukraine',
      },
      'fr-CH': {
        'f-': 'Ukraine',
      },
    },
  },
  {
    _id: '#UG',
    continent: '#AF',
    designation: {
      'de-CH': {
        'f-': 'Uganda',
      },
      'en-US': {
        'f-': 'Uganda',
      },
      'fr-CH': {
        'f-': 'Ouganda',
      },
    },
    ePoliceId: '2a893940-e761-11e8-aea4-43375a4a09b8',
    iso3166: 'UG',
    lat: 1,
    lon: 32,
    officialDesignation: {
      'de-CH': {
        'f-': 'Republik Uganda',
      },
      'en-US': {
        'f-': 'Republic of Uganda',
      },
      'fr-CH': {
        'f-': "République de l'Ouganda",
      },
    },
  },
  {
    _id: '#UM',
    continent: '#NA',
    designation: {
      'de-CH': {
        'f-': 'Kleinere Inselbesitzungen der Vereinigten Staaten',
      },
      'en-US': {
        'f-': 'United States Minor Outlying Islands',
      },
      'fr-CH': {
        'f-': 'Îles mineures éloignées des États-Unis',
      },
    },
    iso3166: 'UM',
    officialDesignation: {
      'de-CH': {
        'f-': 'USA, kleinere ausgelagerte Inseln',
      },
      'en-US': {
        'f-': 'United States Minor Outlying Islands',
      },
      'fr-CH': {
        'f-': 'Îles mineures éloignées des États-Unis',
      },
    },
  },
  {
    _id: '#US',
    continent: '#NA',
    designation: {
      'de-CH': {
        'f-': 'Vereinigte Staaten von Amerika (USA)',
      },
      'en-US': {
        'f-': 'United States',
      },
      'fr-CH': {
        'f-': 'États-Unis',
      },
    },
    ePoliceId: '2a895f7d-e761-11e8-aea4-43375a4a09b8',
    iso3166: 'US',
    lat: 38,
    lon: -97,
    officialDesignation: {
      'de-CH': {
        'f-': 'Vereinigte Staaten von Amerika',
      },
      'en-US': {
        'f-': 'United States of America',
      },
      'fr-CH': {
        'f-': "Les états-unis d'Amérique",
      },
    },
  },
  {
    _id: '#UY',
    continent: '#SA',
    designation: {
      'de-CH': {
        'f-': 'Uruguay',
      },
      'en-US': {
        'f-': 'Uruguay',
      },
      'fr-CH': {
        'f-': 'Uruguay',
      },
    },
    ePoliceId: '2a895f7b-e761-11e8-aea4-43375a4a09b8',
    iso3166: 'UY',
    lat: -33,
    lon: -56,
    officialDesignation: {
      'de-CH': {
        'f-': 'Republik Östlich des Uruguay',
      },
      'en-US': {
        'f-': 'Oriental Republic of Uruguay',
      },
      'fr-CH': {
        'f-': "République orientale de l'Uruguay",
      },
    },
  },
  {
    _id: '#UZ',
    continent: '#AS',
    designation: {
      'de-CH': {
        'f-': 'Usbekistan',
      },
      'en-US': {
        'f-': 'Uzbekistan',
      },
      'fr-CH': {
        'f-': 'Ouzbékistan',
      },
    },
    ePoliceId: '2a9127ee-e761-11e8-aea4-43375a4a09b8',
    iso3166: 'UZ',
    lat: 41,
    lon: 64,
    officialDesignation: {
      'de-CH': {
        'f-': 'Republik Usbekistan',
      },
      'en-US': {
        'f-': 'Republic of Uzbekistan',
      },
      'fr-CH': {
        'f-': "République d'Ouzbékistan",
      },
    },
  },
  {
    _id: '#VA',
    continent: '#EU',
    designation: {
      'de-CH': {
        'f-': 'Vatikanstadt',
      },
      'en-US': {
        'f-': 'Vatican City',
      },
      'fr-CH': {
        'f-': 'Cité du Vatican',
      },
    },
    ePoliceId: '2a88c3c7-e761-11e8-aea4-43375a4a09b8',
    iso3166: 'VA',
    lat: 41.9,
    lon: 12.45,
    officialDesignation: {
      'de-CH': {
        'f-': 'Staat Vatikanstadt',
      },
      'en-US': {
        'f-': 'Vatican City State',
      },
      'fr-CH': {
        'f-': 'Cité du Vatican',
      },
    },
  },
  {
    _id: '#VC',
    continent: '#NA',
    designation: {
      'de-CH': {
        'f-': 'Saint Vincent und die Grenadinen',
      },
      'en-US': {
        'f-': 'Saint Vincent and the Grenadines',
      },
      'fr-CH': {
        'f-': 'Saint-Vincent-et-les-Grenadines',
      },
    },
    ePoliceId: '2a895f82-e761-11e8-aea4-43375a4a09b8',
    iso3166: 'VC',
    lat: 13.25,
    lon: -61.2,
    officialDesignation: {
      'de-CH': {
        'f-': 'St. Vincent und die Grenadinen',
      },
      'en-US': {
        'f-': 'Saint Vincent and the Grenadines',
      },
      'fr-CH': {
        'f-': 'Saint-Vincent-et-les Grenadines',
      },
    },
  },
  {
    _id: '#VE',
    continent: '#SA',
    designation: {
      'de-CH': {
        'f-': 'Venezuela',
      },
      'en-US': {
        'f-': 'Venezuela',
      },
      'fr-CH': {
        'f-': 'Venezuela',
      },
    },
    ePoliceId: '2a895f7c-e761-11e8-aea4-43375a4a09b8',
    iso3166: 'VE',
    lat: 8,
    lon: -66,
    officialDesignation: {
      'de-CH': {
        'f-': 'Bolivarische Republik Venezuela',
      },
      'en-US': {
        'f-': 'Bolivarian Republic of Venezuela',
      },
      'fr-CH': {
        'f-': 'République bolivarienne du Venezuela',
      },
    },
  },
  {
    _id: '#VG',
    continent: '#NA',
    designation: {
      'de-CH': {
        'f-': 'Britische Jungferninseln',
      },
      'en-US': {
        'f-': 'British Virgin Islands',
      },
      'fr-CH': {
        'f-': 'Îles Vierges britanniques',
      },
    },
    ePoliceId: '2a898695-e761-11e8-aea4-43375a4a09b8',
    iso3166: 'VG',
    lat: 18.431383,
    lon: -64.62305,
    officialDesignation: {
      'de-CH': {
        'f-': 'Jungferninseln',
      },
      'en-US': {
        'f-': 'Virgin Islands',
      },
      'fr-CH': {
        'f-': 'îles Vierges',
      },
    },
  },
  {
    _id: '#VI',
    continent: '#NA',
    designation: {
      'de-CH': {
        'f-': 'Amerikanische Jungferninseln',
      },
      'en-US': {
        'f-': 'United States Virgin Islands',
      },
      'fr-CH': {
        'f-': 'Îles Vierges des États-Unis',
      },
    },
    iso3166: 'VI',
    lat: 18.35,
    lon: -64.933333,
    officialDesignation: {
      'de-CH': {
        'f-': 'Jungferninseln der Vereinigten Staaten',
      },
      'en-US': {
        'f-': 'Virgin Islands of the United States',
      },
      'fr-CH': {
        'f-': 'Îles Vierges des États-Unis',
      },
    },
  },
  {
    _id: '#VN',
    continent: '#AS',
    designation: {
      'de-CH': {
        'f-': 'Vietnam',
      },
      'en-US': {
        'f-': 'Vietnam',
      },
      'fr-CH': {
        'f-': 'Viêt Nam',
      },
    },
    ePoliceId: '2a908ba3-e761-11e8-aea4-43375a4a09b8',
    iso3166: 'VN',
    lat: 16.16666666,
    lon: 107.83333333,
    officialDesignation: {
      'de-CH': {
        'f-': 'Sozialistische Republik Vietnam',
      },
      'en-US': {
        'f-': 'Socialist Republic of Vietnam',
      },
      'fr-CH': {
        'f-': 'République socialiste du Viêt Nam',
      },
    },
  },
  {
    _id: '#VU',
    continent: '#OC',
    designation: {
      'de-CH': {
        'f-': 'Vanuatu',
      },
      'en-US': {
        'f-': 'Vanuatu',
      },
      'fr-CH': {
        'f-': 'Vanuatu',
      },
    },
    ePoliceId: '2a914f02-e761-11e8-aea4-43375a4a09b8',
    iso3166: 'VU',
    lat: -16,
    lon: 167,
    officialDesignation: {
      'de-CH': {
        'f-': 'Vanuatu',
      },
      'en-US': {
        'f-': 'Republic of Vanuatu',
      },
      'fr-CH': {
        'f-': 'République de Vanuatu',
      },
    },
  },
  {
    _id: '#WF',
    continent: '#OC',
    designation: {
      'de-CH': {
        'f-': 'Wallis und Futuna',
      },
      'en-US': {
        'f-': 'Wallis and Futuna',
      },
      'fr-CH': {
        'f-': 'Wallis-et-Futuna',
      },
    },
    ePoliceId: '2a914f07-e761-11e8-aea4-43375a4a09b8',
    iso3166: 'WF',
    lat: -13.3,
    lon: -176.2,
    officialDesignation: {
      'de-CH': {
        'f-': 'Gebiet der Wallis und Futuna',
      },
      'en-US': {
        'f-': 'Territory of the Wallis and Futuna Islands',
      },
      'fr-CH': {
        'f-': 'Territoire des îles Wallis et Futuna',
      },
    },
  },
  {
    _id: '#WS',
    continent: '#OC',
    designation: {
      'de-CH': {
        'f-': 'Samoa',
      },
      'en-US': {
        'f-': 'Samoa',
      },
      'fr-CH': {
        'f-': 'Samoa',
      },
    },
    ePoliceId: '2a914f08-e761-11e8-aea4-43375a4a09b8',
    iso3166: 'WS',
    lat: -13.58333333,
    lon: -172.33333333,
    officialDesignation: {
      'de-CH': {
        'f-': 'Unabhängige Staat Samoa',
      },
      'en-US': {
        'f-': 'Independent State of Samoa',
      },
      'fr-CH': {
        'f-': 'Samoa',
      },
    },
  },
  {
    _id: '#XK',
    continent: '#EU',
    designation: {
      'de-CH': {
        'f-': 'Kosovo',
      },
      'en-US': {
        'f-': 'Kosovo',
      },
      'fr-CH': {
        'f-': 'Kosovo',
      },
    },
    ePoliceId: '2a88eae3-e761-11e8-aea4-43375a4a09b8',
    iso3166: 'XK',
    lat: 42.666667,
    lon: 21.166667,
    officialDesignation: {
      'de-CH': {
        'f-': 'Republik Kosovo',
      },
      'en-US': {
        'f-': 'Republic of Kosovo',
      },
      'fr-CH': {
        'f-': 'République du Kosovo',
      },
    },
  },
  {
    _id: '#YE',
    continent: '#AS',
    designation: {
      'de-CH': {
        'f-': 'Jemen',
      },
      'en-US': {
        'f-': 'Yemen',
      },
      'fr-CH': {
        'f-': 'Yémen',
      },
    },
    ePoliceId: '2a8d091c-e761-11e8-aea4-43375a4a09b8',
    iso3166: 'YE',
    lat: 15,
    lon: 48,
    officialDesignation: {
      'de-CH': {
        'f-': 'Republik Jemen',
      },
      'en-US': {
        'f-': 'Republic of Yemen',
      },
      'fr-CH': {
        'f-': 'République du Yémen',
      },
    },
  },
  {
    _id: '#YT',
    continent: '#AF',
    designation: {
      'de-CH': {
        'f-': 'Mayotte',
      },
      'en-US': {
        'f-': 'Mayotte',
      },
      'fr-CH': {
        'f-': 'Mayotte',
      },
    },
    ePoliceId: '2a893943-e761-11e8-aea4-43375a4a09b8',
    iso3166: 'YT',
    lat: -12.83333333,
    lon: 45.16666666,
    officialDesignation: {
      'de-CH': {
        'f-': 'Übersee-Département Mayotte',
      },
      'en-US': {
        'f-': 'Department of Mayotte',
      },
      'fr-CH': {
        'f-': 'Département de Mayotte',
      },
    },
  },
  {
    _id: '#ZA',
    continent: '#AF',
    designation: {
      'de-CH': {
        'f-': 'Republik Südafrika',
      },
      'en-US': {
        'f-': 'South Africa',
      },
      'fr-CH': {
        'f-': 'Afrique du Sud',
      },
    },
    ePoliceId: '2a891228-e761-11e8-aea4-43375a4a09b8',
    iso3166: 'ZA',
    lat: -29,
    lon: 24,
    officialDesignation: {
      'de-CH': {
        'f-': 'Republik Südafrika',
      },
      'en-US': {
        'f-': 'Republic of South Africa',
      },
      'fr-CH': {
        'f-': "République d'Afrique du Sud",
      },
    },
  },
  {
    _id: '#ZM',
    continent: '#AF',
    designation: {
      'de-CH': {
        'f-': 'Sambia',
      },
      'en-US': {
        'f-': 'Zambia',
      },
      'fr-CH': {
        'f-': 'Zambie',
      },
    },
    ePoliceId: '2a891222-e761-11e8-aea4-43375a4a09b8',
    iso3166: 'ZM',
    lat: -15,
    lon: 30,
    officialDesignation: {
      'de-CH': {
        'f-': 'Republik Sambia',
      },
      'en-US': {
        'f-': 'Republic of Zambia',
      },
      'fr-CH': {
        'f-': 'République de Zambie',
      },
    },
  },
  {
    _id: '#ZW',
    continent: '#AF',
    designation: {
      'de-CH': {
        'f-': 'Simbabwe',
      },
      'en-US': {
        'f-': 'Zimbabwe',
      },
      'fr-CH': {
        'f-': 'Zimbabwe',
      },
    },
    ePoliceId: '2a891220-e761-11e8-aea4-43375a4a09b8',
    iso3166: 'ZW',
    lat: -20,
    lon: 30,
    officialDesignation: {
      'de-CH': {
        'f-': 'Republik Simbabwe',
      },
      'en-US': {
        'f-': 'Republic of Zimbabwe',
      },
      'fr-CH': {
        'f-': 'République du Zimbabwe',
      },
    },
  },
];
