import { ChangeDetectionStrategy, Component, type OnInit } from '@angular/core';
import type { IBbitI18n } from '@bbitgmbh/bbit.utils';

import { RouterLink, RouterLinkActive, RouterOutlet } from '@angular/router';
import { I18nPipe } from '@bbitgmbh/website.sdk';

interface ILink {
  name: IBbitI18n;
  routerLink: string[];
  icon?: string;
}

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [RouterLink, RouterLinkActive, RouterOutlet, I18nPipe],
})
export class AccountComponent implements OnInit {
  public links: ILink[] = [
    {
      name: { key: 'profile' },
      routerLink: ['profile'],
      icon: 'heroUserCircle',
    },
    {
      name: { key: 'itemBacklog' },
      routerLink: ['item-backlog'],
    },
    {
      name: { key: 'webOrder' },
      routerLink: ['documents', 'webOrder'],
    },
    {
      name: { key: 'orderConfirmation' },
      routerLink: ['documents', 'orderConfirmation'],
    },
    {
      name: { key: 'deliveryNote' },
      routerLink: ['documents', 'deliveryNote'],
    },
    {
      name: { key: 'invoice' },
      routerLink: ['documents', 'invoice'],
    },
  ];

  ngOnInit(): void {}
}
