import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, type OnInit, inject } from '@angular/core';
import {
  type BbitLegalDocDefinition,
  BbitObject,
  type IBbitOrganizationLegalIdentity,
  bbitProdEnvironmentKey,
} from '@bbitgmbh/bbit.utils';
import { BbitWebsiteMetaDataService, BbitWebsiteService, I18nPipe, LuxonPipe } from '@bbitgmbh/website.sdk';
import { BehaviorSubject, map, share } from 'rxjs';

@Component({
  selector: 'app-legal',
  templateUrl: './legal.component.html',
  styleUrls: ['./legal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [AsyncPipe, I18nPipe, LuxonPipe],
})
export class LegalComponent implements OnInit {
  private _meta = inject(BbitWebsiteMetaDataService);
  private _website = inject(BbitWebsiteService);

  public identity = new BehaviorSubject<IBbitOrganizationLegalIdentity | undefined>(undefined);
  public legalDocs = new BehaviorSubject<(BbitLegalDocDefinition & { key: string })[]>([]);

  Object = Object;
  mailToLink = this.identity.asObservable().pipe(
    map((identity) => {
      if (!identity) {
        return '';
      }
      return `mailto:${identity.mainEMail}`;
    }),
    share(),
  );

  phoneLink = this.identity.asObservable().pipe(
    map((identity) => {
      if (!identity) {
        return '';
      }
      return `tel:${identity.mainPhone?.replace(/\s/g, '')}`;
    }),
    share(),
  );

  constructor() {
    this._meta.update({ title: { key: 'legal' } });

    const currentConfig = this._website.getAuthConfig();

    if (BbitObject.isObject(currentConfig?.legalDocs)) {
      const docsArr = Object.entries(currentConfig.legalDocs).map(([key, value]) => ({ ...value, key }));
      this.legalDocs.next(docsArr);
    }

    this.identity.next(this._website.getAuthMeta()?.environments?.[bbitProdEnvironmentKey]?.identity);
  }

  ngOnInit(): void {}
}
