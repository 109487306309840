<div class="relative overflow-hidden bg-white">
  <div class="mx-auto max-w-7xl">
    <div
      class="relative z-10 bg-white pb-8 sm:pb-16 md:pb-20 lg:w-full lg:max-w-2xl lg:pb-28 xl:pb-32"
    >
      <div>
        <div class="relative px-4 pt-6 sm:px-6 lg:px-8"></div>
      </div>

      <div
        class="mx-auto mt-10 max-w-7xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28"
      >
        <div class="sm:text-center lg:text-left">
          <h1
            class="text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl md:text-6xl"
          >
            <span class="block xl:inline"
              ><span class="text-primary-600">Impressum</span>&nbsp;<span
                class="text-nowrap"
                >{{ (identity | async)?.fullName }}</span
              ></span
            >
          </h1>
          <p
            class="mt-3 text-base text-gray-500 sm:mx-auto sm:mt-5 sm:max-w-xl sm:text-lg md:mt-5 md:text-xl lg:mx-0"
          >
            sowie Rechtliches
          </p>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="bg-white py-4 sm:py-8">
  <div class="mx-auto grid max-w-7xl gap-x-8 px-6 lg:px-8 xl:grid-cols-3">
    <div class="max-w-2xl">
      <h2 class="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
        Herausgeber
      </h2>
      <p class="mt-6 text-lg leading-8 text-gray-600"></p>
    </div>
    <div class="grid xl:col-span-2">
      <p>
        {{ (identity | async)?.fullName }}<br />{{ (identity | async)?.street
        }}<br />{{ (identity | async)?.postalCode }}&nbsp;{{
          (identity | async)?.locality
        }}<br />{{ (identity | async)?.countryName }}
      </p>
      <p>
        Tel:
        <a [href]="phoneLink | async" target="_blank">{{
          (identity | async)?.mainPhone
        }}</a>
        <br />
        E-Mail:
        <a [href]="mailToLink | async" target="_blank">{{
          (identity | async)?.mainEMail
        }}</a>
      </p>
      <p>UID: {{ (identity | async)?.companyRegistrationNumber }}</p>
    </div>
  </div>
</div>

<div class="bg-white py-4 sm:py-8">
  <div class="mx-auto grid max-w-7xl gap-x-8 px-6 lg:px-8 xl:grid-cols-3">
    <div class="max-w-2xl">
      <h2 class="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
        Rechtliches
      </h2>
      <p class="mt-6 text-lg leading-8 text-gray-600">
        Dieser Abschnitt ist nur auf deutsch verfügbar.
      </p>
    </div>
    <div class="grid xl:col-span-2">
      <h2 class="text-xl font-bold tracking-tight text-gray-900 sm:text-2xl">
        Allgemeine Geschäftsbedingungen
      </h2>

      <p>
        Die für diesen Internetauftritt sowie alle weiteren Geschäfte aktuell
        gültige Version befindet sich auf folgender Seite:
        <a class="text-primary-600" [routerLink]="['/legal']">Rechtliches</a>
      </p>

      <h2 class="text-xl font-bold tracking-tight text-gray-900 sm:text-2xl">
        Haftung für Inhalte
      </h2>
      <p>
        Die Inhalte unserer Seiten wurden mit grösster Sorgfalt erstellt. Für die
        Richtigkeit, Vollständigkeit und Aktualität der Inhalte können wir
        jedoch keine Gewähr übernehmen.
      </p>
      <p>
        Haftungsansprüche gegen {{ (identity | async)?.fullName }} wegen Schäden
        materieller oder immaterieller Art, welche aus dem Zugriff oder der
        Nutzung bzw. Nichtnutzung der veröffentlichten Informationen, durch
        Missbrauch der Verbindung oder durch technische Störungen entstanden
        sind, werden hiermit ausgeschlossen.
      </p>
      <h2
        class="text-xl font-bold tracking-tight text-gray-900 sm:text-2xl mt-4"
      >
        Haftungsausschluss für Links
      </h2>
      <p>
        Wir haben bei der Erstellung der Website alle externen Links sorgfältigt
        auf illegale Inhalte überprüft. Da externe Inhalte ohne Kenntnis von
        {{ (identity | async)?.fullName }}
        jederzeit verändert werden können, lehnen wir jegliche Haftung ab.
      </p>
      <h2
        class="text-xl font-bold tracking-tight text-gray-900 sm:text-2xl mt-4"
      >
        Preise
      </h2>
      <p>
        Alle angegeben Preise sind ohne Gewähr und verstehen sich, wenn nicht
        anders angegeben oder im Webshop in den Anzeige-Einstellungen
        konfiguriert, in CHF inklusive Mehrwehrtsteuer.
      </p>
    </div>
  </div>
</div>

<div class="bg-white py-4 sm:py-8">
  <div class="mx-auto grid max-w-7xl gap-x-8 px-6 lg:px-8 xl:grid-cols-3">
    <div class="max-w-2xl">
      <h2 class="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
        Datenschutz
      </h2>
    </div>
    <div class="grid xl:col-span-2">
      <p>
        Ihrer Privatsphäre haben wir eine eigene Seite gewidmet:
        <a class="text-primary-600" [routerLink]="['/privacy']"
          >Datenschutzerklärung</a
        >
      </p>
    </div>
  </div>
</div>
