import { ChangeDetectionStrategy, Component, OnInit, inject } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { NavService } from '../nav/nav.service';
import { NavOverlayService } from './nav-overlay.service';
import { BbitWebsiteService, I18nPipe } from '@bbitgmbh/website.sdk';
import { BbitWebShopCartService } from '@bbitgmbh/webshop.sdk';
import { BbitLanguage, BbitI18n } from '@bbitgmbh/bbit.utils';
import { AsyncPipe } from '@angular/common';
import { NgIcon } from '@ng-icons/core';

@Component({
    selector: 'app-nav-overlay',
    templateUrl: './nav-overlay.component.html',
    styleUrls: ['./nav-overlay.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [RouterLink, NgIcon, AsyncPipe, I18nPipe]
})
export class NavOverlayComponent {
  nav = inject(NavService);
  private _router = inject(Router);
  private _navOverlay = inject(NavOverlayService);
  website = inject(BbitWebsiteService);
  cart = inject(BbitWebShopCartService);


  public BbitI18n = BbitI18n;
  public BbitLanguage = BbitLanguage;

  public route(route: string[]): void {
    this._router.navigate(route);
    this._navOverlay.close();
  }

  close(): void {
    this._navOverlay.close();
  }

  changeLang(lang: BbitLanguage) {
    if (lang) {
      BbitI18n.setLanguage(lang);
    }
  }
}
