import { BbitLanguage, BbitTranslationType } from '@bbitgmbh/bbit.utils';

export const nameSalutationRecords = [
  {
    _id: '#company',
    designation: {
      [BbitLanguage.DE_CH]: { [BbitTranslationType.PLURAL]: 'Firma' },
      [BbitLanguage.EN_US]: { [BbitTranslationType.PLURAL]: 'company' },
    },
  },
  {
    _id: '#club',
    designation: {
      [BbitLanguage.DE_CH]: { [BbitTranslationType.PLURAL]: 'Verein' },
      [BbitLanguage.EN_US]: { [BbitTranslationType.PLURAL]: 'Club' },
    },
  },
  {
    _id: '#male',
    designation: {
      [BbitLanguage.DE_CH]: { [BbitTranslationType.PLURAL]: 'Herr' },
      [BbitLanguage.EN_US]: { [BbitTranslationType.PLURAL]: 'Mr.' },
    },
  },
  {
    _id: '#female',
    designation: {
      [BbitLanguage.DE_CH]: { [BbitTranslationType.PLURAL]: 'Frau' },
      [BbitLanguage.EN_US]: { [BbitTranslationType.PLURAL]: 'Ms.' },
    },
  },
];
