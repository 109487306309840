import { Component, type OnInit, inject } from '@angular/core';
import { BbitPictureSrcDirective, BbitWebsiteMetaDataService } from '@bbitgmbh/website.sdk';

@Component({
    selector: 'app-areas',
    templateUrl: './areas.component.html',
    styleUrl: './areas.component.scss',
    imports: [BbitPictureSrcDirective]
})
export class AreasComponent implements OnInit {
  private _meta = inject(BbitWebsiteMetaDataService);


  ngOnInit() {
    this._meta.update({
      title: 'Bereiche',
      description:
        'Entdecken Sie stufengerechte Kurse, Waffen, Munition und Zubehör bei AATS. Nutzen Sie unsere Sicherheitsdienstleistungen. ',
    });
  }
}
