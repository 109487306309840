import { Injectable } from '@angular/core';
import { DateTime } from 'luxon';
import type { ICountry, IDistributor } from './distributor.interfaces';
import type { INewsEntry } from './news.interfaces';
import type { IWeaponModel } from './weapon.interfaces';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  private _rootUrl = 'https://phx.swiss';

  async preload(): Promise<void> {
    await Promise.all([this.getCountries(), this.getNews(), this.getDistributors(), this.getShooter()]);
  }

  async getNews(): Promise<INewsEntry[]> {
    return this._get({
      url: '/cache/bucket/cmsNews/index.json',
      skipBrowserDiskCache: true,
    });
  }

  async getWeapons(): Promise<IWeaponModel[]> {
    return this._get({
      url: '/cache/bucket/phxItemConfigurator/index.json',
      skipBrowserDiskCache: true,
    });
  }

  async getShooter(): Promise<any[]> {
    return this._get({
      url: '/cache/bucket/cmsShooter/index.json',
      skipBrowserDiskCache: true,
    });
  }

  async getDistributors(): Promise<IDistributor[]> {
    return this._get({
      url: '/cache/bucket/contact/index.json',
      skipBrowserDiskCache: true,
    });
  }

  async getCountries(): Promise<ICountry[]> {
    return this._get({ url: '/cache/bucket/country/index.json' });
  }

  private _cache: { [key: string]: any } = {};

  private async _get<T = any>({
    url,
    skipCache,
    skipBrowserDiskCache,
  }: {
    url: string;
    skipCache?: boolean;
    skipBrowserDiskCache?: boolean;
  }): Promise<T> {
    if (!skipCache) {
      if (this._cache[url]) {
        return this._cache[url];
      }
    }
    const response = await fetch(
      this._rootUrl + url + (skipBrowserDiskCache ? `?v=${DateTime.utc().toFormat('yyyyMMddhhmm')}` : ''),
    );
    const result = await response.json();
    this._cache[url] = result;
    return result;
  }
}
