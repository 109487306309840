import { AsyncPipe } from '@angular/common';
import {
  AfterViewInit,
  CUSTOM_ELEMENTS_SCHEMA,
  Component,
  ElementRef,
  OnDestroy,
  type OnInit,
  inject,
  viewChild,
} from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { BbitI18n } from '@bbitgmbh/bbit.utils';
import { BbitPictureSrcDirective, BbitWebsiteMetaDataService, I18nPipe, LuxonPipe } from '@bbitgmbh/website.sdk';
import { DateTime } from 'luxon';
import { BehaviorSubject } from 'rxjs';
import Swiper from 'swiper';
import { ApiService } from '../api/api.service';
import type { IInjectedNewsEntry } from '../api/news.interfaces';
import { NavService } from '../nav/nav.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss',
  imports: [RouterLink, AsyncPipe, I18nPipe, LuxonPipe, BbitPictureSrcDirective],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class HomeComponent implements OnInit, OnDestroy, AfterViewInit {
  private _api = inject(ApiService);
  private _meta = inject(BbitWebsiteMetaDataService);
  private _router = inject(Router);
  private _navService = inject(NavService);
  public slides = new BehaviorSubject<any[]>([]);
  readonly swiperRef = viewChild<ElementRef>('swiperRef');
  private _swiper?: Swiper;

  public news = new BehaviorSubject<IInjectedNewsEntry[]>([]);

  constructor() {
    this.slides.next([
      {
        title: '',
        subTitle: '',
        imageUrl: '/assets/images/logo-phx-white.svg',
        localImage: true,
      },
    ]);

    this._api.getWeapons().then((weapons) => {
      const newItems = [];
      for (const o of weapons) {
        newItems.push({
          title: BbitI18n.t({ object: o.designation }),
          subTitle: 'Swiss Quality Firearms',
          imageUrl: o.imageLeft?.s3Key || o.imageRight?.s3Key || o.imageFront?.s3Key,
          routerLink: ['/products', o.code],
          linkText: 'Jetzt konfigurieren',
        });
      }
      this.slides.next([...this.slides.value, ...newItems]);
    });

    this._api.getNews().then((result) => {
      let news = [];
      for (const o of result) {
        const from = DateTime.fromISO(o.display?.from);
        const until = DateTime.fromISO(o.display?.until);
        const now = DateTime.now();
        if (now >= from && now <= until) {
          news.push({
            _id: o._id,
            title: o.title,
            date: DateTime.fromISO(o.newsDate),
            from,
            until,
            description: {
              'de-CH': { 'f-': o.newsHtml?.de },
              'en-US': { 'f-': o.newsHtml?.en },
              'fr-CH': { 'f-': o.newsHtml?.fr },
            },
            image: o.newsImage.url,
            moreInfoLink: o.link?.url,
          });
        }
      }

      const sortBy = (a: IInjectedNewsEntry, b: IInjectedNewsEntry) => {
        if (+a.date > +b.date) {
          return -1;
        }
        if (+a.date < +b.date) {
          return 1;
        }
        return 0;
      };

      news = news.sort(sortBy).slice(0, 3);
      this.news.next(news);
    });
  }
  ngOnDestroy(): void {}

  ngOnInit() {
    this._meta.update({
      title: 'PHX Phoenix AG',
      description: 'Die PHX Phoenix AG ist Herstellerin von hochwertigen Pistolen für Sportschützen, Behörden, etc.',
    });
  }

  ngAfterViewInit(): void {
    this._swiper = this.swiperRef()?.nativeElement.swiper;
    setTimeout(() => {
      this._swiper?.autoplay.start();
    }, 1000);
  }

  routeToNews(news: IInjectedNewsEntry): void {
    this._router.navigate(['/news', news._id]);
  }
}
