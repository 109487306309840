import { Component, inject } from '@angular/core';
import { BbitArray, BbitI18n } from '@bbitgmbh/bbit.utils';
import { BehaviorSubject } from 'rxjs';
import { ApiService } from '../api/api.service';
import type { ICountry, IDistributor } from '../api/distributor.interfaces';
import { stripBeginningHashtag } from '../api/helper';
import { NavService } from '../nav/nav.service';
import { AsyncPipe } from '@angular/common';
import { I18nPipe } from '@bbitgmbh/website.sdk';
import { PageHeaderComponent } from '../helper/page-header/page-header.component';

@Component({
    selector: 'app-dealer',
    templateUrl: './dealer.component.html',
    styleUrls: ['./dealer.component.scss'],
    imports: [AsyncPipe, I18nPipe, PageHeaderComponent]
})
export class DealerComponent {
  private _api = inject(ApiService);
  private _navService = inject(NavService);

  public _allDistributors: IDistributor[] = [];
  public distributors = new BehaviorSubject<IDistributor[]>([]);
  public countries = new BehaviorSubject<string[]>([]);
  private _allCountries: ICountry[] = [];
  public filteredCountry = new BehaviorSubject('');
  constructor() {


    this._api.getDistributors().then(async (distributors) => {
      this._allCountries = await this._api.getCountries();
      for (const o of distributors) {
        if (!this.countries.value.find((c) => c === o.address.country)) {
          this.countries.value.push(o.address.country);
        }
      }

      // sort stuff
      distributors = BbitArray.orderBy(distributors, (o) => {
        return [BbitI18n.t(o.name.companyName || o.name.fullName)];
      }, ['asc']);

      this.countries.next(
        BbitArray.orderBy(this.countries.value, (c) => {
          return [this.getCountryDesignation(c)];
        }, ['asc']),
      );

      this._allDistributors = [...distributors];
      this.distributors.next(distributors);
    });
  }

  getCountryCode(_id: string): string {
    return stripBeginningHashtag(_id).toLocaleLowerCase();
  }

  getCountryDesignation(_id: string): string {
    _id = stripBeginningHashtag(_id);
    const country = this._allCountries.find((o) => o._id === _id);
    if (country) {
      return BbitI18n.t({ object: country?.designation });
    }
    return '';
  }

  ngOnInit(): void {}

  public filter(country: string): void {
    if (this.filteredCountry.value === country) {
      this.filteredCountry.next('');
      this.distributors.next(this._allDistributors);
      return;
    }
    this.filteredCountry.next(country);
    this.distributors.next(
      this._allDistributors.filter((o) => {
        return o.address.country === country;
      }),
    );
  }
}
