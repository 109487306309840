import { BbitLanguage, BbitTranslationType, type IBbitTranslation } from '@bbitgmbh/bbit.utils';

export const translations: IBbitTranslation[] = [
  {
    key: 'notInStock',
    text: {
      [BbitLanguage.DE_CH]: {
        [BbitTranslationType.PLURAL]: 'Lieferfrist auf Anfrage',
      },
      [BbitLanguage.EN_US]: {
        [BbitTranslationType.PLURAL]: 'Delivery time on request',
      },
      [BbitLanguage.FR_CH]: {
        [BbitTranslationType.PLURAL]: 'Délai de livraison sur demande',
      },
    },
  },
  {
    key: 'product',
    text: {
      [BbitLanguage.DE_CH]: {
        [BbitTranslationType.PLURAL]: 'Produkte',
        [BbitTranslationType.SINGULAR]: 'Produkt',
      },
      [BbitLanguage.EN_US]: {
        [BbitTranslationType.PLURAL]: 'Products',
        [BbitTranslationType.SINGULAR]: 'Product',
      },
    },
  },
  {
    key: 'news',
    text: {
      [BbitLanguage.DE_CH]: {
        [BbitTranslationType.PLURAL]: 'News',
      },
      [BbitLanguage.EN_US]: {
        [BbitTranslationType.PLURAL]: 'News',
      },
    },
  },
  {
    key: 'impressum',
    text: {
      [BbitLanguage.DE_CH]: {
        [BbitTranslationType.PLURAL]: 'Impressum',
      },
      [BbitLanguage.EN_US]: {
        [BbitTranslationType.PLURAL]: 'Impressum',
      },
    },
  },
  {
    key: 'privacy',
    text: {
      [BbitLanguage.DE_CH]: {
        [BbitTranslationType.PLURAL]: 'Datenschutz',
      },
      [BbitLanguage.EN_US]: {
        [BbitTranslationType.PLURAL]: 'Privacy',
      },
    },
  },
  {
    key: 'shooter',
    text: {
      [BbitLanguage.DE_CH]: {
        [BbitTranslationType.PLURAL]: 'Schützen',
        [BbitTranslationType.SINGULAR]: 'Schüzte',
      },
      [BbitLanguage.EN_US]: {
        [BbitTranslationType.PLURAL]: 'Shooter',
      },
    },
  },
  {
    key: 'factoryTeam',
    text: {
      [BbitLanguage.DE_CH]: {
        [BbitTranslationType.PLURAL]: 'Factory Team',
      },
      [BbitLanguage.EN_US]: {
        [BbitTranslationType.PLURAL]: 'Factory Team',
      },
    },
  },
  {
    key: 'dealer',
    text: {
      [BbitLanguage.DE_CH]: {
        [BbitTranslationType.PLURAL]: 'Händler',
      },
      [BbitLanguage.EN_US]: {
        [BbitTranslationType.PLURAL]: 'Dealer',
      },
    },
  },
  {
    key: 'product',
    text: {
      [BbitLanguage.DE_CH]: {
        [BbitTranslationType.PLURAL]: 'Produkte',
      },
      [BbitLanguage.EN_US]: {
        [BbitTranslationType.PLURAL]: 'Products',
      },
    },
  },
  {
    key: 'contact',
    text: {
      [BbitLanguage.DE_CH]: {
        [BbitTranslationType.PLURAL]: 'Kontakt',
      },
      [BbitLanguage.EN_US]: {
        [BbitTranslationType.PLURAL]: 'Contact',
      },
    },
  },
  {
    key: 'hereYouCanFindOurNewProducts',
    text: {
      [BbitLanguage.DE_CH]: {
        [BbitTranslationType.PLURAL]: 'Hier findest du unsere neuen Produkte',
      },
      [BbitLanguage.EN_US]: {
        [BbitTranslationType.PLURAL]: 'Here you can find our new products',
      },
    },
  },
  {
    key: 'orderNumberOfThisProduct',
    text: {
      [BbitLanguage.DE_CH]: {
        [BbitTranslationType.PLURAL]: 'Bestell-Nr. dieser Konfiguration',
      },
      [BbitLanguage.EN_US]: {
        [BbitTranslationType.PLURAL]: 'Order No. of this configuration',
      },
    },
  },
  {
    key: 'barrel',
    text: {
      [BbitLanguage.DE_CH]: {
        [BbitTranslationType.PLURAL]: 'Läufe',
        f1: 'Lauf',
      },
      [BbitLanguage.EN_US]: {
        [BbitTranslationType.PLURAL]: 'Barrels',
        f1: 'Barrel',
      },
    },
  },
  {
    key: 'gripShell',
    text: {
      [BbitLanguage.DE_CH]: {
        [BbitTranslationType.PLURAL]: 'Griffschalen',
        f1: 'Griffschale',
      },
      [BbitLanguage.EN_US]: {
        [BbitTranslationType.PLURAL]: 'Grip shell',
        f1: 'Grip shell',
      },
    },
  },
  {
    key: 'optic',
    text: {
      [BbitLanguage.DE_CH]: {
        [BbitTranslationType.PLURAL]: 'Visiere',
        f1: 'Visier',
      },
      [BbitLanguage.EN_US]: {
        [BbitTranslationType.PLURAL]: 'Optics',
        f1: 'Optic',
      },
    },
  },
  {
    key: 'trigger',
    text: {
      [BbitLanguage.DE_CH]: {
        [BbitTranslationType.PLURAL]: 'Abzüge',
        f1: 'Abzug',
      },
      [BbitLanguage.EN_US]: {
        [BbitTranslationType.PLURAL]: 'Triggers',
        f1: 'Trigger',
      },
    },
  },
  {
    key: 'caliber',
    text: {
      [BbitLanguage.DE_CH]: { [BbitTranslationType.PLURAL]: 'Kaliber' },
      [BbitLanguage.EN_US]: {
        [BbitTranslationType.PLURAL]: 'Calibers',
        f1: 'Caliber',
      },
    },
  },
  {
    key: 'frontSight',
    text: {
      [BbitLanguage.DE_CH]: { [BbitTranslationType.PLURAL]: 'Korn' },
      [BbitLanguage.EN_US]: {
        [BbitTranslationType.PLURAL]: 'Front sights',
        f1: 'Front sight',
      },
    },
  },
  {
    key: 'color',
    text: {
      [BbitLanguage.DE_CH]: {
        [BbitTranslationType.PLURAL]: 'Farben',
        f1: 'Farbe',
      },
      [BbitLanguage.EN_US]: {
        [BbitTranslationType.PLURAL]: 'Colors',
        f1: 'Color',
      },
    },
  },
  {
    key: 'material',
    text: {
      [BbitLanguage.DE_CH]: {
        [BbitTranslationType.PLURAL]: 'Materialien',
        f1: 'Material',
      },
      [BbitLanguage.EN_US]: {
        [BbitTranslationType.PLURAL]: 'Materials',
        f1: 'Material',
      },
    },
  },
  {
    key: 'version',
    text: {
      [BbitLanguage.DE_CH]: {
        [BbitTranslationType.PLURAL]: 'Ausführungen',
        f1: 'Ausführung',
      },
      [BbitLanguage.EN_US]: {
        [BbitTranslationType.PLURAL]: 'Versions',
        f1: 'Version',
      },
    },
  },
  {
    key: 'scopeOfDelivery',
    text: {
      [BbitLanguage.DE_CH]: {
        [BbitTranslationType.PLURAL]: 'Lieferumfang',
      },
      [BbitLanguage.EN_US]: {
        [BbitTranslationType.PLURAL]: 'Scope of delivery',
      },
    },
  },
  {
    key: 'specification',
    text: {
      [BbitLanguage.DE_CH]: {
        [BbitTranslationType.PLURAL]: 'Spezifikationen',
        f1: 'Spezifikation',
      },
      [BbitLanguage.EN_US]: {
        [BbitTranslationType.PLURAL]: 'Specifications',
        f1: 'specification',
      },
    },
  },
  {
    key: 'latestNews',
    text: {
      [BbitLanguage.DE_CH]: {
        [BbitTranslationType.PLURAL]: 'Aktuelles',
      },
      [BbitLanguage.EN_US]: {
        [BbitTranslationType.PLURAL]: 'Latest news',
      },
    },
  },
  {
    key: 'width',
    text: {
      [BbitLanguage.DE_CH]: { [BbitTranslationType.PLURAL]: 'Breite' },
      [BbitLanguage.EN_US]: { [BbitTranslationType.PLURAL]: 'Width' },
    },
  },
  {
    key: 'height',
    text: {
      [BbitLanguage.DE_CH]: { [BbitTranslationType.PLURAL]: 'Höhe' },
      [BbitLanguage.EN_US]: { [BbitTranslationType.PLURAL]: 'Height' },
    },
  },
  {
    key: 'barrelLength',
    text: {
      [BbitLanguage.DE_CH]: { [BbitTranslationType.PLURAL]: 'Lauf Länge' },
      [BbitLanguage.EN_US]: { [BbitTranslationType.PLURAL]: 'Barrel length' },
    },
  },
  {
    key: 'totalLength',
    text: {
      [BbitLanguage.DE_CH]: { [BbitTranslationType.PLURAL]: 'Gesamtlänge' },
      [BbitLanguage.EN_US]: { [BbitTranslationType.PLURAL]: 'Total length' },
    },
  },
  {
    key: 'weight',
    text: {
      [BbitLanguage.DE_CH]: { [BbitTranslationType.PLURAL]: 'Gewicht' },
      [BbitLanguage.EN_US]: { [BbitTranslationType.PLURAL]: 'Weight' },
    },
  },
  {
    key: 'intendedUse',
    text: {
      [BbitLanguage.DE_CH]: { [BbitTranslationType.PLURAL]: 'Einsatzzwecke' },
      [BbitLanguage.EN_US]: { [BbitTranslationType.PLURAL]: 'Intended use' },
    },
  },
  {
    key: 'magazineCapacity',
    text: {
      [BbitLanguage.DE_CH]: { [BbitTranslationType.PLURAL]: 'Magazingrösse' },
      [BbitLanguage.EN_US]: { [BbitTranslationType.PLURAL]: 'Magazine capacity' },
    },
  },
  {
    key: 'orderNumber',
    text: {
      [BbitLanguage.DE_CH]: { [BbitTranslationType.PLURAL]: 'Bestell-Nr.' },
      [BbitLanguage.EN_US]: { [BbitTranslationType.PLURAL]: 'Order No.' },
    },
  },
  {
    key: 'articleNumber',
    text: {
      [BbitLanguage.DE_CH]: { [BbitTranslationType.PLURAL]: 'Artikelnummer' },
      [BbitLanguage.EN_US]: { [BbitTranslationType.PLURAL]: 'Article number' },
    },
  },
  {
    key: 'model',
    text: {
      [BbitLanguage.DE_CH]: { [BbitTranslationType.PLURAL]: 'Modell' },
      [BbitLanguage.EN_US]: { [BbitTranslationType.PLURAL]: 'Model' },
    },
  },
  {
    key: 'configurator',
    text: {
      [BbitLanguage.DE_CH]: { [BbitTranslationType.PLURAL]: 'Konfigurator' },
      [BbitLanguage.EN_US]: { [BbitTranslationType.PLURAL]: 'Configurator' },
    },
  },
  {
    key: 'findDealer',
    text: {
      [BbitLanguage.DE_CH]: { [BbitTranslationType.PLURAL]: 'Händler finden' },
      [BbitLanguage.EN_US]: { [BbitTranslationType.PLURAL]: 'Find dealer' },
    },
  },
  {
    key: 'weaponImageInfo',
    text: {
      [BbitLanguage.DE_CH]: {
        [BbitTranslationType.PLURAL]: 'Abgebildetes Modell ist eine Beispielkonfiguration mit Sonderzubehör',
      },
      [BbitLanguage.EN_US]: {
        [BbitTranslationType.PLURAL]: 'Model shown is an example configuration with optional accessories',
      },
    },
  },
  {
    key: 'moreInfo',
    text: {
      [BbitLanguage.DE_CH]: { [BbitTranslationType.PLURAL]: 'Mehr Infos' },
      [BbitLanguage.EN_US]: { [BbitTranslationType.PLURAL]: 'More info' },
    },
  },
  {
    key: 'accessories',
    text: {
      [BbitLanguage.DE_CH]: {
        [BbitTranslationType.PLURAL]: 'Zubehör',
      },
      [BbitLanguage.EN_US]: {
        [BbitTranslationType.PLURAL]: 'Accessories',
      },
    },
  },
  {
    key: 'orderInfo',
    text: {
      [BbitLanguage.DE_CH]: {
        [BbitTranslationType.PLURAL]: 'Für eine Bestellung oder weitere Informationen wenden Sie sich an',
      },
      [BbitLanguage.EN_US]: {
        [BbitTranslationType.PLURAL]: 'To place an order or get additional information contact',
      },
    },
  },
  {
    key: 'imprint',
    text: {
      [BbitLanguage.DE_CH]: {
        [BbitTranslationType.PLURAL]: 'Impressum',
      },
      [BbitLanguage.EN_US]: {
        [BbitTranslationType.PLURAL]: 'Imprint',
      },
    },
  },
  {
    key: 'news',
    text: {
      [BbitLanguage.DE_CH]: {
        [BbitTranslationType.PLURAL]: 'News',
      },
      [BbitLanguage.EN_US]: {
        [BbitTranslationType.PLURAL]: 'News',
      },
    },
  },
  {
    key: 'additionalInfo',
    text: {
      [BbitLanguage.DE_CH]: {
        [BbitTranslationType.PLURAL]: 'Zusätzliche Informationen...',
      },
      [BbitLanguage.EN_US]: {
        [BbitTranslationType.PLURAL]: 'Additional infos...',
      },
    },
  },
  {
    key: 'manual',
    text: {
      [BbitLanguage.DE_CH]: {
        [BbitTranslationType.PLURAL]: 'Bedienungsanleitungen',
        f1: 'Bedienungsanleitung',
      },
      [BbitLanguage.EN_US]: {
        [BbitTranslationType.PLURAL]: 'Manuals',
        f1: 'Manual',
      },
    },
  },
  {
    key: '#de-CH',
    text: {
      [BbitLanguage.DE_CH]: {
        [BbitTranslationType.PLURAL]: 'Deutsch',
      },
      [BbitLanguage.EN_US]: {
        [BbitTranslationType.PLURAL]: 'German',
      },
    },
  },
  {
    key: '#en-US',
    text: {
      [BbitLanguage.DE_CH]: {
        [BbitTranslationType.PLURAL]: 'Englisch',
      },
      [BbitLanguage.EN_US]: {
        [BbitTranslationType.PLURAL]: 'English',
      },
    },
  },
  {
    key: 'configureYourWeapon',
    text: {
      [BbitLanguage.DE_CH]: {
        [BbitTranslationType.PLURAL]: 'Konfigurieren Sie Ihre Waffe',
      },
      [BbitLanguage.EN_US]: {
        [BbitTranslationType.PLURAL]: 'Configure your weapon',
      },
    },
  },
  {
    key: 'shooter',
    text: {
      [BbitLanguage.DE_CH]: {
        [BbitTranslationType.PLURAL]: 'Schützen',
        f1: 'Schütze',
      },
      [BbitLanguage.EN_US]: {
        [BbitTranslationType.PLURAL]: 'Shooters',
        f1: 'Shooter',
      },
    },
  },
  {
    key: 'category',
    text: {
      [BbitLanguage.DE_CH]: {
        [BbitTranslationType.PLURAL]: 'Kategorien',
        f1: 'Kategorie',
      },
      [BbitLanguage.EN_US]: {
        [BbitTranslationType.PLURAL]: 'Categories',
        f1: 'Category',
      },
    },
  },
  {
    key: 'weapon',
    text: {
      [BbitLanguage.DE_CH]: {
        [BbitTranslationType.PLURAL]: 'Waffen',
        f1: 'Waffe',
      },
      [BbitLanguage.EN_US]: {
        [BbitTranslationType.PLURAL]: 'Weapons',
        f1: 'Weapon',
      },
    },
  },
  {
    key: 'nationality',
    text: {
      [BbitLanguage.DE_CH]: {
        [BbitTranslationType.PLURAL]: 'Nationalität',
      },
      [BbitLanguage.EN_US]: {
        [BbitTranslationType.PLURAL]: 'Nationality',
      },
    },
  },
  {
    key: '404notFoundHeader',
    text: {
      [BbitLanguage.DE_CH]: {
        [BbitTranslationType.PLURAL]: '404 - knapp daneben ist auch vorbei',
      },
      [BbitLanguage.EN_US]: {
        [BbitTranslationType.PLURAL]: '404 - close but no cigar',
      },
    },
  },
  {
    key: '404notFoundSubtitle',
    text: {
      [BbitLanguage.DE_CH]: {
        [BbitTranslationType.PLURAL]: 'Diese Unterseite existiert nicht mehr. Gehe weiter:',
      },
      [BbitLanguage.EN_US]: {
        [BbitTranslationType.PLURAL]: 'This subpage does not exist anymore. Go further:',
      },
    },
  },
  {
    key: 'toTheHomePage',
    text: {
      [BbitLanguage.DE_CH]: {
        [BbitTranslationType.PLURAL]: 'Zur Startseite',
      },
      [BbitLanguage.EN_US]: {
        [BbitTranslationType.PLURAL]: 'To the homepage',
      },
    },
  },
  {
    key: 'toOurProducts',
    text: {
      [BbitLanguage.DE_CH]: {
        [BbitTranslationType.PLURAL]: 'Zu unseren Produkten',
      },
      [BbitLanguage.EN_US]: {
        [BbitTranslationType.PLURAL]: 'To our products',
      },
    },
  },
  {
    key: 'welcomeTo',
    text: {
      [BbitLanguage.DE_CH]: {
        [BbitTranslationType.PLURAL]: 'Willkommen bei',
      },
      [BbitLanguage.EN_US]: {
        [BbitTranslationType.PLURAL]: 'Welcome to',
      },
    },
  },
  {
    key: 'welcomeToInfo',
    text: {
      [BbitLanguage.DE_CH]: {
        [BbitTranslationType.PLURAL]:
          'Als Schweizer Waffenhersteller stehen bei uns Qualität und Präzision im Vordergrund. Wir bieten Ihnen eine grosse Auswahl an hochwertigen Produkten.',
      },
      [BbitLanguage.EN_US]: {
        [BbitTranslationType.PLURAL]:
          'As a Swiss gun manufacturer, our focus is on quality and precision. We offer a wide range of high quality products.',
      },
    },
  },
  {
    key: 'productLead',
    text: {
      [BbitLanguage.DE_CH]: {
        [BbitTranslationType.PLURAL]:
          'Hier finden Sie eine Liste unserer hochwertigen und präzise gefertigten Produkte.',
      },
      [BbitLanguage.EN_US]: {
        [BbitTranslationType.PLURAL]:
          'Here you can find a list of our high quality and precision manufactured products.',
      },
    },
  },
  {
    key: 'newsLead',
    text: {
      [BbitLanguage.DE_CH]: {
        [BbitTranslationType.PLURAL]: 'Hier finden sie aktuelle News zu unseren Produkten, Schützen und vielem mehr!',
      },
      [BbitLanguage.EN_US]: {
        [BbitTranslationType.PLURAL]: 'Here you will find current news about our products, shooters and much more!',
      },
    },
  },
  {
    key: 'dealerLead',
    text: {
      [BbitLanguage.DE_CH]: {
        [BbitTranslationType.PLURAL]:
          'Hier finden sie eine filterbare Liste aller Händler, bei denen Sie unsere Produkte erwerben können.',
      },
      [BbitLanguage.EN_US]: {
        [BbitTranslationType.PLURAL]:
          'Here you can find a filterable list of all dealers where you can buy our products.',
      },
    },
  },
  {
    key: 'shooterLead',
    text: {
      [BbitLanguage.DE_CH]: {
        [BbitTranslationType.PLURAL]: 'Hier finden Sie unsere aktuellen Schützen.',
      },
      [BbitLanguage.EN_US]: {
        [BbitTranslationType.PLURAL]: 'Here you can find our current shooters.',
      },
    },
  },
  {
    key: 'productLead',
    text: {
      [BbitLanguage.DE_CH]: {
        [BbitTranslationType.PLURAL]:
          'Hier finden Sie eine Liste unserer hochwertigen und präzise gefertigten Produkte.',
      },
      [BbitLanguage.EN_US]: {
        [BbitTranslationType.PLURAL]:
          'Here you can find a list of our high quality and precision manufactured products.',
      },
    },
  },
  {
    key: 'moreNews',
    text: {
      [BbitLanguage.DE_CH]: {
        [BbitTranslationType.PLURAL]: 'Mehr News anzeigen...',
      },
      [BbitLanguage.EN_US]: {
        [BbitTranslationType.PLURAL]: 'Show more news...',
      },
    },
  },
  {
    key: 'onlineConfigurator',
    text: {
      [BbitLanguage.DE_CH]: {
        [BbitTranslationType.PLURAL]: 'Online Konfigurator',
      },
      [BbitLanguage.EN_US]: {
        [BbitTranslationType.PLURAL]: 'Online configurator',
      },
    },
  },
  {
    key: 'configureYourDreamPistolOnline',
    text: {
      [BbitLanguage.DE_CH]: {
        [BbitTranslationType.PLURAL]: 'Konfiguriere deine Wunschpistole online',
      },
      [BbitLanguage.EN_US]: {
        [BbitTranslationType.PLURAL]: 'Configure your dream pistol online',
      },
    },
  },
  {
    key: 'purchaseThePhxPhoenixPistolFromTheDealerOfYourChoice',
    text: {
      [BbitLanguage.DE_CH]: {
        [BbitTranslationType.PLURAL]: 'Beziehe die PHX Phoenix Pistole beim Händler deiner Wahl',
      },
      [BbitLanguage.EN_US]: {
        [BbitTranslationType.PLURAL]: 'Purchase the PHX Phoenix pistol from the dealer of your choice',
      },
    },
  },
  {
    key: 'ourShootersRegularlyWinPodiumPlaces',
    text: {
      [BbitLanguage.DE_CH]: {
        [BbitTranslationType.PLURAL]: 'Unsere Schützen holen regelmässig Podestplätze!',
      },
      [BbitLanguage.EN_US]: {
        [BbitTranslationType.PLURAL]: 'Our shooters regularly win podium places!',
      },
    },
  },
  {
    key: 'newsLeadHome',
    text: {
      [BbitLanguage.DE_CH]: {
        [BbitTranslationType.PLURAL]:
          'Die aktuellsten Neuigkeiten zu unseren Produkten und Schützen. Hier bleiben Sie auf dem aktuellen Stand.',
      },
      [BbitLanguage.EN_US]: {
        [BbitTranslationType.PLURAL]: 'The latest news about our products and shooters. Here you can stay up to date.',
      },
    },
  },
  {
    key: 'homeLead',
    text: {
      [BbitLanguage.DE_CH]: {
        [BbitTranslationType.PLURAL]: 'Ihr Schweizer Hersteller für Präzisionspistolen',
      },
      [BbitLanguage.EN_US]: {
        [BbitTranslationType.PLURAL]: 'Your Swiss manufacturer of precision pistols',
      },
    },
  },
  {
    key: 'welcomeTo',
    text: {
      [BbitLanguage.DE_CH]: {
        [BbitTranslationType.PLURAL]: 'Willkommen bei',
      },
      [BbitLanguage.EN_US]: {
        [BbitTranslationType.PLURAL]: 'Welcome to',
      },
    },
  },
  {
    key: 'replacementPartsAndAccessories',
    text: {
      [BbitLanguage.DE_CH]: {
        [BbitTranslationType.PLURAL]: 'Ersatzteile und Zubehör',
      },
      [BbitLanguage.EN_US]: {
        [BbitTranslationType.PLURAL]: 'Replacement parts and accessories',
      },
    },
  },
  {
    key: 'login',
    text: {
      [BbitLanguage.DE_CH]: {
        [BbitTranslationType.PLURAL]: 'Händler-Login',
      },
      [BbitLanguage.EN_US]: {
        [BbitTranslationType.PLURAL]: 'Dealer Login',
      },
    },
  },
  {
    key: 'home',
    text: {
      [BbitLanguage.DE_CH]: {
        [BbitTranslationType.PLURAL]: 'Home',
      },
      [BbitLanguage.EN_US]: {
        [BbitTranslationType.PLURAL]: 'Home',
      },
    },
  },
];
