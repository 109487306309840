<app-page-header [title]="'Downloads'"></app-page-header>

<div class="relative mx-auto max-w-7xl mt-12 mb-12 px-4 md:px-8">
  <div class="mx-auto mt-12 grid gap-x-6 gap-y-8 md:grid-cols-2 lg:grid-cols-3">
    @for (weapon of weapons | async; track weapon) {
      @if (filterManualsByLanguage(weapon.manuals); as manuals) {
        @if (manuals.length > 0) {
          <div class="flex flex-col overflow-hidden rounded-sm shadow-md justify-between cursor-pointe">
            <div class="flex flex-col justify-between p-6">
              <div class="flex-1">
                <div class="mt-2 block">
                  <p class="text-xl font-semibold text-gray-900">
                    {{ { object: weapon.designation } | i18n }}
                  </p>
                </div>

                <div class="mt-6">
                  <div>
                    <div class="divide-y divide-gray-100mt-6">
                      <div class="flex flex-col">
                        @for (manual of manuals; track manual) {
                          <a
                            class="p-4 even:bg-gray-50 hover:bg-orange-200 flex flex-row justify-between"
                            download
                            [href]="manual.url"
                            target="_blank"
                          >
                            <div class="text-gray-900 overflow-x-clip">
                              {{ manual.designation }}
                            </div>
                            <div>
                              <svg style="width: 24px; height: 24px" viewBox="0 0 24 24">
                                <path fill="currentColor" d="M5,20H19V18H5M19,9H15V3H9V9H5L12,16L19,9Z" />
                              </svg>
                            </div>
                          </a>
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
      }
    }
  </div>
</div>
