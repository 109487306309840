import { Injectable, inject } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { BbitI18n, type IBbitI18n } from '@bbitgmbh/bbit.utils';
import { BehaviorSubject, type Observable } from 'rxjs';

export interface INavItem {
  text: IBbitI18n;
  routerLink: string[];
}

export interface INavChildItem extends INavItem {
  validFor: string[];
  validForStartsWith?: string[];
}

@Injectable({
  providedIn: 'root',
})
export class NavService {
  private _router = inject(Router);
  private _isOnShopPage = new BehaviorSubject<boolean>(false);

  constructor() {
    this._router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (event.url.includes('/shop')) {
          this._isOnShopPage.next(true);
        } else {
          this._isOnShopPage.next(false);
        }
      }
    });
  }

  public getMenuItems(): INavItem[] {
    return [
      {
        text: { key: 'home' },
        routerLink: ['/'],
      },
      {
        text: { key: 'product' },
        routerLink: ['/products'],
      },
      { text: { key: 'news' }, routerLink: ['/news'] },
      { text: { key: 'shooter' }, routerLink: ['/shooter'] },
      { text: { key: 'dealer' }, routerLink: ['/dealer'] },
      { text: 'Downloads', routerLink: ['/downloads'] },
      // { text: { key: 'shop' }, routerLink: ['/shop'] },
    ];
  }

  public observeIsOnShopPage(): Observable<boolean> {
    return this._isOnShopPage.asObservable();
  }

  public isRouteActive(route: string[]): boolean {
    const r = (route || []).join('/');
    const url = this._router.url;
    if (r === '/') {
      return url === r;
    }
    return url.startsWith(r);
  }


  // public getLanguages(): { text: string; language: BbitLanguage }[] {
  //   return [
  //     { text: 'DE', language: BbitLanguage.DE_CH },
  //     { text: 'FR', language: BbitLanguage.FR_CH },
  //   ];
  // }
}
