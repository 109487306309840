import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Injectable, Injector, inject } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { Subject, filter, takeUntil } from 'rxjs';
import { NavService } from '../nav/nav.service';
import { NavOverlayComponent } from './nav-overlay.component';

@Injectable({
  providedIn: 'root',
})
export class NavOverlayService {
  private _overlay = inject(Overlay);
  private _router = inject(Router);
  nav = inject(NavService);

  private _overlayRef: OverlayRef | undefined = undefined;
  private _closeOverlay = new Subject<void>();
  constructor() {
    this._router.events.pipe(filter((event) => event instanceof NavigationStart)).subscribe((change) => {
      if (change instanceof NavigationStart) {
        if (!change.url.startsWith('/shop')) {
          this.close();
        }
      }
    });
    this._closeOverlay.subscribe(() => {
      if (this._overlayRef) {
        this._overlayRef.dispose();
        this._overlayRef = undefined;
      }
    });
  }

  public open() {
    const positionStrategy = this._overlay.position().global().left();

    const overlayConfig = new OverlayConfig({
      hasBackdrop: true,
      backdropClass: 'overlay-backdrop',
      scrollStrategy: this._overlay.scrollStrategies.block(),
      positionStrategy,
      width: '250px',
      // maxWidth: '500px',
      minWidth: '250px',
      height: '100%',
    });

    this._overlayRef = this._overlay.create(overlayConfig);
    this._overlayRef
      .backdropClick()
      .pipe(takeUntil(this._closeOverlay))
      .subscribe(() => this._overlayRef?.dispose());

    const filePreviewPortal = new ComponentPortal(
      NavOverlayComponent,
      null,
      Injector.create({
        providers: [],
      }),
    );
    this._overlayRef.attach(filePreviewPortal);
  }

  public close() {
    this._closeOverlay.next();
  }
}
