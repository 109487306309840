import { ChangeDetectionStrategy, Component, inject } from '@angular/core';

import { AsyncPipe } from '@angular/common';
import { RouterOutlet } from '@angular/router';
import {
  BbitWebShopAlgoliaService,
  BbitWebShopApiService,
  BbitWebShopNotificationService,
} from '@bbitgmbh/webshop.sdk';
import { BbitCookieState, BbitGdprService, I18nPipe } from '@bbitgmbh/website.sdk';
import { NgIcon } from '@ng-icons/core';
import { BehaviorSubject } from 'rxjs';
import { FooterComponent } from './footer/footer.component';
import { NavComponent } from './nav/nav.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NavComponent, NgIcon, RouterOutlet, FooterComponent, AsyncPipe, I18nPipe],
})
export class AppComponent {
  private _algolia = inject(BbitWebShopAlgoliaService);
  notification = inject(BbitWebShopNotificationService);
  private _api = inject(BbitWebShopApiService);
  gdpr = inject(BbitGdprService);

  public BbitCookieState = BbitCookieState;

  public showNotification = new BehaviorSubject<boolean>(false);

  constructor() {
    if (!localStorage.getItem('bbitHideMove2024Notification')) {
      this.showNotification.next(false);
    }
  }

  closeNotification() {
    this.showNotification.next(false);
    localStorage.setItem('bbitHideMove2024Notification', 'true');
  }
}
