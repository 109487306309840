<div class="flex flex-col justify-between bg-white p-4 h-full overflow-auto">
  <div class="flex flex-col">
    <div class="flex justify-between">
      <a [routerLink]="['/']">
        <span class="sr-only">PHX Phoenix Logo</span>
        <img class="h-12 w-auto" src="/assets/images/logo-phx.svg" alt="" />
      </a>
      <div class="flex px-4 pb-2 pt-5">
        <button type="button" class="-m-2 inline-flex items-center justify-center rounded-md p-2 text-gray-400" (click)="close()">
          <span class="sr-only">Close menu</span>
          <svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
            <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
      </div>
    </div>
    <div class="flex flex-col space-y-2 mt-6">
      @for (item of nav.getMenuItems(); track $index) {
        <a
          class="flex items-center text-sm text-gray-700 hover:text-gray-800 border-b pb-2 pl-2 cursor-pointer"
          [class.font-bold]="nav.isRouteActive(item.routerLink)"
          [class.font-medium]="!nav.isRouteActive(item.routerLink)"
          (click)="route(item.routerLink)"
          >{{ item.text | i18n }}</a
        >
      }
    </div>

    <div class="flex flex-col md:hidden mt-8">
      <!-- <h3 class="mb-8 text-l font-bold">{{ { key: 'replacementPartsAndAccessories' } | i18n }}</h3>
          <app-side-nav></app-side-nav> -->
    </div>
  </div>

  <div class="flex flex-col mt-8 gap-8">
    <div class="flex flex-1 items-center justify-end">
      <!-- <a href="#" class="hidden text-sm font-medium text-white lg:block">Search</a> -->

      <div class="ml-auto items-center">
        <div class="flex flex-row items-center ml-2">
          <!-- <div class="hidden md:flex p-2">
              <app-search-box></app-search-box>
            </div> -->

          <!-- @if (!(website.observeAuthenticated() | async)) {
            <button
              (click)="website.login()"
              class="flex max-w-xs flex-1 items-center justify-center rounded-md border border-transparent text-white bg-primary-600 py-2 pl-2 pr-4 text-sm font-medium hover:bg-primary-400 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 focus:ring-offset-gray-50 sm:w-full"
            >
              <ng-icon name="heroArrowLeftOnRectangle" class="flex-shrink-0 mr-1" size="24px"></ng-icon>
              {{ { key: 'login' } | i18n }}
            </button>
          } -->

          <!-- <a
            class="flex p-2 cursor-pointer"
            (click)="website.login()"
            *ngIf="!(website.observeAuthenticated() | async)"
            >
            <ng-icon
              name="heroArrowLeftOnRectangle"
              class="flex-shrink-0 text-gray-400 group-hover:text-gray-500"
              size="24px"
            ></ng-icon>
          </a> -->
          @if (website.observeAuthenticated() | async) {
            <a class="flex p-2 cursor-pointer" (click)="website.logout()">
              <ng-icon name="heroArrowRightOnRectangle" class="flex-shrink-0 text-zinc-950 group-hover:text-gray-500" size="24px"></ng-icon>
            </a>
          }
          @if (website.observeAuthenticated() | async) {
            <a [routerLink]="['/account']" class="flex p-2">
              <ng-icon name="heroUserCircle" class="flex-shrink-0 text-gray-400 group-hover:text-gray-500" size="24px"></ng-icon>
            </a>
          }
          @if (website.observeAuthenticated() | async) {
            <a class="flex p-2" [routerLink]="['/shop/cart']">
              <ng-icon name="heroShoppingBag" class="flex-shrink-0 text-gray-400 group-hover:text-gray-500" size="24px"></ng-icon>
              <span class="ml-2 text-sm font-medium text-zinc-950 group-hover:text-gray-800 flex items-center">{{
                cart.observeItemCount() | async
              }}</span>
            </a>
          }
        </div>
      </div>
    </div>
    <div class="flex items-center text-zinc-950 space-x-6 justify-end">
      <a href="#" class="text-sm font-medium hover:text-primary-300" (click)="changeLang(BbitLanguage.DE_CH)">Deutsch</a>
      <a href="#" class="text-sm font-medium hover:text-primary-300" (click)="changeLang(BbitLanguage.EN_US)">English</a>
    </div>
  </div>
</div>
