<app-page-header [title]="{ key: 'product' }"></app-page-header>

<div class="relative mx-auto max-w-7xl mt-12 mb-12 px-4 md:px-8">
  <div class="mx-auto mt-12 grid gap-x-6 gap-y-8 md:grid-cols-2 lg:grid-cols-3">
    @for (weapon of weapons | async; track weapon) {
      <a
        class="flex flex-col overflow-hidden rounded-sm shadow-md justify-between cursor-pointer hover:bg-orange-200"
        (click)="routeToWeapon(weapon)"
        target="_blank"
      >
        <div class="flex">
          <picture [bbitPictureSrc]="weapon.imageLeft.s3Key"></picture>
          <!-- <img [src]="'https://phx.swiss/' + weapon.imageLeft.s3Key"> -->
        </div>
        <div class="flex flex-col justify-between p-6">
          <div class="flex-1">
            <div class="mt-2 block">
              <p class="text-xl font-semibold text-gray-900">
                {{ { object: weapon.designation } | i18n }}
              </p>
            </div>
          </div>
        </div>
      </a>
    }
  </div>
</div>
