import { ChangeDetectionStrategy, Component, type OnInit, inject } from '@angular/core';
import { type IBbitOrganizationLegalIdentity, bbitProdEnvironmentKey } from '@bbitgmbh/bbit.utils';
import { BbitWebsiteMetaDataService, BbitWebsiteService } from '@bbitgmbh/website.sdk';
import { BehaviorSubject, map, share } from 'rxjs';
import { RouterLink } from '@angular/router';
import { AsyncPipe } from '@angular/common';

@Component({
    selector: 'app-imprint',
    templateUrl: './imprint.component.html',
    styleUrls: ['./imprint.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [RouterLink, AsyncPipe]
})
export class ImprintComponent implements OnInit {
  private _meta = inject(BbitWebsiteMetaDataService);
  private _website = inject(BbitWebsiteService);

  public identity = new BehaviorSubject<IBbitOrganizationLegalIdentity | undefined>(undefined);

  mailToLink = this.identity.asObservable().pipe(
    map((identity) => {
      if (!identity) {
        return '';
      }
      return `mailto:${identity.mainEMail}`;
    }),
    share(),
  );

  phoneLink = this.identity.asObservable().pipe(
    map((identity) => {
      if (!identity) {
        return '';
      }
      return `tel:${identity.mainPhone?.replace(/\s/g, '')}`;
    }),
    share(),
  );

  constructor() {
    this._meta.update({ title: { key: 'imprint' } });

    this.identity.next(this._website.getAuthMeta()?.environments?.[bbitProdEnvironmentKey]?.identity);
  }

  ngOnInit(): void {}
}
