import { AsyncPipe, CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { Router } from '@angular/router';
import { I18nPipe, LuxonPipe } from '@bbitgmbh/website.sdk';
import { DateTime } from 'luxon';
import { BehaviorSubject } from 'rxjs';
import { ApiService } from '../api/api.service';
import { type IInjectedNewsEntry } from '../api/news.interfaces';
import { PageHeaderComponent } from '../helper/page-header/page-header.component';
import { NavService } from '../nav/nav.service';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.scss'],
  imports: [AsyncPipe, I18nPipe, LuxonPipe, PageHeaderComponent, CommonModule],
})
export class NewsComponent {
  private _api = inject(ApiService);
  private _router = inject(Router);

  public news = new BehaviorSubject<IInjectedNewsEntry[]>([]);
  public history = new BehaviorSubject<IInjectedNewsEntry[]>([]);

  constructor() {
    this._api.getNews().then((news) => {
      const entries = [];
      const history = [];

      for (const o of news) {
        const from = DateTime.fromISO(o.display?.from);
        const until = DateTime.fromISO(o.display?.until);
        const now = DateTime.now();

        const mappedEntry = {
          _id: o._id,
          title: o.title,
          date: DateTime.fromISO(o.newsDate),
          from,
          until,
          description: {
            'de-CH': { 'f-': o.newsHtml?.de },
            'en-US': { 'f-': o.newsHtml?.en },
            'fr-CH': { 'f-': o.newsHtml?.fr },
          },
          image: o.newsImage.url,
          moreInfoLink: o.link?.url,
        };

        if (now >= from && now <= until) {
          entries.push(mappedEntry);
        } else {
          history.push(mappedEntry);
        }
      }

      const sortBy = (a: IInjectedNewsEntry, b: IInjectedNewsEntry) => {
        if (+a.date > +b.date) {
          return -1;
        }
        if (+a.date < +b.date) {
          return 1;
        }
        return 0;
      };

      this.news.next(entries.sort(sortBy));
      this.history.next(history.sort(sortBy));
    });
  }

  routeToNews(news: IInjectedNewsEntry): void {
    this._router.navigate(['/news', news._id]);
  }
}
