import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { type BbitDataAddress, type BbitDataName, BbitI18n } from '@bbitgmbh/bbit.utils';
import { BbitWebShopApiService } from '@bbitgmbh/webshop.sdk';
import { BbitWebsiteMetaDataService, BbitWebsiteService, I18nPipe } from '@bbitgmbh/website.sdk';
import { BehaviorSubject, map } from 'rxjs';
import { countryRecords } from './country';
import { nameSalutationRecords } from './name-salutation';
import { AsyncPipe } from '@angular/common';
@Component({
    selector: 'app-profile',
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [ReactiveFormsModule, AsyncPipe, I18nPipe]
})
export class ProfileComponent {
  private _meta = inject(BbitWebsiteMetaDataService);
  private _website = inject(BbitWebsiteService);

  public profileForm = new FormGroup({
    salutation: new FormControl(''),
    name: new FormGroup({
      companyName: new FormControl(''),
      givenName: new FormControl(''),
      familyName: new FormControl(''),
    }),
    eMail: new FormControl(''),
    address: new FormGroup({
      street: new FormControl(''),
      postalCode: new FormControl(''),
      locality: new FormControl(''),
    }),
  });

  contactSubj = new BehaviorSubject<any>(undefined);

  addressObs = this.contactSubj.pipe(
    map((contact) => {
      if (!contact) {
        return '';
      }

      return this.templateFormatAddress(
        contact.name,
        '',
        contact.address,
        '#CH',
        BbitI18n.getLanguage(),
        '#person-with-salutation',
      );
    }),
    takeUntilDestroyed(),
  );

  organizationKey: string | undefined;

  constructor() {
    this.profileForm.disable();
    this._website
      .observeProfile()
      .pipe(takeUntilDestroyed())
      .subscribe((contact) => {
        console.log('contact', contact);
        this.contactSubj.next(contact as any);
        this.profileForm.patchValue(contact || {});
      });

    this.organizationKey = this._website.getOrganizationKey();
    this._meta.update({ title: { key: 'profile' } });
  }

  public async onSubmit(): Promise<void> {
    // TODO
  }

  public templateFormatAddress(
    nameStruct: BbitDataName | undefined,
    contactPerson: string,
    adr: BbitDataAddress | undefined,
    ownCountry: string,
    language: string | undefined,
    orderAddressingType: string,
  ) {
    const templatePadIfNonEmpty = (
      value: string | undefined,
      before: string | undefined,
      after: string | undefined,
    ): string => {
      value = (value || '').trim();
      return value && value.length > 0 ? (before ? before : '') + value + (after ? after : '') : '';
    };

    let country = undefined;

    if (!adr) {
      adr = { country: undefined };
    }

    if (adr.country && adr.country !== ownCountry) {
      const countryRecord = countryRecords.find((e) => e._id === adr?.country);

      country = countryRecord ? BbitI18n.t({ object: countryRecord.designation }, language) : adr.country;
    }

    let isPrivate = false;
    let printContactPerson = false;
    let withSalutation = false;

    switch (orderAddressingType) {
      case '#person-with-salutation':
        withSalutation = true;
        break;
      case '#person-public':
        isPrivate = false;
        printContactPerson = true;
        break;
      case '#person-private':
        isPrivate = true;
        printContactPerson = true;
        break;
      default:
        // use default values
        break;
    }

    const salutationRecord = nameSalutationRecords.find((e) => e._id === nameStruct?.salutation);

    let lines: (string | undefined)[] = [];
    switch (nameStruct?.salutation) {
      case '#company':
        lines = isPrivate
          ? [printContactPerson ? contactPerson : undefined, nameStruct.companyName, nameStruct.department]
          : [nameStruct.companyName, nameStruct.department, printContactPerson ? contactPerson : undefined];
        break;
      case '#club':
        lines = isPrivate
          ? [printContactPerson ? contactPerson : undefined, nameStruct.clubName, nameStruct.department]
          : [nameStruct.clubName, nameStruct.department, printContactPerson ? contactPerson : undefined];
        break;
      case '#male':
      case '#female':
        lines = [
          templatePadIfNonEmpty(
            withSalutation
              ? salutationRecord
                ? BbitI18n.t(
                    {
                      object: salutationRecord.designation,
                    },
                    language,
                  )
                : ''
              : '',
            '',
            ' ',
          ) +
            templatePadIfNonEmpty(nameStruct.givenName, '', ' ') +
            templatePadIfNonEmpty(nameStruct.familyName, '', ''),
        ];
        break;
    }

    return lines
      .concat([
        adr.addition,
        `${adr.street || ''} ${(adr as any).buildingNumber || ''}`,
        adr.postOfficeBox,
        `${adr.postalCode || ''} ${adr.locality || ''}`,
        country,
      ])
      .map((e) => e?.trim())
      .filter((e) => (e || '').length > 0)
      .join('\n');
  }
}
