<div class="px-2 lg:px-8 m-auto max-w-[1600px] pb-6 py-6 mt-16">
  <div class="grid grid-cols-1 sm:grid-cols-2 gap-8">
    <div class="flex flex-col justify-center">
      <h1 class="text-4xl mb-12 font-bold">Bereiche der AATS-Group</h1>
      <p>
        Gönnen Sie sich einen Einblick in unser vielschichtiges Angebot. Besuchen Sie einen unserer AATS-Kurse und bestellen Sie fehlendes
        Einsatzmaterial einfach über den AATS-Shop. Auch bei Fragen der persönlichen Sicherheit ist die AATS-Protection der richtige
        Ansprechpartner. Kompetente Beratung ist garantiert.
      </p>
    </div>

    <picture
      [bbitPictureSrc]="'/assets/images/areas/area-1.jpg'"
      [bbitPictureDefaultSize]="750"
      [bbitPictureImgClasses]="'h-96 w-full object-cover object-top'"
      [bbitPictureAlt]="'Schiessübung bei einem Auto'"
    ></picture>
  </div>

  <h2 class="text-3xl mb-8 mt-24 font-bold">AATS-Kurse</h2>
  <div class="flex flex-col-reverse sm:flex-row gap-8">
    <div class="flex flex-col gap-8 w-full sm:w-96 sm:min-w-96">
      <picture
        [bbitPictureSrc]="'/assets/images/areas/kurs-1.jpg'"
        [bbitPictureDefaultSize]="400"
        [bbitPictureImgClasses]="'h-96 w-full object-cover'"
        [bbitPictureAlt]="'Schiessübung draussen'"
      ></picture>
      <picture
        [bbitPictureSrc]="'/assets/images/areas/kurs-2.jpg'"
        [bbitPictureDefaultSize]="600"
        [bbitPictureImgClasses]="'h-96 w-full object-cover'"
        [bbitPictureAlt]="'Schiessübung drinnen'"
      ></picture>
    </div>

    <div class="flex flex-col">
      <p class="mb-8">
        Mit den Kursen von AATS®-Group werden Anfängerinnen oder Anfänger, fortgeschrittene Schützinnen und Schützen, Sicherheitsdienste
        sowie Mitglieder von Behörden angesprochen. Als Kundin oder Kunde absolvieren Sie eine stufengerechte Ausbildung, in welcher die
        Sicherheit oberste Priorität hat.
      </p>
      <div class="flex flex-row gap-4 mb-8 items-start">
        <img class="w-8 mt-4" src="assets/images/li.svg" alt="Aufzählungszeichen" />
        <div class="flex flex-col">
          <h3 class="text-xl font-bold mb-4 mt-4">Stufengerecht</h3>
          <p>
            Unser Ausbildungskonzept ist auf die unterschiedlichen Vorkenntnisse der Teilnehmenden abgestimmt. Sowohl Einsteigende als auch
            Fortgeschrittene lernen in jedem Kurs neue Fertigkeiten.
          </p>
        </div>
      </div>
      <div class="flex flex-row gap-4 mb-8 items-start">
        <img class="w-8 mt-4" src="assets/images/li.svg" alt="Aufzählungszeichen" />
        <div class="flex flex-col">
          <h3 class="text-xl font-bold mb-4 mt-4">Sicher</h3>
          <p>
            In unseren Kursen hat die Sicherheit der Teilnehmenden oberste Priorität. Es ist uns daher wichtig, dass während des Kurses alle
            Teilnehmenden die Sicherheitsregeln im Umgang mit Waffen beherrschen und die Waffenhandhabung sitzt.
          </p>
        </div>
      </div>
      <div class="flex flex-row gap-4 items-start">
        <img class="w-8 mt-4" src="assets/images/li.svg" alt="Aufzählungszeichen" />
        <div class="flex flex-col">
          <h3 class="text-xl font-bold mb-4 mt-4">Bewährt</h3>
          <p>
            Unsere Instruktorinnen und Instruktoren sind erfahrene Berufswaffenträger und kennen sich bestens in der Materie des
            Schusswaffengebrauchs aus.
          </p>
        </div>
      </div>
    </div>
  </div>

  <h2 class="text-3xl mb-8 mt-24 font-bold">AATS-Shop</h2>
  <div class="flex flex-col sm:flex-row gap-8">
    <div class="flex flex-col">
      <p class="mb-8">
        Die erste Anlaufstelle, wenn es um Einsatzwaffen und taktische Ausrüstung geht. Unser Angebot wird laufend ausgebaut und mit neuen
        Artikeln bestückt. Einkaufen rund um die Uhr online oder kompetente Beratung in unserem Fachgeschäft – Sie entscheiden, was für Sie
        das Richtige ist. Falls Sie ein gewünschtes Produkt nicht finden, nehmen Sie mit uns Kontakt auf.
      </p>
      <div class="flex flex-row gap-4 mb-8 items-start">
        <img class="w-8 mt-4" src="assets/images/li.svg" alt="Aufzählungszeichen" />
        <div class="flex flex-col">
          <h3 class="text-xl font-bold mb-4 mt-4">Schusswaffen</h3>
          <p>
            Egal ob Anfängerin oder Anfänger, Sportschütze, Enthusiast, Sammler oder Berufswaffenträger, im AATS-Shop finden Sie immer etwas
            Passendes.
          </p>
        </div>
      </div>
      <div class="flex flex-row gap-4 mb-8 items-start">
        <img class="w-8 mt-4" src="assets/images/li.svg" alt="Aufzählungszeichen" />
        <div class="flex flex-col">
          <h3 class="text-xl font-bold mb-4 mt-4">Munition</h3>
          <p>
            Wir bieten ein breites Angebot von Munition in verschiedenen "Grössen und Formen". Egal ob für das taktische Schiessen oder auch
            exotische Waffenkaliber.
          </p>
        </div>
      </div>
      <div class="flex flex-row gap-4 items-start">
        <img class="w-8 mt-4" src="assets/images/li.svg" alt="Aufzählungszeichen" />
        <div class="flex flex-col">
          <h3 class="text-xl font-bold mb-4 mt-4">Ausrüstung</h3>
          <p>
            Von Waffenzubehör, über Holster, Halter, Pouches, Gürtel und Westen, bis hin zu Taschen und Tresoren - im AATS-Shop findet sich
            alles Notwendige für einen Kursbesuch und auch danach.
          </p>
        </div>
      </div>
    </div>
    <div class="flex flex-col gap-8 w-full sm:w-96 sm:min-w-96">
      <picture
        [bbitPictureSrc]="'/assets/images/areas/shop-1.jpg'"
        [bbitPictureDefaultSize]="800"
        [bbitPictureImgClasses]="'h-96 w-full object-cover object-top'"
        [bbitPictureAlt]="'Shop'"
      ></picture>
    </div>
  </div>

  <h2 class="text-3xl mb-8 mt-24 font-bold">AATS-Protection​</h2>
  <div class="flex flex-col-reverse sm:flex-row gap-8 mb-24">
    <div class="flex flex-col gap-8 w-full sm:w-96 sm:min-w-96">
      <picture
        [bbitPictureSrc]="'/assets/images/areas/protection-1.jpg'"
        [bbitPictureDefaultSize]="800"
        [bbitPictureImgClasses]="'h-96 w-full object-cover'"
        [bbitPictureAlt]="'AATS-Protection'"
      ></picture>
    </div>

    <div class="flex flex-col">
      <p class="mb-8">
        Dank unserer langjährigen Einsatzerfahrung und intensiven Ausbildungen bieten wir Ihnen Top-Dienstleistungen in den Bereichen Personen- und
        Begleitschutz, Wertsachentransport, Objektschutz sowie Bewachungen an. Basierend auf einer akribischen Situationsanalyse
        möglicher Bedrohungen wird ein optimales und mit Ihnen abgesprochenes Einsatzkonzept erstellt. Vertrauen Sie sich unserem Team an.
      </p>
      <div class="flex flex-row gap-4 mb-8 items-start">
        <img class="w-8 mt-4" src="assets/images/li.svg" alt="Aufzählungszeichen" />
        <div class="flex flex-col">
          <h3 class="text-xl font-bold mb-4 mt-4">Objektschutz / Bewachungen</h3>
        </div>
      </div>
      <div class="flex flex-row gap-4 mb-8 items-start">
        <img class="w-8 mt-4" src="assets/images/li.svg" alt="Aufzählungszeichen" />
        <div class="flex flex-col">
          <h3 class="text-xl font-bold mb-4 mt-4">Personenschutz / Begleitschutz</h3>
        </div>
      </div>
      <div class="flex flex-row gap-4 items-start">
        <img class="w-8 mt-4" src="assets/images/li.svg" alt="Aufzählungszeichen" />
        <div class="flex flex-col">
          <h3 class="text-xl font-bold mb-4 mt-4">Wertsachentransport / Begleitschutz</h3>
        </div>
      </div>
    </div>
  </div>
</div>
