import { AsyncPipe } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, Component, type OnInit, inject } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { BbitI18n, BbitLanguage } from '@bbitgmbh/bbit.utils';
import { BbitWebShopCartService } from '@bbitgmbh/webshop.sdk';
import { BbitWebsiteService, I18nPipe } from '@bbitgmbh/website.sdk';
import { NgIcon } from '@ng-icons/core';
import { BehaviorSubject } from 'rxjs';
import { NavOverlayService } from '../nav-overlay/nav-overlay.service';
import { NavService } from './nav.service';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss'],
  imports: [RouterLink, NgIcon, AsyncPipe, I18nPipe],
})
export class NavComponent implements OnInit {
  website = inject(BbitWebsiteService);
  cart = inject(BbitWebShopCartService);
  nav = inject(NavService);
  overlay = inject(NavOverlayService);

  public BbitI18n = BbitI18n;
  public BbitLanguage = BbitLanguage;
  public showMobileSearchBox = new BehaviorSubject<boolean>(false);

  ngOnInit(): void {}

  changeLang(lang: BbitLanguage) {
    if (lang) {
      BbitI18n.setLanguage(lang);
    }
  }
}
