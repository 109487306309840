import { Routes } from '@angular/router';
import { BbitAuthGuard, bbitWebsiteAuthRoutes } from '@bbitgmbh/website.sdk';
import { AccountComponent } from './account/account.component';
import { ProfileComponent } from './account/profile/profile.component';
import { AreasComponent } from './areas/areas.component';
import { DealerComponent } from './dealer/dealer.component';
import { HomeComponent } from './home/home.component';
import { ImprintComponent } from './imprint/imprint.component';
import { LegalComponent } from './legal/legal.component';
import { NewsDetailComponent } from './news/news-detail/news-detail.component';
import { NewsComponent } from './news/news.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { ProductDetailComponent } from './products/product-detail/product-detail.component';
import { ProductsComponent } from './products/products.component';
import { ShooterComponent } from './shooter/shooter.component';
import { DownloadsComponent } from './downloads/downloads.component';

export const appRoutes: Routes = [
  ...bbitWebsiteAuthRoutes,
  {
    path: '',
    component: HomeComponent,
  },
  { path: 'products', component: ProductsComponent },
  { path: 'products/:code', component: ProductDetailComponent },
  { path: 'news/:id', component: NewsDetailComponent },
  { path: 'news', component: NewsComponent },
  { path: 'shooter', component: ShooterComponent },
  { path: 'dealer', component: DealerComponent },
  { path: 'downloads', component: DownloadsComponent },
  {
    path: 'account',
    component: AccountComponent,
    canActivate: [BbitAuthGuard],
    children: [
      { path: 'profile', component: ProfileComponent },
      { path: '', redirectTo: 'profile', pathMatch: 'full' },
    ],
  },
  {
    path: 'shop',
    loadComponent: () => import('./shop/shop.component').then((m) => m.ShopComponent),
    children: [
      {
        path: 'item/:slug',
        loadComponent: () => import('./shop/item-detail/item-detail.component').then((m) => m.ItemDetailComponent),
      },
      {
        path: 'items',
        loadComponent: () => import('./shop/item-list/item-list.component').then((m) => m.ItemListComponent),
      },
      {
        path: 'order-result',
        loadComponent: () => import('./shop/order-result/order-result.component').then((m) => m.OrderResultComponent),
      },
      {
        path: 'redirect-to-payment',
        loadComponent: () =>
          import('./shop/redirect-to-payment/redirect-to-payment.component').then((m) => m.RedirectToPaymentComponent),
      },
      { path: 'cart', loadComponent: () => import('./shop/cart/cart.component').then((m) => m.CartComponent) },
      {
        path: '',
        loadComponent: () => import('./shop/item-list/item-list.component').then((m) => m.ItemListComponent),
      },
      { path: '**', redirectTo: '404-not-found', pathMatch: 'full' },
    ],
  },
  { path: 'imprint', component: ImprintComponent },
  { path: 'legal', component: LegalComponent },
  { path: 'privacy', component: PrivacyComponent },
  {
    path: 'bereiche',
    component: AreasComponent,
  },

  { path: '', redirectTo: '', pathMatch: 'full' },
  { path: '**', redirectTo: '', pathMatch: 'full' },
];
