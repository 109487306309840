<div class="flex flex-col bg-white min-h-screen">
  <div class="sticky top-0 z-30">
    <app-nav></app-nav>
  </div>
  <main class="bg-white w-full mx-auto overflow-auto">
    <router-outlet></router-outlet>
  </main>

  <footer class="bg-white m-auto w-full" aria-labelledby="footer-heading">
    <app-footer></app-footer>
  </footer>
</div>

<div class="pointer-events-none fixed bottom-6 right-6 z-50 space-y-6">
  @for (n of notification.observe() | async; track n._id) {
    <div class="pointer-events-auto w-64 overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
      <div class="p-4">
        <div class="flex items-start">
          <div class="flex-shrink-0">
            <!-- TODO types -->
            @switch (n.level) {
              @case ('success') {
                <ng-icon name="heroCheckCircle" class="h-6 w-6 text-green-700" size="24px"></ng-icon>
              }
              @case ('progress') {
                <ng-icon name="heroClockSolid" class="h-6 w-6" size="24px"></ng-icon>
              }
            }
          </div>
          <div class="ml-3 w-0 flex-1 pt-0.5">
            <p class="text-sm font-medium text-gray-900">
              {{ n.message | i18n }}
            </p>
            @if (n.detail) {
              <p class="mt-1 text-sm text-gray-500">
                {{ n.detail | i18n }}
              </p>
            }
          </div>
          <div class="ml-4 flex flex-shrink-0">
            <button
              type="button"
              class="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
              (click)="notification.remove(n)"
            >
              <ng-icon name="heroXMark" class="h-5 w-5"></ng-icon>
            </button>
          </div>
        </div>
      </div>
    </div>
  }
</div>

@if ((gdpr.observeCookieState() | async) === BbitCookieState.ASK) {
  <div class="fixed bottom-0 w-full border-t-2 z-50 border-white">
    <div class="bg-gray-800 text-gray-300 p-4 text-sm flex flex-col md:flex-row">
      <div>
        <div class="flex flex-row space-x-2">
          <ng-icon name="matCookie" size="24px"></ng-icon>
          <h3 class="text-xl mb-2">{{ { key: 'cookieTitle' } | i18n }}</h3>
        </div>
        <p>{{ { key: 'cookieMessage' } | i18n }}</p>
      </div>
      <div class="mt-2 flex justify-end items-end">
        <div class="flex flex-row space-x-4 h-12">
          <button
            class="rounded-md px-3 py-2 text-sm font-semibold text-primary-600 shadow-sm hover:bg-primary-100"
            (click)="gdpr.denyCookies()"
          >
            {{ { key: 'deny' } | i18n }}
          </button>
          <button
            class="rounded-md bg-primary-600 px-3 py-2 text-sm font-semibold text-black shadow-sm hover:bg-primary-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600"
            (click)="gdpr.allowCookies()"
          >
            {{ { key: 'allow' } | i18n }}
          </button>
        </div>
      </div>
    </div>
  </div>
}
